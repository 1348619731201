import useSWR from "swr";
import { getSingleSubAdminUrl } from "../subadmins.service";

export default function useSingleSubAdmin(id) {
   const {
      data: subAdmin,
      error: subAdminError,
      mutate: mutateSubAdmin,
   } = useSWR(id ? [getSingleSubAdminUrl(id), "", "V2"] : null);

   const subAdminLoading = !subAdmin && !subAdminError;

   return {
      subAdmin,
      subAdminInfo: subAdmin?.subAdmin,
      subAdminBills: subAdmin?.bills,
      subAdminGroups: subAdmin?.groupCircles,
      mutateSubAdmin,
      subAdminError,
      subAdminLoading,
   };
}
