import { Fragment } from "react";

export default function TableRow({
   className,
   children,
   gridTemplateCols,
   rowTypeHeader,
}) {
   const rowClass = `${className} grid ${
      gridTemplateCols || "grid-cols-custom-table-1"
   }`;

   return (
      <Fragment>
         <div className={rowClass}>{children}</div>
         {!rowTypeHeader && <hr className="w-full mx-auto" />}
      </Fragment>
   );
}
