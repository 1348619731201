export const handleBankSelect = (bankName, bankData, setValue, clearErrors) => {
   const selectedBank = bankData.filter((bank) => bank.name === bankName);
   if (selectedBank.length < 1) {
      return;
   }
   const bankCode = selectedBank[0].code;
   //update bank name and code from bank list
   setValue("bank_name", bankName);
   setValue("bank_code", bankCode);
   clearErrors("bank_name");
};
