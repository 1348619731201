import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const organizationDetailsSchema = yup.object().shape({
   organization_name: yup
      .string()
      .required("organization name cannot be empty")
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "Please enter a valid organization name"),
   institution_type: yup
      .string()
      .required("at least one institution must be selected")
      .typeError("at least one institution must be selected"),
   state: yup.string().required("state cannot be empty"),
   street: yup.string().required("address cannot be empty"),
   phone: yup
      .string()
      .required("phone number cannot be empty")
      .matches(REGEX.PHONE_NUMBER_VALIDATION, "Please enter a valid phone number"),
   account_type: yup.string().required("account type cannot be empty"),
   date_of_birth: yup.string().when(["account_type"], {
      is: (account_type) => account_type === "individual",
      then: yup
         .string()
         .required("date of birth cannot be empty")
         .test("is-valid", "please enter valid date", (value) => {
            // date is in the format of "dd-mm-yyyy"
            return value.split("-").join("").length === 8;
         }),
   }),
});

export const cacDetailsSchema = yup.object().shape({
   company_name: yup
      .string()
      .required("organization name cannot be empty")
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "Please enter a valid organization name"),
   company_reg_no: yup
      .string()
      .required("company reg no cannot be empty")
      .length(9, "Please enter a valid company reg no")
      .typeError("company reg no cannot be empty"),
   incorporation_date: yup
      .string()
      .required("incorporation date cannot be empty")
      .test("is-valid", "please enter valid date", (value) => {
         // date is in the format of "dd-mm-yyyy"
         return value.split("-").join("").length === 8;
      }),
   identity_image: yup.string().required("identity image cannot be empty"),
   cac_image: yup.string().required("cac image cannot be empty"),
});
