export default function CustomErrorIcon({ className, color }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.41 84.41" className={className}>
         <defs>
            <style>{`.cls-error{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-error"
                  d="M42.2,84.41A42.21,42.21,0,1,1,84.41,42.2,42.26,42.26,0,0,1,42.2,84.41ZM42.2,9A33.21,33.21,0,1,0,75.41,42.2,33.25,33.25,0,0,0,42.2,9Z"
               />
               <line className="cls-2" x1="29.31" y1="27.81" x2="55.1" y2="56.59" />
               <path
                  className="cls-error"
                  d="M55.1,61.09a4.49,4.49,0,0,1-3.35-1.49L26,30.82a4.5,4.5,0,1,1,6.71-6L58.45,53.59a4.49,4.49,0,0,1-3.35,7.5Z"
               />
               <line className="cls-2" x1="55.1" y1="27.81" x2="29.31" y2="56.59" />
               <path
                  className="cls-error"
                  d="M29.3,61.09A4.5,4.5,0,0,1,26,53.59l25.8-28.78a4.5,4.5,0,1,1,6.7,6L32.66,59.6A4.5,4.5,0,0,1,29.3,61.09Z"
               />
            </g>
         </g>
      </svg>
   );
}
