import LimitModal from "../features/spending-limit/limit-modal";
import LoadingModal from "features/ui/feedbacks/loading-modal";
import ModalContainer from "features/modal/modal-container.component";
import Numpad from "features/numpad/numpad.component";
import useUpdateSpendingLimit from "features/spending-limit/update-spending-limit.api";

export default function SetSpendingLimit() {
   const {
      state,
      dispatch,
      handleClick,
      limitModal,
      pin,
      setPin,
      handleSubmit,
      numpadModalIsOpen,
      setNumpadModalIsOpen,
      loading,
   } = useUpdateSpendingLimit();

   return (
      <>
         <LimitModal
            state={state}
            dispatch={dispatch}
            handleClick={handleClick}
            modalDisplay={limitModal}
         />
         <ModalContainer
            onClose={() => {
               setPin("");
               setNumpadModalIsOpen(false);
            }}
            isOpen={numpadModalIsOpen}
         >
            <div className="md:pb-16 md:pt-4">
               <Numpad
                  pin={pin}
                  setPin={setPin}
                  title="transaction pin"
                  background="BLUE"
                  btnText="proceed"
                  btnOnClick={handleSubmit}
                  amount={state.formattedAmount}
                  message="confirm spending limit"
               />
            </div>
         </ModalContainer>
         <LoadingModal
            isOpen={loading}
            message="please wait while the transaction limit is being set"
         />
      </>
   );
}
