import NumberFormat from "react-number-format";

import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import RadioBtn from "features/ui/forms/radio-btn";
import FormGroup from "features/ui/forms/form-group";
import PageTitle from "features/ui/layout/headers/page-title";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import CardContainer from "features/ui/cards/card-container";
import { useNavigate } from "react-router-dom";

export default function LimitModal({ state, dispatch, handleClick }) {
   const navigate = useNavigate();
   const filterOptions = ["daily", "weekly", "monthly"];

   const handleNumberFormat = (values, input, formattedInput) => {
      const { value, formattedValue } = values;
      dispatch({
         type: "UPDATE_INPUT",
         payload: { [input]: value, [formattedInput]: formattedValue },
      });
   };

   const handleSelect = (value) => {
      dispatch({ type: "UPDATE_INPUT", payload: { schedule: value } });
   };

   const label =
      state.schedule === "weekly"
         ? "amount/week"
         : state.schedule === "daily"
         ? "amount/day"
         : "amount/month";

   return (
      <CardContainer>
         <div className="container justify-center min-h-screen ax-w-sm md:min-h-0 md:py-12">
            <ArrowBack handleBack={() => navigate(-1)} />
            <PageTitle
               variant="SECONDARY"
               title="set spending limit"
               message="This is the maximum amounts students can spend in their Ledga"
            />
            <p className="text-center font-semibold">
               click to choose from the option below
            </p>
            <RadioBtn
               options={filterOptions}
               selected={state.schedule}
               handleSelect={handleSelect}
            />
            <FormGroup className="self-stretch  my-16  md:my-6">
               <NumberFormat
                  customInput={Input}
                  value={state.amount}
                  prefix={"₦"}
                  thousandSeparator={true}
                  label={label}
                  placeholder="₦"
                  errorMessage={{
                     requiredError: "amount cannot be empty",
                     validityError: "please enter a valid amount",
                  }}
                  onValueChange={(values) =>
                     handleNumberFormat(values, "amount", "formattedAmount")
                  }
               />
            </FormGroup>
            <Button size="WIDE" onClick={handleClick}>
               confirm
            </Button>
         </div>
      </CardContainer>
   );
}
