import { useNavigate } from "react-router-dom";
import { Controller, useWatch } from "react-hook-form";

import useBills from "features/bills/api/bills.api";
import useSavings from "features/savings/api/savings.api";

import Form from "features/ui/forms/form";
import Select from "features/ui/forms/select";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import { withdrawSchema } from "./withdraw.utils";
import { ErrorMessage } from "@hookform/error-message";
import { getFormattedAmount } from "utils/lib/number-formatter";

export const options = [
   { name: "Savings", value: "savings" },
   { name: "Sub Account", value: "bill" },
   { name: "Main Account", value: "main" },
   { name: "External Account", value: "external" },
];

export default function WithdrawForm() {
   const navigate = useNavigate();
   const hookFormOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         source: "savings",
         id: "",
         to: "external",
      },
   };

   const handleSubmit = (data) => {
      navigate(
         `/dashboard/withdraw/${data.source}/${data.destination}?sourceId=${data.sourceId}&destinationId=${data.destinationId}`
      );
   };

   return (
      <>
         <Form
            schema={withdrawSchema}
            id="signupform"
            options={hookFormOptions}
            className="mb-10 pt-20 w-5/6 max-w-screen-sm"
            onSubmit={handleSubmit}
         >
            {(hookFormMethods) => <FormDetails hookFormMethods={hookFormMethods} />}
         </Form>

         <div className="flex items-center justify-between">
            <Button type="submit" form="signupform" size="EXTRA_WIDE">
               <span className="flex items-center justify-center w-16 h-5 md:w-20 md:h-6 border-box">
                  <span className="text-sm md:text-base">Continue</span>
               </span>
            </Button>
         </div>
      </>
   );
}

const FormDetails = ({ hookFormMethods }) => {
   const {
      register,
      control,
      setValue,
      formState: { errors },
   } = hookFormMethods;

   return (
      <>
         <WithdrawSourceForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
         />
         <WithdrawDestinationForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
         />
      </>
   );
};

const SavingsList = ({ register, activeId, fieldName }) => {
   const { savings } = useSavings();
   return (
      <>
         <style jsx>{`
            ::-webkit-scrollbar {
               width: 8px;
               height: 8px;
               border-radius: 8px;
               margin-left: 2px;
            }

            ::-webkit-scrollbar-track {
               background: inherit;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 5px;
            }

            ::-webkit-scrollbar-thumb:hover {
               background: #555;
            }
         `}</style>
         <div className="px-2 space-y-6">
            {savings?.map((s) => (
               <label
                  className={`block   ${
                     activeId === s.unique_id
                        ? "outline outline-primary outline-offset-[3px] rounded-lg"
                        : "outline-none"
                  }`}
               >
                  <input
                     {...register(fieldName)}
                     type="radio"
                     value={s.unique_id}
                     className="hidden"
                  />
                  <Card
                     key={s._id}
                     uniqueId={s.unique_id}
                     title={s.title}
                     balance={s.meta?.balance ?? 0}
                  />
               </label>
            ))}
         </div>
      </>
   );
};

const BillsList = ({ register, activeId, fieldName }) => {
   const { bills } = useBills();
   return (
      <>
         <style jsx>{`
            ::-webkit-scrollbar {
               width: 8px;
               height: 8px;
               border-radius: 8px;
               margin-left: 2px;
            }

            ::-webkit-scrollbar-track {
               background: inherit;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 5px;
            }

            ::-webkit-scrollbar-thumb:hover {
               background: #555;
            }
         `}</style>
         <div className="px-2 space-y-6">
            {bills?.map((b) => (
               <label
                  className={`block   ${
                     activeId === b.unique_id
                        ? "outline outline-primary outline-offset-[3px] rounded-lg"
                        : "outline-none"
                  }`}
               >
                  <input
                     {...register(fieldName)}
                     type="radio"
                     value={b.unique_id}
                     className="hidden"
                  />
                  <Card
                     key={b._id}
                     bill={b}
                     uniqueId={b.unique_id}
                     title={b.title}
                     balance={b.meta?.balance ?? 0}
                  />
               </label>
            ))}
         </div>
      </>
   );
};

const Card = ({ uniqueId, title, balance }) => {
   return (
      <div className="text-start capitalize text-base p-3 font-semibold md:text-lg md:py-6 bg-white rounded-lg">
         <p className="font-normal poppins-medium text-[16px]">{title}</p>
         <p className="font-normal poppins-medium text-[16px]">{uniqueId}</p>
         <p>{getFormattedAmount(balance)}</p>
      </div>
   );
};

function WithdrawSourceForm({ setValue, control, register, errors }) {
   const [source, sourceId] = useWatch({ control, name: ["source", "sourceId"] });
   const withdrawOptions = options.filter((j) => j.value !== "external");
   return (
      <section className="mb-6">
         <h2 className="text-xl mb-2">Select Transfer Source</h2>
         <div className="border rounded-lg p-6">
            <FormGroup className="mb-6">
               <Controller
                  control={control}
                  name="source"
                  render={({ field: { onChange, name, value } }) => (
                     <Select
                        value={value}
                        name={name}
                        label="Source"
                        error={errors.source}
                        options={withdrawOptions}
                        handleChange={(value) => {
                           setValue("destination", null);
                           setValue("sourceId", value === "main" ? "main" : null);
                           onChange(value);
                        }}
                     />
                  )}
               />
            </FormGroup>
            <ErrorMessage
               errors={errors}
               name="sourceId"
               render={({ message }) => <p className="text-red-600 text-sm px-6">{message}</p>}
            />
            <div className="max-h-[350px] overflow-y-scroll">
               {source === "savings" ? (
                  <SavingsList
                     register={register} //
                     activeId={sourceId}
                     fieldName="sourceId"
                  />
               ) : null}
               {source === "bill" ? (
                  <BillsList
                     register={register} //
                     activeId={sourceId}
                     fieldName="sourceId"
                  />
               ) : null}
            </div>
         </div>
      </section>
   );
}

function WithdrawDestinationForm({ setValue, control, register, errors }) {
   const [destination, destinationId, source] = useWatch({
      control,
      name: ["destination", "destinationId", "source"],
   });

   const destinationOptions = options.filter((v) => {
      return source === "main" ? v.value !== source : !["savings", "bill"].includes(v.value);
   });

   if (!destinationOptions) {
      return null;
   }

   return (
      <section className="mb-8">
         <h2 className="text-xl">Select Transfer Destination</h2>
         <div className="border rounded-lg p-6">
            <FormGroup className="mb-6">
               <Controller
                  control={control}
                  name="destination"
                  render={({ field: { onChange, name, value } }) => (
                     <Select
                        value={value}
                        name={name}
                        label="destination"
                        error={errors.source}
                        options={destinationOptions}
                        handleChange={(value) => {
                           setValue("destinationId", value === "main" ? "main" : null);
                           onChange(value);
                        }}
                     />
                  )}
               />
            </FormGroup>
            <ErrorMessage
               errors={errors}
               name="destinationId"
               render={({ message }) => <p className="text-red-600 text-sm px-6">{message}</p>}
            />
            <div className="max-h-[350px] overflow-y-scroll">
               {destination === "savings" ? (
                  <SavingsList
                     register={register}
                     activeId={destinationId}
                     fieldName="destinationId"
                  />
               ) : null}
               {destination === "bill" ? (
                  <BillsList
                     register={register}
                     activeId={destinationId}
                     fieldName="destinationId"
                  />
               ) : null}
            </div>
         </div>
      </section>
   );
}
