export default function CustomManagersIcon({ className, color = "#1358CC" }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.56 90.5" className={className}>
         <defs>
            <style>{`.cls-managers{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-managers"
                  d="M70,86.36a4.55,4.55,0,0,1-4.54-4.55c0-15.23-12.65-27.63-28.2-27.63S9.09,66.58,9.09,81.81a4.55,4.55,0,1,1-9.09,0C0,61.57,16.72,45.09,37.28,45.09S74.56,61.57,74.56,81.81A4.55,4.55,0,0,1,70,86.36Z"
               />
               <path
                  className="cls-managers"
                  d="M34.69,55.75l-1.44.9a2.12,2.12,0,0,0-.74,3.1l1.11,1.56A2,2,0,0,1,34,63L29.16,82.91a2.09,2.09,0,0,0,.66,2l5,4.74a3.41,3.41,0,0,0,4.64-.16l4.13-4.62A2.07,2.07,0,0,0,44.12,83L39.27,62.79a2,2,0,0,1,.33-1.63l1-1.48a2.12,2.12,0,0,0-.87-3.13l-1.53-.87A3.51,3.51,0,0,0,34.69,55.75Z"
               />
               <path
                  className="cls-managers"
                  d="M37.28,47.62A23.81,23.81,0,1,1,61.09,23.81,23.84,23.84,0,0,1,37.28,47.62Zm0-38.53A14.72,14.72,0,1,0,52,23.81,14.73,14.73,0,0,0,37.28,9.09Z"
               />
            </g>
         </g>
      </svg>
   );
}
