import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";

import { useAdmin } from "features/auth/admin-context";
import BillsEditorForm from "features/bills/components/bill-editor-form";
import useUpdateBill from "features/bills/api/update-bill.api";

export default function EditBill() {
   const { id: billId } = useParams();
   const { adminBankDetails } = useAdmin();
   const { handleBillUpdateBill } = useUpdateBill(billId);

   // ensure bank details is available as it is used as default value in form
   if (!adminBankDetails.bankName) {
      return null;
   }

   return (
      <section className="wrapper bg-[#FCFDFF] md:bg-[#F3F3F3]">
         <Link to="../../dashboard/savings-accounts">
            <IoArrowBack className="fixed top-10 left-4 w-12 md:w-8 cursor-pointer md:hidden text-4xl" />
         </Link>
         <div className="container sef left text-primary pt-20 md:max-w-xl">
            <div className="self-stretch justify-items-center gap-3 lg:gap-6">
               <BillsEditorForm
                  adminBankDetails={adminBankDetails}
                  editingId={billId}
                  handleSubmit={handleBillUpdateBill}
                  editingMode="edit"
               />
            </div>
         </div>
      </section>
   );
}
