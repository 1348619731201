import TableRow from "../tables/table-row";
import TableCol from "../tables/table-col";

export default function ReceiptField({ label, value }) {
   if (!value) {
      return;
   }

   return (
      <div className="my-[5px] flex justify-between">
         <p className="poppins-regular text-sm capitalize text-[#707070]">{label}</p>
         <p className="poppins-semibold justify-start text-sm text-[13px] capitalize text-[#333333]">
            {label === "name" ? value.toLowerCase() : value}
         </p>
      </div>
   );
}
