import { axios, axiosClient } from "utils/lib/axios";

export const addMerchantsBulk = async (queryObj) => {
   return axios.post("admin/merchants/bulk", queryObj);
};

export const getMerchantsUrl = (search = "", page = 1, pageSize = 50) => {
   return `admin/merchants?search=${search}&page=${page}&pageSize=${pageSize}`;
};

export const addStudentsBulk = async (queryObj) => {
   return axios.post("admin/students/bulk?isEmailOptional=true", queryObj);
};

export const addMembers = async (queryObj) => {
   return axiosClient.post("admin/members", queryObj);
};

export const getMembersUrl = (search = "", page = 1, pageSize = 50) => {
   return `admin/members?search=${search}&page=${page}&pageSize=${pageSize}`;
};

export const updateMember = (queryObj, memberId) => {
   return axiosClient.patch(`admin/member/${memberId}/update`, queryObj);
};

export const deleteMember = ({ id }) => {
   return axiosClient.patch(`admin/member/${id}/delete`);
};

export const getPaidBillsUrl = (studentId) => {
   return `admin/students/${studentId}/bills/paid`;
};

export const getSingleMemberUrl = (studentId) => {
   return `admin/member?userId=${studentId}`;
};

export const getSingleMerchantUrl = (merchantId) => {
   return `admin/merchant?merchantId=${merchantId}`;
};

export const transferToUser = (queryObj) => {
   return axiosClient.post(`admin/transfer-member`, queryObj);
};
