import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { ErrorMessage } from "@hookform/error-message";

import Form from "features/ui/forms/form";
import FormGroup from "features/ui/forms/form-group";
import * as NumberFormatter from "utils/lib/number-formatter";

import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import ModalContainer from "features/modal/modal-container.component";
import * as yup from "yup";
import { useEffect } from "react";

export default function AmountModal({
   dispatch,
   onClose,
   isOpen,
   handleClick,
   balance,
   modalPosition,
   hideOnBlur,
   showCloseBtn,
   defaultValue = null,
   disabledInput = false,
}) {
   useEffect(() => {
      dispatch({
         type: "updateInput",
         payload: { name: "amount", value: defaultValue },
      });
   }, []);

   const handleNumberFormat = (values, input, formattedInput) => {
      const { value, formattedValue } = values;
      dispatch({
         type: "updateInput",
         payload: { name: input, value: value },
      });
      dispatch({
         type: "updateInput",
         payload: { name: formattedInput, value: formattedValue },
      });
   };

   return (
      <ModalContainer
         size="THIN"
         isOpen={isOpen}
         onClose={onClose}
         modalPosition={modalPosition}
         showCloseBtn={showCloseBtn}
         hideOnBlur={hideOnBlur}
      >
         {/* <ArrowBack handleBack={onClose} /> */}

         <div className="container  min-h-[100vh] full-page items-center justify-center md:min-h-0 md:py-24">
               <h2 className="text-2xl mb-10 font-semibold capitalize">enter amount</h2>
               <p className="mb-3 text-yellow-600 text-lg text-left self-start">
                  bal: {NumberFormatter.getFormattedAmount(balance ?? 0)}
               </p>
            <AmountForm
               handleNumberFormat={handleNumberFormat}
               handleSubmit={handleClick}
               balance={balance}
               defaultValue={defaultValue}
               disabledInput={disabledInput}
            />
         </div>
      </ModalContainer>
   );
}

const AmountForm = ({ handleNumberFormat, handleSubmit, balance, defaultValue, disabledInput }) => {
   const hookFormOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         amount: defaultValue,
      },
   };

   const amountSchema = getAmountSchema(Number(balance));

   return (
      <>
         <Form
            id="amountform"
            schema={amountSchema}
            options={hookFormOptions}
            className="self-stretch mb-12"
            onSubmit={handleSubmit}
         >
            {({ control, formState: { errors } }) => (
               <>
                  <FormGroup className="mb-6">
                     <Controller
                        control={control}
                        name="amount"
                        defaultValue=""
                        render={({ field: { onChange, name, value } }) => (
                           <NumberFormat
                              customInput={Input}
                              prefix={"₦"}
                              thousandSeparator={true}
                              label="amount"
                              placeholder="₦"
                              autoFocus={true}
                              disabled={disabledInput}
                              name={name}
                              value={value}
                              onValueChange={(values) => {
                                 handleNumberFormat(values, "amount", "formattedAmount");
                                 onChange(values.value);
                              }}
                           />
                        )}
                     />
                     <ErrorMessage
                        errors={errors}
                        name="amount"
                        render={({ message }) => <p className="text-red-600 text-xs">{message}</p>}
                     />
                  </FormGroup>

                  <p className="text-xs pl-1 -mt-4">
                     note that a processing fee of ₦5 is charged by the bank
                  </p>
               </>
            )}
         </Form>
         <Button type="submit" form="amountform">
            proceed
         </Button>
      </>
   );
};

const getAmountSchema = (balance) => {
   return yup.object().shape({
      amount: yup
         .number()
         .max(balance || 0, "number cannot be greater than balance")
         .min(100, "amount cannot be less than 100`")
         .required("amount cannot be empty"),
   });
};
