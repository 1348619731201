import { Tooltip } from "react-tooltip";
import { useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import Checkbox from "features/ui/forms/check-box";
import Select from "features/ui/forms/select";

export default function ManagerSelector({ methods, subAdminNames, permissions }) {
   const managerAccess = useWatch({ control: methods.control, name: "manager_access" });
   const mappedSubAdmins = subAdminNames.map((s) => ({
      ...s,
      disabled: managerAccess.find((a) => a.admin_id === s.value),
   }));

   console.log(mappedSubAdmins);
   if (!managerAccess || mappedSubAdmins.length < 1) {
      return null;
   }

   function addRow() {
      methods.setValue("manager_access", [
         ...managerAccess,
         {
            admin_id: "",
            permissions: convertPermissionsToObject(permissions),
         },
      ]);
   }

   function removeRow(idx) {
      const filtered = managerAccess.filter((ma, i) => i !== idx);
      methods.setValue("manager_access", filtered);
   }

   return (
      <section>
         <h2 className="text-xl text-black-three">Select Managers </h2>
         <ErrorMessage
            errors={methods.formState?.errors}
            name="manager_access"
            render={({ message }) => <p className="text-red-600 text-sm">{message}</p>}
         />
         <div className="bg-white p-4 mb-6 rounded-lg flex flex-col items-center">
            {managerAccess.map((manager, idx) => {
               return (
                  <FormGroup className="relative mb-6 space-y-4 w-full" key={idx}>
                     {idx > 0 ? (
                        <button
                           type="button"
                           onClick={() => removeRow(idx)}
                           className="absolute text-white -top-3 -right-0  w-6 h-6 p-1 border rounded-full bg-black-two text-[10px] flex items-center justify-center hover:opacity-75"
                        >
                           x
                        </button>
                     ) : null}
                     <div
                        data-tooltip-id="manager-tooltip"
                        data-tooltip-content="Select Manager For this Group"
                        data-tooltip-place="bottom"
                     >
                        <Tooltip id="manager-tooltip" />
                        <Controller
                           control={methods.control}
                           name={`manager_access.${idx}.admin_id`}
                           defaultValue=""
                           render={({ field: { onChange, name, value } }) => (
                              <Select
                                 value={value}
                                 name={name}
                                 label="Manager"
                                 error={methods.errors?.manager}
                                 options={mappedSubAdmins}
                                 handleChange={(value) => {
                                    onChange(value);
                                 }}
                              />
                           )}
                        />
                     </div>

                     <FormGroup>
                        {permissions.map((p) => {
                           return (
                              <div
                                 className="mb-2 mx-1"
                                 data-tooltip-id={p.label}
                                 data-tooltip-content={`Allow this manager to ${p.label}`}
                                 data-tooltip-place="bottom"
                              >
                                 <Tooltip id={p.label} />
                                 <Controller
                                    control={methods.control}
                                    name={`manager_access.${idx}.permissions.${p.fieldName}`}
                                    render={({ field: { onChange, name, value } }) => (
                                       <Checkbox
                                          variant="SECONDARY"
                                          name={name}
                                          value={value}
                                          label={p.label}
                                          handleChange={onChange}
                                       />
                                    )}
                                 />
                              </div>
                           );
                        })}
                     </FormGroup>
                  </FormGroup>
               );
            })}
            <Button type="button" onClick={addRow}>
               Add
            </Button>
         </div>
      </section>
   );
}

/**
 *
 * @example
 * // returns {edit_members: false, view_bills:false}
 *  convertPermissionsToObject([
 *    {label: "Edit Members", fieldName:"edit_members"},
 *    {label: "View Sub Accounts", fieldName:"view_bills"}
 *  ])
 */

const convertPermissionsToObject = (permissions) => {
   return permissions.reduce((r, k) => {
      r[k.fieldName] = false;
      return r;
   }, {});
};
