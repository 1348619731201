export default function NotificationIcon({ className }) {
   return (
      <svg
         width="32"
         height="32"
         viewBox="0 0 32 32"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         className={className}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.334 28.0189C14.6353 28.0189 13.2486 26.7256 13.066 25.0736H19.6033C19.4207 26.7256 18.0327 28.0189 16.334 28.0189ZM28.206 25.0736L27.486 24.0283C26.1287 22.0589 25.4113 19.7549 25.4113 17.3656V13.6416C25.4113 8.97359 21.87 5.12025 17.334 4.62025V2.35092H15.334V4.62025C10.7979 5.12025 7.25528 8.97359 7.25528 13.6416V17.3656C7.25528 19.7576 6.53795 22.0616 5.18062 24.0283L4.46062 25.0736H11.066C11.2553 27.8283 13.5327 30.0189 16.334 30.0189C19.1353 30.0189 21.414 27.8283 21.6033 25.0736H28.206Z"
            fill="#0A0A0A"
         />
      </svg>
   );
}
