import Form from "features/ui/forms/form";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";

import ImageUpload from "features/ui/forms/image-upload";

import ArrowIcon from "../../assets/icons/LedgaV2 Icons/Arrow.png";

export default function ProfileImageForm({ handleNext }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         profile_image_url:
            "https://ik.imagekit.io/thelastkingpin/clouddust/book%20covers/sanyay.jpg?updatedAt=1694393545671",
      },
   };

   return (
      <Form id="profileForm" options={formOptions} onSubmit={(data) => handleNext(data)}>
         {(methods) => <FormDetails methods={methods} handleNext={handleNext} />}
      </Form>
   );
}

export const FormDetails = ({ methods, handleNext }) => {
   const {
      setValue,
      clearErrors,
      formState: { errors },
   } = methods;

   const onSuccess = (data) => {
      clearErrors("profile_image_url");
      setValue("profile_image_url", data);
   };

   // TODO: This should probably be in an .env file
   const defaultImageUrl =
      "https://ik.imagekit.io/thelastkingpin/clouddust/book%20covers/sanyay.jpg?updatedAt=1694393545671";

   return (
      <FormGroup className="flex flex-col self-center gap-2 items-center mb-10">
         <FormGroup>
            <ImageUpload
               label="Upload profile image"
               variant="PROFILE"
               errorMessage={errors?.profile_image_url?.message}
               onSuccess={(data) => onSuccess(data)}
            />
         </FormGroup>
         <Button
            variant="TERTIARY"
            color="BLUE_TEXT"
            type="button"
            onClick={() => {
               // default profile image
               onSuccess(defaultImageUrl);
               handleNext({ profile_image_url: defaultImageUrl });
            }}
         >
            skip
         </Button>

         <Button size="WIDE" type="submit" variant="PRIMARYV2" form="profileForm">
            Go Home
            <img src={ArrowIcon} alt="" className="h-[35px]" />
         </Button>
      </FormGroup>
   );
};
