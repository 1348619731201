import BillInfo from "./bill-info";
import useDeleteBill from "../api/delete-bill.api";
import Button from "features/ui/forms/button";
import Spinner from "features/ui/feedbacks/spinner";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import PageTitle from "features/ui/layout/headers/page-title";
import { getFormattedAmount } from "utils/lib/number-formatter";
import ModalContainer from "features/modal/modal-container.component";

export default function DeleteModal({ bill, modalDisplay, setModalDisplay }) {
   const { handleDelete, deleteLoading } = useDeleteBill(bill, setModalDisplay);

   return (
      <>
         <ModalContainer
            isOpen={modalDisplay}
            onClose={() => setModalDisplay(false)}
            modalPosition="CENTER"
         >
            <div className="container min-h-screen justify-center md:min-h-0 md:py-20">
               <ArrowBack handleBack={() => setModalDisplay(false)} />

               <PageTitle
                  variant="SECONDARY"
                  title="delete bill"
                  message="Are you sure you want to delete this bill?"
               />
               <div className="self-start mb-10 md:-ml-6">
                  <BillInfo title="name" value={bill.title} />
                  <BillInfo title="id" value={bill.unique_id} />
                  <BillInfo title="amount" value={getFormattedAmount(bill.amount)} />
                  <BillInfo title="bal" value={getFormattedAmount(bill.meta.balance)} />
               </div>
               <Button color="RED" onClick={handleDelete} size="WIDE">
                  {deleteLoading ? <Spinner color="WHITE" /> : "delete bill"}
               </Button>
            </div>
         </ModalContainer>
      </>
   );
}
