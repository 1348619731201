import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import Header from "features/ui/layout/headers/dashboard-header";
import EmailSuccess from "../features/ui/feedbacks/email-success";
import FormGroup from "features/ui/forms/form-group";

export default function ContactUs() {
   const navigate = useNavigate();
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [body, setBody] = useState("");

   const [successModal, setSuccessModal] = useState(false);
   const toggleSuccessModal = (value) => setSuccessModal(value);

   const handleSubmit = () => {
      toggleSuccessModal(true);
      setTimeout(() => {
         navigate("../../../dashboard");
      }, 3000);
      return () => clearTimeout;
   };

   return (
      <section className="wrapper bg-white flex flex-col px-[20px] md:px-[50px] min-h-screen">
         <Header variant="OTHERS" title="Contact Us" settingsUrl="../settings" to={"../settings"} />
         <div className="container-new-gray">
            <div className="self-start w-4/5 mx-auto py-20">
               <h2 className="text-xl md:text-2xl">Send Email </h2>
               <p className="text-lg">How Can We Help You</p>
               <form action="mailto:hello@ledgafinance.com" method="post" className="mb-4 w-full">
                  <FormGroup className="mb-16 self-stretch w-full">
                     <Input
                        value={name}
                        type="text"
                        name="name"
                        label="name"
                        onChange={(e) => setName(e.target.value)}
                     />
                     <Input
                        value={email}
                        type="text"
                        name="email"
                        label="email"
                        onChange={(e) => setEmail(e.target.value)}
                     />
                  </FormGroup>
                  <label>
                     <span>Feedback:</span>
                     <textarea
                        value={body}
                        name="feedback"
                        rows="12"
                        cols="35"
                        className="rounded-lg w-full p-4"
                        onChange={(e) => setBody(e.target.value)}
                     >
                        {body}
                     </textarea>
                  </label>
               </form>
               <FormGroup className="w-full flex justify-center">
                  <Button size="WIDE" disabled={body.length < 3} onClick={handleSubmit}>
                     send
                  </Button>
                  {/* <Button
                     disabled={body.length < 3}
                     size="ZERO"
                     variant="TERTIARY"
                     color="BLUE_TEXT"
                     onClick={() => setBody("")}
                  >
                     reset
                  </Button> */}
               </FormGroup>
               <EmailSuccess
                  successModal={successModal}
                  toggleSuccessModal={toggleSuccessModal}
                  modalPosition="CENTER"
                  outsideOnClick={() => body.length > 3 && navigate("../../../dashboard")}
               />
            </div>
         </div>
      </section>
   );
}
