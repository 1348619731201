import { useNavigate } from "react-router-dom";

import Button from "features/ui/forms/button";
import PageTitle from "features/ui/layout/headers/page-title";
import CardContainer from "features/ui/cards/card-container";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import useSetPayout from "features/set-payout/set-payout.api";
import SetPayoutForm from "features/set-payout/set-payout-form";

export default function SetPayoutModal() {
   const navigate = useNavigate();
   const { handleSubmit } = useSetPayout();

   return (
      <CardContainer>
         <ArrowBack handleBack={() => navigate(-1)} />
         <div className="container justify-center md:py-12">
            <PageTitle
               variant="SECONDARY"
               title="Set Payout Date"
               message="Set a convenient date for students to withdraw their funds without approval"
            />
            <p className="text-center font-semibold">
               click to choose from the option below
            </p>
            {/* formId must match the form attribute on the submit button*/}
            <SetPayoutForm handleSubmit={handleSubmit} formId="set-payout" />
            <Button form="set-payout">set date</Button>
         </div>
      </CardContainer>
   );
}
