import SplashScreen from "features/ui/layout/splash-screen";
import PageTitle from "features/ui/layout/headers/page-title";

export default function AuthLayout({ children, subtitle }) {
   return (
      <section className="flex items-stretch space-between min-h-screen bg-white md:pl-[25vw]">
         <SplashScreen />
         <div className="container justify-center md:self-center p-10 w-full md:w-[90vw] max-w-[600px] bg-gray-200 rounded-lg">
            <p className="font-semibold mb-4 text-left w-full">Experience freedom with</p>
            <PageTitle title="Ledga" message={subtitle} variant="TERTIARY" />
            <div className="w-full">{children}</div>
         </div>
      </section>
   );
}
