import AddUsersSpreadsheet from "features/add-users/components/spreadsheet";

import useRequest from "utils/hooks/useRequest";
import DuplicatesFound from "features/add-users/components/duplicates-modal";
import useCreateUser from "features/add-users/api/create-user.api";
import { addMerchantsBulk } from "features/add-users/users.service";

export default function AddMerchants() {
   const { queryFn: createMerchantsFn } = useRequest(addMerchantsBulk);

   const {
      createUsers, //
      duplicateEntries,
      duplicatesModal,
      closeDuplicateModal,
   } = useCreateUser(createMerchantsFn);

   return (
      <section className="wrapper">
         <AddUsersSpreadsheet usersType="merchants" createUsers={createUsers} />
         <DuplicatesFound
            usersType="merchants"
            isOpen={duplicatesModal}
            onClose={closeDuplicateModal}
            duplicateEntries={duplicateEntries}
         />
      </section>
   );
}
