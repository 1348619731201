import SplashScreen from "features/ui/layout/splash-screen";
import PageTitle from "features/ui/layout/headers/page-title";
import ArrowBack from "features/ui/layout/nav/arrow-back";

export default function SetupLayout({ title, message, handleBack, children, setScreen, screen }) {
   return (
      <section>
         <div className="mx-auto flex flex-col md:pl-[25vw] py-5">
            <SplashScreen />

            <div className="container lg:w-[700px] lg:max-w-[800px] justify-center items-center min-h-screen pt-6">
               .
               <StepTracker screen={screen} />
               {handleBack && <ArrowBack variant="PRIMARYV2" handleBack={handleBack} />}
               {/* {handleBack && (
                  <button onClick={() => handleBack()}>
                     <img
                        src={ArrowIcon}
                        alt="arrow"
                        className="absolute top-2 h-10 w-10 rotate-180"
                     />
                  </button>
               )} */}
               <PageTitle variant="TERTIARY" title={title} message={message} />
               {children}
            </div>
         </div>
      </section>
   );
}

const StepTracker = ({ screen }) => {
   return (
      <div className="flex flex-row items-center self-center gap-1 absolute  top-5">
         <div
            className={`h-6 w-6 border-2 border-gray-400 ${
               screen >= 1 && "bg-blue-500"
            }  rounded-full`}
         ></div>
         <div className="w-10 md:w-20 h-[2px] bg-gray-400"></div>
         <div
            className={`h-6 w-6 border-2 border-gray-400 ${
               screen >= 2 && "bg-blue-500"
            }  rounded-full`}
         ></div>
         <div className="w-10 md:w-20 h-[2px] bg-gray-400"></div>
         <div
            className={`h-6 w-6 border-2 border-gray-400 ${
               screen >= 3 && "bg-blue-500"
            }  rounded-full`}
         ></div>
         <div className="w-10 md:w-20 h-[2px] bg-gray-400"></div>
         <div
            className={`h-6 w-6 border-2 border-gray-400 ${
               screen >= 4 && "bg-blue-500"
            }  rounded-full`}
         ></div>
      </div>
   );
};
