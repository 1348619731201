import { useState } from "react";
import { useCallback } from "react";

/*
 *useRequest receives an axios post request from that returns a promise
 *and returns an axios request and various states i.e data, loading, error states
 *the axios post request can then be called onSubmit and the various status of the
 *request tracked using the states returned alongside it.
 */

// most post request requires just the config objects, other require an id as well

/**
   rest is often used to get query params like ids, page, search etc that are usually but 
   not always available at the time of useRequest call. Any parameter that doesn't go in the
   queryObj is passed here. 
*/

const useRequest = (service, ...rest) => {
   const [data, setData] = useState("");
   const [loading, setIsLoading] = useState(false);
   const [error, setError] = useState("");

   /*create a function that receives queryObj makes axios query call when called*/
   /* queryFn is called in a useCallback so it can be called inside a useEffect 
   without causing infinite re-renders */
   const queryFn = useCallback(
      (queryObj, options) => {
         const { onSuccess, onError } = options;

         setIsLoading(true);
         setData("");
         setError("");

         service(queryObj, ...rest)
            .then((response) => {
               setIsLoading(false);
               const { data } = response;
               if (data.status === "error") {
                  onError && onError(data.message);
                  setError(data.message);
               } else if (data.status === "success") {
                  onSuccess && onSuccess(data.data);
                  setData(data.data);
               }
            })
            .catch((err) => {
               setIsLoading(false);
               onError && onError(err.message);
               setError(err.message);
            });
      },
      [service, rest]
   );
   return { queryFn, data, loading, error };
};

export default useRequest;
