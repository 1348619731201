import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";

export default function SelectIcon({ variant, isSelected, src, icon }) {
   if (variant === "MULTIPLE") {
      const check = isSelected ? (
         <AiFillCheckCircle />
      ) : (
         <AiOutlineCheckCircle />
      );
      return check;
   } else if (src) {
      return (
         <img
            src={`/icons/${src}`}
            alt={src}
            className={isSelected ? "filter-yellow-500" : "filter-primary"}
         />
      );
   } else {
      return icon;
   }
}
