import useSWR from "swr";
import { fetcherV2 } from "utils/lib/fetcher";
import { getAllGroupsUrl } from "../groups.service";
import { useState } from "react";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import TwoUsersIcon from "assets/icons/LedgaV2 Icons/twoUsersIcon";

export default function useGroups() {
   const [groupNames, setGroupNames] = useState([]);

   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const {
      data: groups,
      error: getAllGroupsError,
      mutate: mutateGroups,
   } = useSWR(getAllGroupsUrl, fetcherV2, {
      onSuccess: (data) => {
         const groupNames = data.groupCircles.map((group) => {
            return {
               name: `${group.title} : ${group.unique_id}`,
               value: `${group._id}`,
            };
         });

         setGroupNames([
            {
               name: "Create New Default Group",
               value: "Default",
            },
            ...groupNames,
         ]);
      },
      onError: () => {
         showModal(MODAL_TYPES.ERROR, {
            modalPosition: "CENTER",
            hideOnBlur: false,
            btnOnClick: () => window.location.reload(),
         });
      },
   });

   const getAllGroupsLoading = !groups && !getAllGroupsError;

   return {
      groups: groups?.groupCircles,
      getAllGroupsLoading,
      getAllGroupsError,
      groupsData: groups?.groupCircles?.map((d) => ({
         id: d._id,
         ProfileIcon: TwoUsersIcon,
         name: d?.title,
         uniqueId: d?.unique_id,
      })),
      groupNames,
      mutateGroups,
   };
}
