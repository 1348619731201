import jsPDF from "jspdf";
import "jspdf-autotable";
import "assets/fonts/Roboto-Regular-normal";
import "assets/fonts/FuzzyBubbles-normal";
import "assets/fonts/Montserrat-normal";
import "assets/fonts/Montserrat-Regular-normal";

export default function getUploadsPDF(usersType, tableData) {
   const doc = new jsPDF("p", "mm", [210, 297]);

   doc.setFont("Montserrat-Regular");

   const pX = 3;
   const pY = 4;
   const head = [["unique id", "name", "class", "password"]];
   const body = tableData;

   doc.autoTable({
      head: head,
      body: body,
      theme: "grid",

      headStyles: {
         font: "Montserrat-Regular",
         fontSize: 11,
         valign: "middle",
         textColor: "#64748b",
         fillColor: "#fff",
         cellPadding: { top: 6, right: 3, bottom: 3, left: 3 },
      },

      bodyStyles: {
         font: "Montserrat-Regular",
         fontSize: 13,
         textColor: "#64748b",
         fillColor: "#fff",
         cellPadding: { top: pY, right: pX, bottom: pY, left: pX },
         lineWidth: { top: 0, right: 0, bottom: 0.1, left: 0 },
      },
   });

   // return doc;
   doc.save(`uploaded-${usersType}.pdf`);
}
