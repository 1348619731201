import getMonthName from "utils/lib/getMonthName";
import { getFormattedDate } from "utils/lib/number-formatter";
import { getFormattedAmount } from "utils/lib/number-formatter";

export const getTableData = (transactionData) => {
   return transactionData?.flatMap((e, i, arr) => {
      const thisYear = e._id.year;
      const lastYear = arr[i - 1]?._id.year;
      const isNewYear = i === 0 || thisYear !== lastYear;
      const data = e.docs.map((transaction) => [
         getFormattedDate(transaction.created),
         getFormattedAmount(50),
      ]);

      if (isNewYear) {
         return [
            [e._id.year, "year"],
            [getMonthName(e._id.month), "month"],
            ...data,
         ];
      } else {
         return [[getMonthName(e._id.month), "month"], ...data];
      }
   });
};
