import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const subadminSchema = yup.object().shape({
   name: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please enter a valid name")
      .required("name cannot be empty"),
   email: yup.string().email("please enter a valid email").required("email cannot be empty"),
   permissions: yup.mixed().test("is-valid", "please grant at least one permission", (value) => {
      return Object.values(value).filter((i) => i).length > 0;
   }),
});

const subadminUtils = {
   subadminSchema,
};

export default subadminUtils;
