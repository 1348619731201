import TableRow from "features/ui/tables/table-row";
import TableCol from "features/ui/tables/table-col";
import getMonthName from "utils/lib/getMonthName";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { getFormattedDate } from "utils/lib/number-formatter";

export default function EarningsTableData({ year, month, data }) {
   const monthName = getMonthName(month);

   const yearClass = `
      text-2xl 
      font-semibold 
      text-secondary-darker 
      sm:pl-1 
      md:text-3xl
   `;

   return (
      <section className="mb-10" key={year}>
         <h2 className={yearClass}>{year}</h2>
         <h3 className="inline-block self-center rounded-xl bg-blue-600 px-2 font-semibold text-white sm:rounded-3xl sm:px-3  sm:py-1 sm:text-xl lg:text-2xl">
            {monthName}
         </h3>
         <div className="mb-3">
            {data.map((i) => (
               <div key={i._id}>
                  <TableRow gridTemplateCols="grid-cols-4" key={i.transID}>
                     <TableCol className="justify-start text-secondary-darker font-medium col-span-2">
                        {getFormattedDate(i.created)}
                     </TableCol>
                     <TableCol className="justify-center text-secondary-darker font-medium">
                        <img
                           src="/icons/la_coins.svg"
                           alt="coins icon"
                           className="w-8"
                        />
                     </TableCol>
                     <TableCol className="justify-end font-medium text-green-500">
                        {getFormattedAmount(50)}
                     </TableCol>
                  </TableRow>
               </div>
            ))}
         </div>
      </section>
   );
}
