import { useEffect, useState } from "react";
import QRCode from "../../../src/assets/icons/LedgaV2 Icons/QR code Black.svg";

export default function SettingsFormColored({ userData }) {
   const [schoolName, setSchoolName] = useState("");
   const [address, setAddress] = useState("");
   const [profileImage, setProfileImage] = useState("");
   const [userId, setUserId] = useState("");

   useEffect(() => {
      if (userData) {
         const {
            organization_details: { name, street },
            profile_image,
            unique_id,
         } = userData;
         setSchoolName(name);
         setAddress(street);
         setProfileImage(profile_image);
         setUserId(unique_id);
      }
   }, [userData, schoolName, address]);

   // const handleSubmit = (e) => {
   //    e.preventDefault();
   // };

   return (
      <div className="mx-auto w-5/6 max-w-screen-sm mb-[20px] shadow shadow-faintShadow bg-white rounded-[10px] py-[30px] px-[20px]">
         <div className="flex flex-row gap-2 items-center justify-between">
            <div className="flex gap-[10px]">
               <img
                  src={profileImage}
                  alt="user profile"
                  className="rounded-[50%] shadow-faintShadow-one shadow-md w-[40px] h-[40px] border border- border-white"
               />
               <div>
                  <p className="font-bold">{schoolName}</p>
                  <p className="text-gray-400">{userId}</p>
               </div>
            </div>

            <img alt="qr code" src={QRCode} />
         </div>
      </div>
   );
}
