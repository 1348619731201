import { useSWRConfig } from "swr";
import { BsQuestionCircleFill } from "react-icons/bs";

import ReceiptField from "./receipt-fieldN";
import * as NumberFormatter from "utils/lib/number-formatter";
import UserDetailsFields from "./user-details-fields";
import { Link } from "react-router-dom";

export default function ReceiptDownload(props) {
   const {
      created, //
      amount,
      type,
      reference,
      sender,
      recipient,
      status,
   } = props;

   /*destructure from props  rather than directly so props can be passed whole into getReceipt function */
   const { cache } = useSWRConfig();
   const member = cache.get("member/account/info");
   const organization = member?.admin?.organization_details?.name;

   /*
    *student is gotten from cache because the global modal which
    *is responsible for rendering the receipt in most cases wraps
    *auth-context and as a result does not have access to useStudent()
    *exported from authContext.
    */

   const formattedDate = NumberFormatter.getFormattedDate(created);
   const formattedTime = NumberFormatter.getFormattedTime(created);
   const formattedAmount = NumberFormatter.getIsoCurrencyFormat(amount);

   return (
      <section
         id="printing"
         className="mx-auto flex w-[500px] items-center  justify-center border "
      >
         <div className="mx-auto w-[500px]">
            <h2 className="poppins-semibold mb-12  text-[24px] text-black-one">
               Transaction Details
            </h2>

            <div className="poppins-semibold mb-[20px] flex w-full flex-col items-center overflow-scroll rounded-[5px] bg-white shadow-lg shadow-[#F5F5F5]">
               <div
                  className={`flex h-[50px] w-[50px] items-center justify-center rounded-full ${
                     member?.unique_id === sender.uniqueId ? "bg-[#EDC3C4]" : "bg-[#B5DEBE]"
                  } `}
               >
                  {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                   >
                      <path
                         strokeLinecap="round"
                         strokeLinejoin="round"
                         d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                   </svg> */}

                  {/* <MdCallMade
                      className={`${
                         member?.unique_id === sender.uniqueId
                            ? " text-red-600"
                            : "rotate-180 text-green-400"
                      }`}
                   /> */}
               </div>
               <p className="poppins-regular text-[16px] text-[#474747]">
                  {member?.unique_id === sender.uniqueId ? "Debit" : "Credit"}
               </p>
               <p className="poppins-semibold text-[24px] text-[#121212]">{formattedAmount}</p>
            </div>

            <div className="flex w-full flex-col rounded-[5px] bg-white px-[20px] py-[20px] shadow-lg shadow-[#F5F5F5]">
               <div>
                  <p className="poppins-semibold text-black mb-[16px] text-[16px] text-black-two">
                     Payment Details
                  </p>
               </div>
               <ReceiptField label="organization" value={organization} />
               <UserDetailsFields
                  name="Sender"
                  data={sender}
                  className=" my-[5px] flex flex-col justify-between"
               />
               <UserDetailsFields
                  name="Recipent"
                  data={recipient}
                  className="my-[5px] flex flex-col justify-between"
               />
               <ReceiptField label="transaction type" value={type} />
               <ReceiptField label="Payment time" value={`${formattedTime} ${formattedDate}`} />
               <ReceiptField label="Total Payment" value={formattedAmount} />
               <ReceiptField label="transaction status" value={status} />
               <ReceiptField label="reference" value={reference} />
            </div>

            <Link
               to="dashboard/settings/contact-us"
               className="bg-[FCFDFF] mt-[20px] flex  flex-row items-center justify-between gap-2 rounded-md px-[20px] py-[20px] text-black-one shadow-lg shadow-[#F5F5F5]"
            >
               <BsQuestionCircleFill size={30} className="text-black-three" />
               <div>
                  <p className="poppins-medium text-2xl text-[14px]">Trouble With Your Payment?</p>
                  <p className="poppins-regular text-[12px]">
                     Let us know know on help center now!
                  </p>
               </div>
               {/* <FaAngleRight size={30} color="#3B16A7" /> */}
            </Link>
         </div>
      </section>
   );
}
