import { Link } from "react-router-dom";
import { ReactComponent as ArrowForward } from "../../../src/assets/icons/LedgaV2 Icons/Arrow Complete.svg";

export default function LinkItemColored({
   className,
   icon,
   label,
   to,
   btnOnclick,
   external = false,
}) {
   return (
      <div
         className={`${className} py-3 flex-row justify-between flex items-center border-none text-base capitalize text-black sm:text-xl  lg:text-[22px] lg:font-normal`}
      >
         {!external ? (
            <div className="flex gap-2 flex-row justify-center items-center">
               {icon}
               {to ? <Link to={to}>{label}</Link> : <button onClick={btnOnclick}>{label}</button>}
            </div>
         ) : (
            <div className="flex gap-2 flex-row justify-center items-center">
               {icon}
               <a href={to} rel="noreferrer" target="_blank">
                  {label}
               </a>
            </div>
         )}

         <ArrowForward className="text-white" />
      </div>
   );
}
