import useSWR from "swr";
import { getBillTransactions, getSingleBillUrl } from "features/bills/bills.service";
import { formatDataByDay } from "utils/lib/getSortedData";
import { getTransformedTransaction } from "utils/lib/getTransformedTransactions";

export default function useSingleBill(id, config) {
   const {
      data: bill,
      error: billError,
      mutate: mutateBill,
   } = useSWR(getSingleBillUrl(id), config);

   const billLoading = !bill && !billError;
   const billInfo = bill?.bill;



   const {
      data: billTransactionsData,
      error: billTransactionsError,
      mutate: mutateBillTransactions,
   } = useSWR(getBillTransactions(id), config);


   
   const billFormattedTransactions =
      billTransactionsData && getTransformedTransaction(billTransactionsData.transactions.transactions, id);

   const billTransactions = billTransactionsData && formatDataByDay(billFormattedTransactions);

   const billTransactionsLoading = !billTransactionsData && !billTransactionsError;
   return {
      billInfo,
      mutateBill,
      billError,
      billLoading,
      billTransactions,
      billTransactionsLoading,
      billTransactionsError,
      mutateBillTransactions,
      billTableTransactions: billTransactions,
   };
}
