import { useParams } from "react-router-dom";
import Header from "features/ui/layout/headers/dashboard-header";

import MemberEditorForm from "features/add-users/components/member-editor-form";
import useUpdateMember from "features/add-users/api/member-update.api";

export default function MemberEdit() {
   const { id } = useParams();
   const { handleMemberUpdate } = useUpdateMember(id);

   return (
      <section className="wrapper bg-white">
         <Header title="Edit Member" variant="ACCOUNTS" />
         <div className="w-full min-h-screen mx-auto bg-[#F3F3F3] rounded-t-xl">
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px] pt-20">
               <MemberEditorForm
                  editingId={id}
                  handleSubmit={handleMemberUpdate}
                  editingMode="edit"
               />
            </div>
         </div>
      </section>
   );
}
