import * as REGEX from "utils/constants/regex";
import createNameAndEmailArray from "utils/lib/createMultiInput";

/*
 *@DESC adds to or remove from end of field array depending on
 *if the rows provided is less or greater than the current field arry
 */
export const handleAppend = (rows, fields, append, remove) => {
   let diff = rows - fields.length;
   if (diff > 0) {
      const newFields = createNameAndEmailArray(diff);
      append(newFields);
   } else {
      diff = Math.abs(diff);
      // create an array of numbers with the amount of input to be removed (diff)
      const arrayOfN = Array.from(Array(diff));

      // add rows to each item in the array to create an array of indices after
      // the entered rows value.
      const toRemoveIndices = arrayOfN.map((_, i) => i + rows);
      remove(toRemoveIndices);
   }
};

/*
 *@DESC takes field Array values after submit and clean up
 *fields that doesn't have the required value. The fieldArray comes
 *submitted with at least 3 fields and only two at most is filled.
 *name is always a required field an the others can be optional
 */
export const fieldArrayValidator = (
   fieldArray,
   additionalRequiredField = ""
) => {
   const filtered = fieldArray.reduce((result, field) => {
      const nameIsValid = REGEX.NAME_VALIDATION.test(field.name);
      const emailIsValid = REGEX.EMAIL_VALIDATION.test(field.email);
      const schoolIdIsValid = REGEX.ALPHANUMERIC_NAME_VALIDATION.test(
         field.schoolId
      );

      // remove undefined field.
      const fieldWithoutUndefinedValues = Object.entries(field).reduce(
         (record, [key, value]) => {
            if (value) {
               record[key] = value;
            }
            return record;
         },
         {}
      );

      // prettier-ignore
      const requiredCondition = additionalRequiredField === "email" 
         ? emailIsValid && nameIsValid : additionalRequiredField = "id" 
         ? schoolIdIsValid && nameIsValid :
         nameIsValid

      /*
       *return the cleaned-up field only if it passes the condition.
       *this is sort of a double-check because already hookform validation shouldn't
       *allow a field that doesn't contain all required valid values to be submitted
       *in the first place.
       */
      return requiredCondition
         ? [...result, fieldWithoutUndefinedValues]
         : [...result];
   }, []);
   return filtered;
};

export const getTableData = (data) => {
   return data.map((i) => [i.uniqueId, i.name, i.class, i.temporaryPassword]);
};
