import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { signupWithEmailAndPassword } from "features/auth/auth.service";

export default function useSignup() {
   const { queryFn: signupFn, loading: signupLoading } = useRequest(
      signupWithEmailAndPassword
   );

   const navigate = useNavigate();
   const [error, setError] = useState({});
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const handleNext = (userId) => {
      hideModal();
      navigate(`../email-verification/${userId}`);
   };

   const resetError = () => {
      setError({});
   };

   const showSuccessModal = (userId) => {
      showModal(MODAL_TYPES.SUCCESS, {
         title: "registration complete",
         message: "check your email for verification code",
         btnText: "verify account",
         modalPosition: "CENTER",
         bg: "WHITE",
         size: "THIN",
         showCloseBtn: false,
         hideOnBlur: false,
         icon: <IoCheckmarkCircleOutline />,
         btnOnClick: () => handleNext(userId),
      });
   };

   const signup = ({ email, password }) => {
      const queryObj = { email, password };
      signupFn(queryObj, {
         onSuccess: ({ id: userId }) => {
            showSuccessModal(userId);
         },
         onError: (message) => {
            if (message.includes("already exist")) {
               setError({
                  message: "Account already exists. Please",
                  link: "../signin",
                  linkText: "sign in",
               });
            } else {
               setError({
                  message: "Something went wrong, please try again",
               });
            }
         },
      });
   };

   return { signup, signupLoading, signupError: error, resetError };
}
