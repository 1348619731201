import { useState } from "react";

import NavItem from "./nav-item";
import { signOut } from "features/auth/auth.service";
import CustomHomeIcon from "assets/custom-icons/home-icon";
import CustomBillIcon from "assets/custom-icons/bill-icon";
import CustomHistoryIcon from "assets/custom-icons/history";
import CustomSettingsIcon from "assets/custom-icons/settings";
import CustomLogOutIcon from "assets/custom-icons/logout";

export default function Sidebar() {
   const navItemClassName = "h-[35px] md:h-[22px] text-green-500";
   // const { adminId } = useAdmin();
   const [activeLabel, setActiveLabel] = useState("");
   const handleClick = (label) => setActiveLabel(label);

   return (
      <aside className="fixed z-10 flex justify-between md:block w-full md:top-0 bottom-0 md:h-screen  md:p-6 bg-white transition dark:bg-[white] px-2 md:px-3 dark:border-none md:w-[100px] lg:w-[200px]">
         {/* user icon top */}
         {/* <div className="hidden md:block text-center text-sm text-gray-600 mb-10 mt-5 dark:text-gray-600">
            <BiUserCircle fontSize={30} className="mx-auto" />
            <span>ID: {adminId}</span>
         </div> */}

         {/* main dashboard nav links */}
         <div className="flex md:flex-col flex-row md:h-full justify-between w-[100%] md:justify-center">
            <NavItem
               label="Overview"
               to="/dashboard"
               isActive={activeLabel === "Home"}
               onClick={() => handleClick("Home")}
               icon={<CustomHomeIcon className={"w-[25px]"} color="#7E7E7E" />}
               activeIcon={<CustomHomeIcon className={"w-[25px]"} color={"#FFFFFF"} />}
               smallIcon={<CustomHomeIcon className={"w-[25px]"} />}
            />

            <NavItem
               label="Accounts"
               isActive={activeLabel === "Accounts"}
               to="bill-accounts"
               icon={<CustomBillIcon className={"w-[25px]"} color="#7E7E7E" />}
               activeIcon={<CustomBillIcon className={"w-[25px]"} color={"#FFFFFF"} />}
               smallIcon={<CustomBillIcon className={"w-[25px]"} />}
               onClick={() => handleClick("Accounts")}
            />

            <NavItem
               label="History"
               to="/dashboard/transactions"
               isActive={activeLabel === "Transactions"}
               icon={<CustomHistoryIcon className={"w-[25px]"} color="#7E7E7E" />}
               activeIcon={<CustomHistoryIcon className={"w-[25px]"} color={"#FFFFFF"} />}
               smallIcon={<CustomHistoryIcon className={"w-[25px]"} />}
               onClick={() => handleClick("Transactions")}
            />

            <NavItem
               label="Settings"
               to="/dashboard/settings"
               isActive={activeLabel === "Settings"}
               icon={<CustomSettingsIcon className={"w-[25px]"} color="#7E7E7E" />}
               activeIcon={<CustomSettingsIcon className={"w-[25px]"} color={"#FFFFFF"} />}
               smallIcon={<CustomSettingsIcon className={"w-[25px]"} />}
               onClick={() => handleClick("Settings")}
            />
         </div>

         {/* logout button bottom */}
         <div className="bottom-0 absolute w-full font-semibold left-0 right-0 hidden md:flex md:justify-center">
            <NavItem
               to="#"
               onClick={signOut}
               label="Logout"
               icon={
                  <CustomLogOutIcon className={"mx-auto w-[40px] md:w-[20px]"} color={"#7E7E7E"} />
               }
            />
         </div>
      </aside>
   );
}
