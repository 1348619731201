import Header from "features/ui/layout/headers/dashboard-header";
import WithdrawForm from "features/withdraw/withdraw-form";

export default function Withdraw() {
   return (
      <section className="wrapper bg-white flex flex-col px-[20px] md:px-[50px] min-h-screen">
         <Header variant="OTHERS" title="Transfer" settingsUrl="../settings" to={"../settings"} />
         <div className="w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#EDF5FC] rounded-t-xl flex flex-col items-center pb-20">
            <WithdrawForm />
         </div>
      </section>
   );
}
