import useUsers from "features/add-users/api/get-users.api";
import { getMembersUrl } from "features/add-users/users.service";

export default function useMembers(search, page, pageSize) {
   const {
      usersData: membersData,
      usersDataError: membersError,
      usersDataIsLoading: membersIsLoading,
      mutateUsers: mutateMembers,
      totalCount,
      totalPages,
   } = useUsers(getMembersUrl(search, page, pageSize), "V2");

   return {
      membersData,
      membersError,
      membersIsLoading,
      mutateMembers,
      totalCount,
      totalPages,
   };
}
