import AuthLayout from "features/auth/components/layout";
import SigninForm from "features/auth/components/signin-form";
import useSignin from "features/auth/api/signin";
import ResetPasswordModal from "features/reset-password/reset-password-modal.component";

export default function Signin() {
   const {
      signin,
      signinLoading,
      signinError,
      resetError,
      resetPasswordIsOpen,
      closeResetPasswordModal,
      showResetPasswordModal,
   } = useSignin();

   return (
      <AuthLayout>
         <SigninForm
            signin={signin}
            signinLoading={signinLoading}
            signinError={signinError}
            resetError={resetError}
            showResetPasswordModal={showResetPasswordModal}
         />
         <ResetPasswordModal
            isOpen={resetPasswordIsOpen}
            modalPosition="CENTER"
            handleClose={closeResetPasswordModal}
         />
      </AuthLayout>
   );
}
