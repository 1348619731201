import Numpad from "features/numpad/numpad.component";
import CardContainer from "features/ui/cards/card-container";
import useChangePin from "features/pin/change-pin.api";
import RequestPinOtpModal from "features/pin/request-reset-otp-modal.component";

export default function ChangePin() {
   const {
      step,
      pin,
      pinConfirmation,
      setPin,
      setPinConfirmation,
      previousPin,
      setPreviousPin,
      handleSubmit,
      handleNext,
      handlePrevious,
      showRequestPinOtp,
      setShowRequestPinOtp,
   } = useChangePin();

   switch (step) {
      case 2: {
         return (
            <CardContainer>
               <Numpad
                  bg="BLUE"
                  pin={pinConfirmation}
                  setPin={setPinConfirmation}
                  title="Confirm pin"
                  btnText="create pin"
                  btnOnClick={handleSubmit}
                  handleBack={handlePrevious}
                  message="Confirm new pin"
               />
            </CardContainer>
         );
      }
      case 1: {
         return (
            <CardContainer>
               <Numpad
                  hideOnBlur={false}
                  pin={pin}
                  setPin={setPin}
                  bg={"GREY"}
                  title="New pin"
                  btnText="Next"
                  btnOnClick={handleNext}
                  handleBack={handlePrevious}
                  message={`Enter new pin`}
               />
            </CardContainer>
         );
      }
      default: {
         return (
            <>
               <CardContainer>
                  <Numpad
                     bg="GREY"
                     pin={previousPin}
                     setPin={setPreviousPin}
                     title="Change Pin"
                     btnText="Next"
                     btnOnClick={handleNext}
                     message={`Enter Current Pin`}
                     shouldAutoFocus={!showRequestPinOtp}
                  >
                     <p className="text-center">
                        Forgot Pin?{" "}
                        <button
                           type="button"
                           onClick={() => setShowRequestPinOtp(true)}
                           className="text-green-500"
                        >
                           reset
                        </button>
                     </p>
                  </Numpad>
               </CardContainer>
               <RequestPinOtpModal
                  isOpen={showRequestPinOtp}
                  onClose={() => setShowRequestPinOtp(false)}
               />
            </>
         );
      }
   }
}
