import { useState } from "react";
import { useFormData } from "utils/hooks/useFormData";
import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { transferToUser } from "../users.service";

export default function useTransfer({
   receiver_id,
   receiver_type,
   mutationFn,
   onClose,
   modalPosition = "CENTER",
}) {
   //form states
   const initialState = { amount: "", formattedAmount: "" };
   const [state, dispatch] = useFormData(initialState);
   const [pin, setPin] = useState("");
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   //modal states
   const [numpadModal, setNumpadModal] = useState(false);
   const [amountModal, setAmountModal] = useState(true);

   const toggleNumpadModal = () => setNumpadModal((prev) => !prev);
   const toggleAmountModal = () => setAmountModal((prev) => !prev);

   //queries
   const { queryFn: transferFn, loading: transferLoading } = useRequest(transferToUser);
   const handleClose = () => {
      onClose && onClose();
      hideModal();
   };

   const handleNext = () => {
      setAmountModal(false);
      setNumpadModal(true);
   };

   const handleSubmit = () => {
      setNumpadModal(false);
      showModal(MODAL_TYPES.LOADING, { modalPosition });

      const queryObj = {
         amount: state.amount,
         transaction_pin: pin,
         receiver_type,
         receiver_id,
         purpose: `${receiver_type} transfer`,
      };

      transferFn(queryObj, {
         onSuccess: () => {
            mutationFn && mutationFn();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Transfer Successful",
               message: "",
               hideOnBlur: false,
               btnText: "OK",
               modalPosition,
               onClose: handleClose,
               btnOnClick: handleClose,
            });
         },
         onError: (message) => {
            if (message.includes("pin you entered is incorrect!")) {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition,
                  message: `the pin you entered is incorrect \nPlease check your transaction pin and try again`,
               });
            } else if (message.includes("do not have sufficient balance")) {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition,
                  message: `you do not have sufficient balance to process this request`,
               });
            } else {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition,
                  message: `transfer request could not be completed\nplease check your network settings and try again`,
               });
            }
         },
      });
      setPin("");
      dispatch({ type: "CLEAN_SLATE", payload: initialState });
   };

   return {
      pin,
      setPin,
      state,
      dispatch,
      transferLoading,
      numpadModal,
      amountModal,
      toggleAmountModal,
      toggleNumpadModal,
      handleNext,
      handleSubmit,
   };
}
