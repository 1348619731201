export default function TwoUsersIcon({ className }) {
   return (
      <svg
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         className={className}
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.73137 4.36572C7.80046 4.36572 6.23535 5.93016 6.23535 7.86049C6.23535 9.79082 7.80046 11.3553 9.73137 11.3553C11.6609 11.3553 13.2261 9.79097 13.2261 7.86049C13.2261 5.93001 11.6609 4.36572 9.73137 4.36572ZM4.73535 7.86049C4.73535 5.10115 6.97261 2.86572 9.73137 2.86572C12.489 2.86572 14.7261 5.1013 14.7261 7.86049C14.7261 10.6197 12.489 12.8553 9.73137 12.8553C6.97261 12.8553 4.73535 10.6198 4.73535 7.86049Z"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3096 5.91602C15.5772 5.91602 14.9194 6.23084 14.4624 6.7348L13.9585 7.29038L12.8474 6.28275L13.3512 5.72717C14.0806 4.92277 15.1365 4.41602 16.3096 4.41602C18.5132 4.41602 20.3009 6.20243 20.3009 8.40729C20.3009 10.6121 18.5132 12.3986 16.3096 12.3986C14.9021 12.3986 13.6651 11.6698 12.9552 10.5723L12.5479 9.94256L13.8074 9.12791L14.2147 9.75767C14.6598 10.4458 15.432 10.8986 16.3096 10.8986C17.6851 10.8986 18.8009 9.78343 18.8009 8.40729C18.8009 7.03115 17.6851 5.91602 16.3096 5.91602Z"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.92338 19.3866C5.62584 20.2747 7.60644 20.6389 9.76227 20.6336H9.76593C11.9217 20.6389 13.9023 20.2747 15.6047 19.3866C14.6212 17.0681 12.4438 15.9501 9.76602 15.957H9.76218C7.081 15.9501 4.90707 17.0654 3.92338 19.3866ZM9.7641 14.457C6.3937 14.4489 3.38617 16.0175 2.28492 19.4999L2.10767 20.0604L2.60963 20.3664C4.73279 21.6607 7.19984 22.1397 9.7641 22.1336C12.3283 22.1397 14.7954 21.6607 16.9185 20.3664L17.4205 20.0604L17.2433 19.4999C16.1432 16.0209 13.1312 14.449 9.7641 14.457Z"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3335 14.766C14.9687 14.7625 13.8072 15.1535 12.9905 15.9051L12.4387 16.413L11.4229 15.3093L11.9748 14.8014C13.1338 13.7347 14.6924 13.2622 16.3354 13.266C18.9632 13.2599 21.3465 14.4923 22.2155 17.2406L22.3928 17.8011L21.8908 18.1071C20.2329 19.1178 18.3134 19.4873 16.3336 19.4824L15.5836 19.4806L15.5873 17.9806L16.3373 17.9824C17.9009 17.9863 19.3304 17.7324 20.5645 17.1257C19.8179 15.5367 18.2679 14.761 16.3374 14.766H16.3335Z"
         />
      </svg>
   );
}
