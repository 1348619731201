import { useParams } from "react-router-dom";

import Header from "features/ui/layout/headers/dashboard-header";
import GroupEditorForm from "features/groups/components/group-editor-form";
import useUpdateGroup from "features/groups/api/group-edit.api";

export default function GroupEdit() {
   const { id: groupId } = useParams();
   const { handleSubmit } = useUpdateGroup(groupId);

   return (
      <section className="wrapper bg-white">
         <Header title="" variant="ACCOUNTS" />
         <div className="w-full min-h-screen mx-auto bg-[#F3F3F3] rounded-t-xl">
            <h2 className="pt-20 self-stretch w-full mx-auto max-w-screen-md text-black-one  text-[24px] md:text-[40px] md:pl-0 lg:text-4xl mt-14">
               Edit Group
            </h2>
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px] pt-20">
               <GroupEditorForm
                  handleSubmit={handleSubmit}
                  editingMode="edit"
                  editingId={groupId}
               />
            </div>
         </div>
      </section>
   );
}
