import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { updateGroup } from "../groups.service";
import useSingleGroup from "./group-single.api";

export default function useUpdateGroup(groupId) {
   const navigate = useNavigate();
   const { mutategroup } = useSingleGroup(groupId);
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: createGroupFn } = useRequest(updateGroup, groupId);

   const handleSubmit = (queryObj, resetForm) => {
      showModal(MODAL_TYPES.LOADING, {
         message: "Wait a moment while we update the group",
         modalPosition: "CENTER",
      });

      createGroupFn(queryObj, {
         onSuccess: () => {
            resetForm();
            mutategroup();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Success",
               message: "Group has been Updated Successfully.",
               btnText: "OK",
               modalPosition: "CENTER",
               bg: "WHITE",
               size: "THIN",
               showCloseBtn: false,
               hideOnBlur: false,
               icon: <IoCheckmarkCircleOutline />,
               btnOnClick: () => {
                  hideModal();
                  navigate(`/dashboard/groups/${groupId}`);
               },
            });
         },
         onError: () => {
            showModal(MODAL_TYPES.ERROR, {
               modalPosition: "CENTER",
            });
         },
      });
   };

   return {
      handleSubmit,
   };
}
