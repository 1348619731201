import { Link } from "react-router-dom";
import Button from "../ui/forms/button";

export default function WelcomeSlideParentWrapper({
   url,
   title,
   text,
   secondaryText,
   isFinalSlide,
   screen,
   setScreen,
}) {
   return (
      <section className="max-w-md md:max-w-sm mx-auto px-6 py-7 flex flex-col min-h-screen justify-center">
         <div className="flex justify-between items-center">
            <img
               onClick={() => setScreen((prev) => prev - 1)}
               src="/icons/ic_baseline-arrow-back 2.png"
               className="w-12 md:w-8 cursor-pointer"
               alt=""
            />

            <Link to="../setup">Skip</Link>
         </div>

         <img src={url} alt="" className="w-full max-w-[230px] mt-16 md:mt-0 mb-1 mx-auto" />

         <article className="text-center">
            {!isFinalSlide && <h1 className="text-blue-700 font-bold text-xl mb-1">{title}</h1>}

            <p className="text-stone-700 text-sm">{text}</p>

            <p className="text-blue-600 font-bold mt-2">{secondaryText}</p>
         </article>

         <ul className="flex justify-center mt-7">
            <li
               className={`h-3 ${
                  screen === 1 ? "w-8 bg-blue-600" : "w-3 bg-blue-300"
               } rounded-full mr-1`}
            ></li>

            <li
               className={`h-3 ${
                  screen === 2 ? "w-8 bg-blue-600" : "w-3 bg-blue-300"
               } rounded-full mr-1`}
            ></li>

            <li
               className={`h-3 ${
                  screen === 3 ? "w-8 bg-blue-600" : "w-3 bg-blue-300"
               } rounded-full mr-1`}
            ></li>

            <li
               className={`h-3 ${
                  screen === 4 ? "w-8 bg-blue-600" : "w-3 bg-blue-300"
               } rounded-full mr-1`}
            ></li>
         </ul>

         <figure className="flex justify-center my-10 md:my-5">
            {isFinalSlide && (
               <Button to="../setup">
                  <span className="px-8">Setup account</span>
               </Button>
            )}

            {!isFinalSlide && (
               <img
                  onClick={() => setScreen((prev) => prev + 1)}
                  src="/icons/ic_baseline-arrow-back.png"
                  className="w-12 md:w-8 cursor-pointer hover:animate-pulse"
                  alt=""
               />
            )}
         </figure>
      </section>
   );
}
