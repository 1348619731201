import { getFormattedAmount } from "utils/lib/number-formatter";

export function Card({ className, amount, title }) {
   return (
      <div
         className={`${className}  p-3 md:flex md:flex-col md:justify-center md:items-center md:shadow-bottom md:border-[1.5px] md:border-blue-600 md:rounded-xl md:py-8  md:w-[32%]`}
      >
         <p className="font-semibold text-3xl md:font-normal lg:text-4xl">
            {amount}
         </p>
         <p className="text-xs md:text-center md:text-sm lg:text-base">
            {title}
         </p>
      </div>
   );
}

export default function EarningsCard({
   todayEarnings = 0,
   thisWeekEarnings = 0,
   lifeTimeEarnings = 0,
}) {
   return (
      <div className="flex justify-center md:justify-between md:border-blue-600">
         <Card
            amount={getFormattedAmount(todayEarnings)}
            title="today's earnings"
            className="border-l-2"
         />
         <Card
            amount={getFormattedAmount(thisWeekEarnings)}
            title="this week's earnings"
            className="hidden md:block"
         />
         <Card
            amount={getFormattedAmount(lifeTimeEarnings)}
            title="lifetime earnings"
            className="hidden md:block "
         />
      </div>
   );
}
