import { useWatch } from "react-hook-form";

import FormGroup from "features/ui/forms/form-group";

import useGroups from "features/groups/api/groups.api";
import NamesSelector from "./names-selector";
import { Controller } from "react-hook-form";
import Checkbox from "features/ui/forms/check-box";

export default function GroupsSelector({ methods }) {
   const { groupNames } = useGroups();
   const attachToGroups = useWatch({
      control: methods.control,
      name: "attachToGroups",
   });

   return (
      <section>
         <h2 className="text-xl text-black-three">Bill Groups</h2>
         <p className="text-gray-400">Attach bill to be paid by only select groups?</p>
         <div className="bg-white p-4 mb-6 rounded-lg flex flex-col items-center">
            <FormGroup className="relative mb-6 space-y-4 w-full">
               <Controller
                  control={methods.control}
                  name={`attachToGroups`}
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Attach Bill To Groups"
                        handleChange={(v) => {
                           if (v.target.value) {
                              methods.setValue("groups", []);
                           }
                           onChange(v);
                        }}
                     />
                  )}
               />
            </FormGroup>
            {attachToGroups ? (
               <FormGroup className="w-full">
                  <h3 className="text-lg">Select Applicable Groups</h3>
                  <NamesSelector methods={methods} fieldName="groups" nameList={groupNames} />
               </FormGroup>
            ) : null}
         </div>
      </section>
   );
}
