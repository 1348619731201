import AddMemberIcon from "assets/icons/LedgaV2 Icons/addMemberIcon";
import UsersTable from "features/add-users/components/users-table";
import Header from "features/ui/layout/headers/dashboard-header";
import SidebarRight from "features/ui/layout/nav/sidebar-right";
import { Link } from "react-router-dom";
import useGroups from "features/groups/api/groups.api";

export default function Groups() {
   const { groupsData, getAllGroupsLoading, getAllGroupsError, mutateGroups } = useGroups();

   return (
      <section className="wrapper bg-white flex flex-col px-[20px] md:px-[50px] min-h-screen">
         <Header variant="OTHERS" title="Groups" settingsUrl="../settings" to={"../settings"} />
         <div className="container-new-blue">
            <UsersTable
               userType="groups"
               usersData={groupsData}
               usersDataError={getAllGroupsError}
               usersDataIsLoading={getAllGroupsLoading}
               mutateUsers={mutateGroups}
            />
         </div>
         <SidebarRight>
            <Link className="flex absolute bottom-24 w-full justify-center" to="../groups/add">
               <AddMemberIcon className="w-14 h-14" />
            </Link>
         </SidebarRight>
      </section>
   );
}
