export function classNames(...classes){
   return classes.some(i=> i === undefined) 
   ? 'block bg-black text-red-600 w-24 h-24'
   : classes.filter(Boolean).join(" ");
}


//the function takes in string class names and check if any 
//of the values is undefined it returns a warning black box with red text 
//so if the values to be used as arguments for this function are passed as props, 
//they must be assigned a default value when they are being destructured