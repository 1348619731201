import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { useFormData } from "utils/hooks/useFormData";
import { setSpendingLimit } from "./spending-limit.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useUpdateSpendingLimit() {
   const initialFormData = {
      amount: "",
      formattedAmount: "",
      schedule: "weekly",
   };
   const [state, dispatch] = useFormData(initialFormData);

   const [successModal, setSuccessModal] = useState(false);
   const [limitModal, setLimitModal] = useState(true);
   const [numpadModalIsOpen, setNumpadModalIsOpen] = useState(false);

   const navigate = useNavigate();
   const [pin, setPin] = useState("");
   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const { queryFn: setSpendingLimitFn, loading } =
      useRequest(setSpendingLimit);

   const handleSubmit = () => {
      const { schedule, amount } = state;
      const queryObj = { schedule, amount };
      setNumpadModalIsOpen(false);

      setSpendingLimitFn(queryObj, {
         onSuccess: () =>
            showModal(MODAL_TYPES.SUCCESS, {
               isOpen: successModal,
               toggleModal: () => setSuccessModal((prev) => !prev),
               src: "/icons/arcticons_ok-payment.svg",
               title: "limit set",
               message: "Members will now be able to spend only wthin limit",
               btnText: "home",
               btnOnClick: () => navigate("../../../dashboard"),
            }),
         onError: () => showModal(MODAL_TYPES.ERROR),
      });
   };

   const handleClick = () => {
      setLimitModal(false);
      setNumpadModalIsOpen(true);
   };

   return {
      state,
      dispatch,
      handleClick,
      limitModal,
      pin,
      setPin,
      handleSubmit,
      numpadModalIsOpen,
      setNumpadModalIsOpen,
      navigate,
      loading,
   };
}
