import RequestInfo from "./request-info";
import Button from "features/ui/forms/button";
import useRequestActions from "../api/request-actions";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import PageTitle from "features/ui/layout/headers/page-title";
import { getFormattedDate } from "utils/lib/number-formatter";
import { getFormattedAmount } from "utils/lib/number-formatter";
import NumPadModal from "features/numpad/numpad-modal.component";
import ModalContainer from "features/modal/modal-container.component";

export default function RequestActions({ isOpen, onClose, selectedRequest }) {
   const {
      handleNext,
      handleAcceptPayout,
      acceptPayoutIsLoading,
      handleRejectPayout,
      numpadModalIsOpen,
      numpadModalOnClose,
   } = useRequestActions(selectedRequest._id, onClose);

   const formattedAmount = getFormattedAmount(selectedRequest.amount);
   const formattedDate = selectedRequest.created && getFormattedDate(selectedRequest.created);
   const recipient = selectedRequest?.paid_to;

   return (
      <>
         <ModalContainer isOpen={isOpen} onClose={onClose}>
            <div className="container min-h-screen justify-center md:min-h-0 md:py-16 ">
               <ArrowBack handleBack={onClose} />
               <PageTitle
                  variant="SECONDARY"
                  title="cancel request"
                  message="Permanently Cancel This request? Please note that this action cannot be reversed"
               />
               <div className="mb-12 self-start">
                  <RequestInfo title="name" value={recipient?.accountName} />
                  <RequestInfo title="amount" value={formattedAmount} />
                  <RequestInfo title="account no" value={recipient?.accountNumber} />
                  <RequestInfo title="bank name" value={recipient?.bankName} />
                  <RequestInfo title="date" value={formattedDate} />
                  <RequestInfo title="status" value={selectedRequest.status} />
               </div>
               <div className="mx-auto flex justify-between gap-2 md:gap-3">
                  <Button onClick={handleNext}>
                     <span className="text-xs sm:text-sm">accept payout</span>
                  </Button>
                  <Button color="RED" onClick={handleRejectPayout}>
                     <span className="text-xs sm:text-sm">reject payout</span>
                  </Button>
               </div>
            </div>
         </ModalContainer>
         <NumPadModal
            message="enter your transaction pin to accept"
            title="Accept Payout"
            btnText="confirm"
            isOpen={numpadModalIsOpen}
            onClose={numpadModalOnClose}
            amount={selectedRequest.amount}
            handleSubmit={handleAcceptPayout}
            submitLoading={acceptPayoutIsLoading}
         />
      </>
   );
}
