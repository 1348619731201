export default function CustomEditIcon({ className, color }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.18 77.31" className={className}>
         <defs>
            <style>{`.cls-edit{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-edit"
                  d="M38.66,77.31a38.66,38.66,0,1,1,9.21-76.2A4,4,0,1,1,46,8.88,30.66,30.66,0,1,0,69.31,38.66a4,4,0,0,1,8,0A38.7,38.7,0,0,1,38.66,77.31Z"
               />
               <path
                  className="cls-edit"
                  d="M25.21,54.53a4,4,0,0,1-3.89-4.91l3.32-14.21a4.06,4.06,0,0,1,1.42-2.24L60.84,5.84A16,16,0,0,1,71.13,2.33,12.51,12.51,0,0,1,80.64,7a12.46,12.46,0,0,1,2.25,10.33,15.89,15.89,0,0,1-5.84,9.18L42.93,53.29a4,4,0,0,1-2.37.86l-15.24.38Zm15.25-4.38h0ZM32.11,38.6l-1.82,7.8L39,46.18l33.08-26a8.07,8.07,0,0,0,3-4.53,4.52,4.52,0,0,0-.71-3.74h0a4.56,4.56,0,0,0-3.47-1.58,8.08,8.08,0,0,0-5.1,1.8Z"
               />
            </g>
         </g>
      </svg>
   );
}
