import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import useNavRange from "features/ui/hooks/useNavRange";

export default function Pagination({ total, pageCount }) {
   const [searchParams, setSearchParams] = useSearchParams();
   const page = searchParams.get("page");

   const [navigatePage, setNavigatePage] = useState(page);
   const { mobileRange, desktopRange } = useNavRange({
      total,
      pageCount,
      currentPage: page,
      mobileBtnsNum: 3,
      desktopBtnsNum: 10,
   });

   const handleSubmit = (e) => {
      e.preventDefault();
      handleNavigate(navigatePage);
   };

   const handleNavigate = (newPage) => {
      if (newPage === page) return;
      searchParams.set("page", newPage);
      setSearchParams(searchParams);
   };

   if (!total) return;

   return (
      <div className="mt-2 py-4 flex items-center justify-center gap-16">
         <div className="flex gap-2">
            <PageNavBtn
               isActive={false}
               handleClick={() => handleNavigate(Number(page) - 1)}
               disabled={page <= 1}
            >
               {"<"}
            </PageNavBtn>
            <div className="flex w-full justify-center md:hidden gap-[2px]">
               {mobileRange.map((n) => (
                  <PageNavBtn isActive={page == n} handleClick={() => handleNavigate(n)}>
                     {n}
                  </PageNavBtn>
               ))}
            </div>
            <div className="hidden w-full justify-center md:flex gap-[2px]">
               {desktopRange.map((n) => (
                  <PageNavBtn isActive={page == n} handleClick={() => handleNavigate(n)}>
                     {n}
                  </PageNavBtn>
               ))}
            </div>
            <PageNavBtn
               isActive={false}
               handleClick={() => handleNavigate(Number(page) + 1)}
               disabled={page >= pageCount}
            >
               {">"}
            </PageNavBtn>
         </div>
         <form
            onSubmit={handleSubmit}
            className="text-base place-self-end flex justify-end items-center min-w-max"
         >
            <label className="mx-2">
               <input
                  className={`border border-black-two !w-16 p-1 rounded-lg`}
                  min={1}
                  max={pageCount}
                  type="number"
                  value={navigatePage}
                  onChange={(e) => setNavigatePage(e.target.valueAsNumber)}
               />
            </label>
            of {pageCount}
            <input
               type="submit"
               value="go"
               className="bg-black-two rounded-lg border-none  text-white px-3 ml-2 py-1 cursor-pointer"
            />
         </form>
      </div>
   );
}

const PageNavBtn = ({ children, isActive, disabled = false, handleClick = { handleClick } }) => {
   if (disabled) return;

   return (
      <button
         className={`w-8 h-8 flex justify-center items-center rounded-lg border border-gray-300 hover:bg-black-two hover:text-white ${
            isActive ? "bg-black-two text-white" : ""
         }`}
         onClick={handleClick}
      >
         {children}
      </button>
   );
};
