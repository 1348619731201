import Button from "features/ui/forms/button";
import Spinner from "features/ui/feedbacks/spinner";
import ModalContainer from "features/modal/modal-container.component";

export default function DeleteModal({
   title = "Delete",
   message,
   onClose,
   isOpen,
   modalPosition,
   children,
   hideOnBlur,
   showCloseBtn,
   handleDelete,
   deleteLoading,
}) {
   return (
      <>
         <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            modalPosition={modalPosition}
            hideOnBlur={hideOnBlur}
            showCloseBtn={showCloseBtn}
            size="THIN"
         >
            <div className="text-lg text-center container min-h-screen justify-center md:min-h-0 md:py-20">
               <h2 className="text-2xl font-semibold mb-6">{title}</h2>
               {children}
               {message}
               <p className="mb-6"> This action cannot be undone</p>
               <div className="flex gap-2">
                  <Button onClick={onClose}>cancel</Button>
                  <Button color="RED" onClick={handleDelete}>
                     {deleteLoading ? <Spinner color="WHITE" /> : "delete"}
                  </Button>
               </div>
            </div>
         </ModalContainer>
      </>
   );
}
