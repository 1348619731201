import { axios, axiosClient } from "./axios";

export const fetcher = async (url, queryParams = "", version) => {
   if (version === "V2") {
      return axiosClient.get(`${url}${queryParams}`).then((res) => res.data.data);
   }

   return axios.get(`${url}${queryParams}`).then((res) => res.data.data);
};

export const fetcherV2 = async (url, queryParams = "") => {
   return axiosClient.get(`${url}${queryParams}`).then((res) => res.data.data);
};
