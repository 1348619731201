import { useState } from "react";
import useBills from "./bills.api";

import { deleteBill } from "features/bills/bills.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { useNavigate } from "react-router-dom";

export default function useDeleteBill(bill, setModalDisplay) {
   const navigate = useNavigate();
   const { mutateBills } = useBills();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const [deleteLoading, setDeleteLoading] = useState(false);

   const handleDelete = () => {
      setDeleteLoading(true);
      deleteBill(bill.unique_id)
         .then((data) => {
            const result = data.data;
            if (result.status === "success") {
               mutateBills();
               showModal(MODAL_TYPES.SUCCESS, {
                  title: "bill deleted",
                  message: result.message,
                  btnText: "continue",
                  modalPosition: "CENTER",
                  btnOnClick: () => {
                     hideModal();
                     navigate("/dashboard/bill-accounts");
                  },
               });
               setModalDisplay(false);
            } else {
               onError(result.message);
            }
         })
         .catch(() => onError());
   };

   const onError = (message = "bill deletion could not be completed. Please try again") => {
      setModalDisplay(false);
      showModal(MODAL_TYPES.ERROR, {
         message: message,
      });
   };

   return { handleDelete, deleteLoading };
}
