import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import useSubAdmins from "./subadmins.api";
import { addSubAdmin } from "../subadmins.service";
import { useNavigate } from "react-router-dom";

export default function useAddSubAdmin() {
   const navigate = useNavigate();
   const { mutateSubAdmins } = useSubAdmins();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: addSubAdminFn } = useRequest(addSubAdmin);

   const handleSubmit = (queryObj, resetForm) => {
      showModal(MODAL_TYPES.LOADING, {
         modalPosition: "CENTER",
         message: "Wait a moment while we add the sub admin",
      });

      addSubAdminFn(queryObj, {
         onSuccess: () => {
            resetForm();
            mutateSubAdmins();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Success",
               message:
                  "Sub Admin has been added Successfully. An email will be sent to the sub admin to complete their verification process",
               btnText: "OK",
               modalPosition: "CENTER",
               bg: "WHITE",
               size: "THIN",
               showCloseBtn: false,
               hideOnBlur: false,
               icon: <IoCheckmarkCircleOutline />,
               btnOnClick: () => {
                  hideModal();
                  navigate("/dashboard/subadmins");
               },
            });
         },
         onError: () => {
            showModal(MODAL_TYPES.ERROR);
         },
      });
   };

   return {
      handleSubmit,
   };
}
