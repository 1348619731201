import React from "react";

const CustomBillIcon = ({ className, color = "#1358CC" }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.89 98.89" className={className}>
         <defs>
            <style>{`.cls-bill{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-bill"
                  d="M66,98.89H32.89A32.92,32.92,0,0,1,0,66V32.89A32.92,32.92,0,0,1,32.89,0H66A32.92,32.92,0,0,1,98.89,32.89v8.57a4.54,4.54,0,0,1-9.08,0V32.89A23.83,23.83,0,0,0,66,9.09H32.89a23.82,23.82,0,0,0-23.8,23.8V66a23.82,23.82,0,0,0,23.8,23.8H66A23.83,23.83,0,0,0,89.81,66V56.28a4.54,4.54,0,0,1,9.08,0V66A32.92,32.92,0,0,1,66,98.89Z"
               />
               <rect className="cls-bill" x="21.84" y="32.58" width="53.6" height="7.6" rx="3.8" />
               <rect className="cls-bill" x="21.84" y="49.78" width="53.6" height="7.6" rx="3.8" />
               <rect className="cls-bill" x="21.84" y="66.98" width="27.61" height="7.6" rx="3.8" />
            </g>
         </g>
      </svg>
   );
};

export default CustomBillIcon;
