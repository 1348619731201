import { Link, useParams } from "react-router-dom";
import { BsSend } from "react-icons/bs";

import BillCard from "features/bills/components/bill-card";
import GroupCard from "features/groups/components/group-card";
import EditIcon from "../assets/icons/LedgaV2 Icons/Edit Square.svg";
import useSingleSubAdmin from "features/subadmins/api/subamin-single.api";
import Header from "features/ui/layout/headers/dashboard-header";
import { getFormattedAmount } from "utils/lib/number-formatter";
import Checkbox from "features/ui/forms/check-box";
import { useState } from "react";
import TabSelect from "features/ui/layout/nav/tabselect";

export default function SubAdminDetails() {
   const { id } = useParams();
   const { subAdminInfo, subAdminBills, subAdminGroups } = useSingleSubAdmin(id);
   const [tabSelect, setTabSelect] = useState("Sub Accounts");

   const tabSelectOptions = [
      {
         title: "Sub Accounts",
         onClick: () => setTabSelect("Sub Accounts"),
         isActive: tabSelect === "Sub Accounts",
      },
      {
         title: "Groups",
         onClick: () => setTabSelect("Groups"),
         isActive: tabSelect === "Groups",
      },
   ];

   if (!subAdminInfo) return null;

   const pStyle =
      "flex items-center border-none mt-[12px] text-[18px] poppins-bold text-black-one capitalize sm:text-xl md:mb-7 lg:text-[22px] lg:font-normal";
   return (
      <section className="wrapper">
         <div className="flex flex-col items-center md:items-stretch md:flex-row  md:w-5/6 md:max-w-3xl mx-auto">
            <Header settingsUrl="../settings" variant="OTHERS"></Header>
         </div>
         <div className="mx-auto md:w-5/6 p-[10px] flex flex-col justify-center items-center ">
            <img
               src={
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
               }
               alt="icon"
               className="text-black-one border-black-one border-[4px] rounded-[50%] h-[100px] w-[100px]"
            />

            <p className={pStyle}>{subAdminInfo?.name}</p>
            <p
               className={`bg-black-one rounded-[5px] mt-[10px] px-[5px] py-[2px] w-fit text-white flex flex-row items-center justify-center`}
            >
               {subAdminInfo?.unique_id}
            </p>
            {/* Transfer and balance */}
            <div className="flex  my-[20px] gap-[10px] items-end justify-center w-full">
               <p className={"poppins-bold text-[24px] "}>
                  <span className="text-[16px] text-black-three poppins-bold">₦</span>
                  {getFormattedAmount(subAdminInfo?.meta.balance).slice(1)}
               </p>
            </div>
            <div className="flex flex-row  w-full  justify-between mt-[20px] items-center">
               <div className="flex">
                  <Link
                     to={`../../dashboard/subAdmins/${id}/edit`}
                     className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
                  >
                     <img src={EditIcon} alt="edit" />
                     <span className="text-black-three">edit</span>
                  </Link>
                  {/* <Link
                     to={`../../dashboard/subAdmins/${id}/edit`}
                     className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold "
                  >
                     <img src={DeleteIcon} alt="withdraw" />
                     <span className="text-black-three">delete</span>
                  </Link> */}
               </div>
               <Link
                  to={`../../dashboard/subadmins/${id}-${subAdminInfo.unique_id}/transfer`}
                  className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
               >
                  <BsSend className="w-12 h-12 text-black-three" />
                  <span className="text-black-three poppins-semibold">transfer</span>
               </Link>{" "}
            </div>
            <Permissions subAdminInfo={subAdminInfo} />
            <div className="w-full h-full mt-[40px] p-5 mb-[20px] shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 bg-white rounded-[10px]">
               <TabSelect options={tabSelectOptions} />
               <TabDetails bills={subAdminBills} groups={subAdminGroups} tabSelect={tabSelect} />
            </div>
         </div>
      </section>
   );
}

const Permissions = ({ subAdminInfo }) => {
   return (
      <div className="w-full h-full mt-[40px] p-5 mb-[20px] shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 bg-white rounded-[10px]">
         <PermissionsInfo label="Add Admin" value={subAdminInfo.permissions.add_admin} />
         <PermissionsInfo
            label="Create User Group/Class"
            value={subAdminInfo.permissions.create_group_circle}
         />
         <PermissionsInfo
            label="Add Members To Group/Class"
            value={subAdminInfo.permissions.add_members}
         />
         <PermissionsInfo label="Create Bills" value={subAdminInfo.permissions.create_bills} />
         <PermissionsInfo
            label="View Ledga Balance"
            value={subAdminInfo.permissions.view_balance}
         />
         <PermissionsInfo
            label="View Ledga Transactions"
            value={subAdminInfo.permissions.view_transactions}
         />
         <PermissionsInfo
            label="Manage External Payment"
            value={subAdminInfo.permissions.external_payment}
         />
      </div>
   );
};

const PermissionsInfo = ({ label, value }) => {
   return (
      <article className="flex flex-row justify-between text-black my-[20px] md:text-lg">
         <Checkbox value={value} label={label} variant="SECONDARY" disabled />
      </article>
   );
};

const TabDetails = ({ tabSelect, bills, groups }) => {
   return (
      <div className="h-fit mb-[100px]">
         {tabSelect === "Sub Accounts" ? (
            <div>
               {bills.length > 0 ? (
                  bills.map((bill) => {
                     return <BillCard bill={bill} />;
                  })
               ) : (
                  <div className="p-6 text-xl">No Bills Yet</div>
               )}
            </div>
         ) : null}
         {tabSelect === "Groups" ? (
            <div>
               {groups.length > 0 ? (
                  groups.map((group) => {
                     return <GroupCard group={group} />;
                  })
               ) : (
                  <div className="bg-white p-6 text-xl">No Groups Yet</div>
               )}
            </div>
         ) : null}
      </div>
   );
};
