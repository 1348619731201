import ModalContainer from "../../modal/modal-container.component";

export default function LoadingModal({
   isOpen,
   modalPosition,
   message = "Please wait while your Ledga is loading",
}) {
   //TODO: add loading icon

   return (
      <ModalContainer
         size="THIN"
         isOpen={isOpen}
         modalPosition={modalPosition}
         showCloseBtn={false}
         hideOnBlur={false}
      >
         <div className="container h-screen justify-center md:h-auto md:py-20">
            <img
               src="/icons/tools _ time, hourglass, timer, deadline, stopwatch, people 1Admin.png"
               alt="loading"
               className="block w-full max-w-xs"
            />
            <p className="text-center whitespace-pre-wrap">{message}</p>
         </div>
      </ModalContainer>
   );
}
