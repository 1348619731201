import OrganizationDetailsForm from "features/setup/organization-details-form.component";
import BankDetailsForm from "features/bank-details/components/bank-details-form.component";
import LoadingModal from "features/ui/feedbacks/loading-modal";
import SetupLayout from "features/setup/setup-layout.component";
import useSetup from "features/setup/setup.api";
import CACDetailsForm from "features/setup/cac-details-form.comp";
// import SignatoriesForm from "features/setup/signatories-form";
import ProfileImageForm from "features/setup/profile-form";

export default function SetUp() {
   const {
      screen,
      setupLoading,
      hookFormMethods,
      handleBack,
      handleNextScreen,
      handleFormSubmit,
      setupDetails,
      setScreen,
   } = useSetup();

   switch (screen) {
      case 4: {
         return (
            <SetupLayout title="" handleBack={handleBack} setScreen={setScreen} screen={screen}>
               <ProfileImageForm handleNext={handleFormSubmit} />
               <LoadingModal
                  modalPosition="CENTER"
                  isOpen={setupLoading}
                  message="Wait a moment while we create your unique Organization ID"
               />
            </SetupLayout>
         );
      }

      case 3: {
         return (
            <SetupLayout
               title="Bank Details"
               message="Verify your banking info"
               handleBack={handleBack}
               setScreen={setScreen}
               screen={screen}
            >
               <BankDetailsForm
                  bvn
                  btnText="Complete"
                  onSubmit={handleNextScreen}
                  modalPosition="CENTER"
                  hookFormMethods={hookFormMethods}
               />
            </SetupLayout>
         );
      }
      // case 3: {
      //    return (
      //       <SetupLayout
      //          title="Signatory"
      //          message="Secure your account with a signatory"
      //          handleBack={handleBack}
      //          setScreen={setScreen}
      //          screen={screen}
      //       >
      //          <SignatoriesForm handleNext={handleNextScreen} />
      //       </SetupLayout>
      //    );
      // }
      case 2: {
         return (
            <SetupLayout
               title="Business Verification"
               message="Verify business details"
               handleBack={handleBack}
               setScreen={setScreen}
               screen={screen}
            >
               <CACDetailsForm handleNext={handleNextScreen} setupDetails={setupDetails} />
            </SetupLayout>
         );
      }
      default:
         return (
            <SetupLayout title="Basic Info" message="Enter basic details" screen={screen}>
               <OrganizationDetailsForm handleNext={handleNextScreen} />
            </SetupLayout>
         );
   }
}
