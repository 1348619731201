import { useWatch } from "react-hook-form";
import FormGroup from "features/ui/forms/form-group";

import NamesSelector from "./names-selector";
import { Controller } from "react-hook-form";
import Checkbox from "features/ui/forms/check-box";

export default function WithdrawSignOff({ methods, subAdminNames }) {
   const signOffEnforced = useWatch({
      control: methods.control,
      name: "withdrawal_permissions.signOff",
   });

   if (!subAdminNames) {
      return null;
   }

   return (
      <section>
         <h2 className="text-xl text-black-three">Withdraw Sign Off </h2>
         <p className="text-gray-400">Require Admins to sign off before withdrawal can be made</p>
         <div className="bg-white p-4 mb-6 rounded-lg flex flex-col items-center">
            <FormGroup className="relative mb-6 space-y-4 w-full">
               <Controller
                  control={methods.control}
                  name={`withdrawal_permissions.signOff`}
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Enforce Sign Off on Withdraw"
                        handleChange={(v) => {
                           if (v.target.value) {
                              methods.setValue("withdrawal_permissions.authorisers", []);
                           }
                           onChange(v);
                        }}
                     />
                  )}
               />
            </FormGroup>
            {signOffEnforced ? (
               <FormGroup className="w-full">
                  <h3 className="text-lg">Select Authorisers</h3>
                  <NamesSelector
                     methods={methods}
                     fieldName="withdrawal_permissions.authorisers"
                     nameList={subAdminNames}
                  />
               </FormGroup>
            ) : null}
         </div>
      </section>
   );
}
