import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const memberEditSchema = yup.object().shape({
   name: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please enter a valid name")
      .required("name cannot be empty"),
   email: yup.string().email("please enter a valid email").required("email cannot be empty"),
   student_info: yup.mixed(),
   staff_info: yup.mixed(),
});

const usersUtil = {
   memberEditSchema,
};

export default usersUtil;
