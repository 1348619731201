import { useParams } from "react-router-dom";

import Header from "features/ui/layout/headers/dashboard-header";
import SubAdminEditorForm from "features/subadmins/components/subadmins-editor-form";
import useUpdateSubAdmin from "features/subadmins/api/subadmin-update.api";

export default function SubAdminEdit() {
   const { id } = useParams();
   const { handleSubAdminUpdate } = useUpdateSubAdmin(id);

   return (
      <section className="wrapper bg-white">
         <Header title="Admins" variant="ACCOUNTS" />
         <div className="w-full min-h-screen mx-auto bg-[#F3F3F3] rounded-t-xl">
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px] pt-20">
               <SubAdminEditorForm
                  editingId={id}
                  handleSubmit={handleSubAdminUpdate}
                  editingMode="edit"
               />
            </div>
         </div>
      </section>
   );
}
