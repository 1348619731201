import { useNavigate } from "react-router-dom";

import ErrorModal from "features/modal/error-modal.component";
import Spinner from "features/ui/feedbacks/spinner";
import UserTab from "./user-tab";

export default function UsersTable({
   userType,
   usersData,
   usersDataError,
   usersDataIsLoading,
   mutateUsers,
}) {
   const navigate = useNavigate();

   if (usersDataError && !usersDataIsLoading) {
      return <ErrorModal isOpen={true} btnOnClick={mutateUsers} />;
   }

   if (usersDataIsLoading) {
      return (
         <div className="my-32 flex items-center justify-center">
            <Spinner color="GREY" size="MEDIUM" />
         </div>
      );
   }

   return (
      <div className="w-full max-w-screen-md">
         {usersData?.map((e) => {
            return (
               <div
                  key={e.uniqueId}
                  className="cursor-pointer hover:bg-blue-50 h-fit"
                  onClick={() => navigate(`../${userType}/${e._id}`)}
               >
                  <UserTab userData={e} key={e._id} />
               </div>
            );
         })}
      </div>
   );
}
