import useSWR from "swr";
import { useState } from "react";

import { getBanksUrl } from "../bank-details.services";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useBankList(modalPosition) {
   const [bankNames, setBankNames] = useState([]);
   const [bankData, setBankData] = useState("");

   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const { error: bankDataError, mutate: mutateBankData } = useSWR(
      getBanksUrl,
      {
         onSuccess: (data) => {
            setBankData(data.bank);
            const banks = data.bank.map((bank) => bank.name);
            setBankNames(banks);
         },
         onError: () =>
            showModal(MODAL_TYPES.ERROR, {
               modalPosition: modalPosition,
               hideOnBlur: false,
               btnOnClick: () => window.location.reload(),
            }),
      }
   );

   const bankDataLoading = !bankData && !bankDataError;

   return {
      bankNames,
      bankData,
      bankDataError,
      bankDataLoading,
      mutateBankData,
   };
}
