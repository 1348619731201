import { useEffect, useState } from "react";

import RadioBtn from "features/ui/forms/radio-btn";

export default function WithdrawToForm({ setValue, source, position = "RIGHT" }) {
   const [filterOptions, setFilterOptions] = useState(["external"]);
   const [selectedFilter, setSelectedFilter] = useState("external");

   useEffect(() => {
      if (source === "mainAccount") {
         setValue("to", "external");
         setSelectedFilter("external");
         setFilterOptions(["external", "bills", "savings"]);
      } else {
         setFilterOptions(["external", "main"]);
      }
   }, [source, setValue]);

   const handleRangeSelect = (value) => {
      setSelectedFilter(value);
      setValue("to", value);
   };

   return (
      <section className="px-6 mb-6">
         <h2>Select Account to Receive Your Fund</h2>
         <div className={`flex ${position === "CENTER" ? "justify-center" : ""}`}>
            <RadioBtn
               options={filterOptions}
               selected={selectedFilter}
               handleSelect={handleRangeSelect}
            />
         </div>
      </section>
   );
}
