import { useState } from "react";
import NumberFormat from "react-number-format";
import { AiOutlineCalendar } from "react-icons/ai";

import Form from "../forms/form";
import Button from "../forms/button";
import Input from "../forms/input";
import FormGroup from "../forms/form-group";
import RadioBtn from "../forms/radio-btn";
import { useWindowResize } from "utils/hooks/useWindowResize";
import ModalContainer from "../../modal/modal-container.component";

export default function FilterModal({
   isOpen,
   onClose,
   title = "",
   options,
   handleSubmit,
   showDateRange = true,
   defaultValue,
}) {
   const { width } = useWindowResize();
   const [selectedFilter, setSelectedFilter] = useState(defaultValue);

   const handleFormSubmit = (value, reset) => {
      handleSubmit(value, reset);
      onClose();
   };

   return (
      <ModalContainer variant="AUTO" isOpen={isOpen} onClose={onClose}>
         <div className="container min-h-0 py-6 md:py-8 lg:py-12">
            {title && (
               <h2 className="col-span-2 mb-6 font-semibold text-blue-600">
                  {title}
               </h2>
            )}

            {
               <Form
                  id="filter-form"
                  onSubmit={handleFormSubmit}
                  className="self-stretch"
               >
                  {({
                     control,
                     Controller,
                     formState: { errors, dirtyFields },
                  }) => {
                     return (
                        <>
                           {options && (
                              <FormGroup className="mb-4 md:mb-12">
                                 <Controller
                                    control={control}
                                    name="option"
                                    defaultValue=""
                                    render={({
                                       field: { onChange, value },
                                    }) => (
                                       <RadioBtn
                                          value={value}
                                          selected={selectedFilter}
                                          options={options}
                                          handleSelect={(value) => {
                                             onChange(value);
                                             setSelectedFilter(value);
                                          }}
                                       />
                                    )}
                                 />
                              </FormGroup>
                           )}

                           {showDateRange && (
                              <FormGroup className="flex justify-between my-10 gap-2">
                                 <Controller
                                    control={control}
                                    name="from"
                                    render={({
                                       field: { onChange, name, value },
                                    }) => (
                                       <NumberFormat
                                          variant="LINE"
                                          label="from"
                                          isEmpty={!dirtyFields.from}
                                          error={errors.from}
                                          customInput={Input}
                                          name={name}
                                          value={value}
                                          format="####-##-##"
                                          placeholder="yyyy-mm-dd"
                                          iconPosition="RIGHT_EDGE"
                                          icon={<AiOutlineCalendar />}
                                          mask={[
                                             "y",
                                             "y",
                                             "y",
                                             "y",
                                             "m",
                                             "m",
                                             "d",
                                             "d",
                                          ]}
                                          onValueChange={(v) => {
                                             onChange(v.formattedValue);
                                          }}
                                       />
                                    )}
                                 />

                                 <Controller
                                    control={control}
                                    name="to"
                                    render={({
                                       field: { onChange, name, value },
                                    }) => (
                                       <NumberFormat
                                          variant="LINE"
                                          label="to"
                                          isEmpty={!dirtyFields.to}
                                          error={errors.to}
                                          customInput={Input}
                                          name={name}
                                          value={value}
                                          format="####-##-##"
                                          placeholder="yyyy-mm-dd"
                                          iconPosition="RIGHT_EDGE"
                                          icon={<AiOutlineCalendar />}
                                          mask={[
                                             "y",
                                             "y",
                                             "y",
                                             "y",
                                             "m",
                                             "m",
                                             "d",
                                             "d",
                                          ]}
                                          onValueChange={(v) => {
                                             onChange(v.formattedValue);
                                          }}
                                       />
                                    )}
                                 />
                              </FormGroup>
                           )}
                        </>
                     );
                  }}
               </Form>
            }

            <Button size={width < 640 ? "SMALL" : "NORMAL"} form="filter-form">
               apply
            </Button>
         </div>
      </ModalContainer>
   );
}
