import SubAdminEditorForm from "features/subadmins/components/subadmins-editor-form";
import useAddSubAdmin from "features/subadmins/api/add-subadmin.api";
import Header from "features/ui/layout/headers/dashboard-header";

export default function SubAdminCreate() {
   const { handleSubmit } = useAddSubAdmin();

   return (
      <section className="wrapper bg-white">
         <Header title="Admins" variant="ACCOUNTS" />
         <div className="w-full min-h-screen mx-auto bg-[#F3F3F3] rounded-t-xl">
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px] pt-20">
               <SubAdminEditorForm handleSubmit={handleSubmit} editingMode="create" />
            </div>
         </div>
      </section>
   );
}
