import useVerifyEmail from "../features/auth/api/verfiy-email";
import OTPVerification from "features/misc/components/otp-verification";

export default function EmailVerification() {
   const {
      handleChange,
      handleVerification,
      verificationLoading,
      verificationError,
   } = useVerifyEmail();

   return (
      <div className="container justify-center min-h-screen">
         <OTPVerification
            handleChange={handleChange}
            verificationLoading={verificationLoading}
            validChars="a-z0-9"
            handleVerification={handleVerification}
            verificationError={verificationError}
         />
      </div>
   );
}
