import { useEffect } from "react";

import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { BsFillFileEarmarkCheckFill } from "react-icons/bs";

import Button from "features/ui/forms/button";
import { useSingleExcelInputUpload } from "features/ui/hooks/fileUpload";
import { useSingleExcelDragUpload } from "features/ui/hooks/dragUpload";
import Header from "features/ui/layout/headers/dashboard-header";

export default function AddMembers({ createMembers }) {
   const { register: clickRegister, excelData: clickData } = useSingleExcelInputUpload();
   const { register: dragRegister, dragState, excelData: dragData } = useSingleExcelDragUpload();

   //styles
   const title = `self-start capitalize pt-10 mb-6 text-2xl md:pt-16 md:text-3xl md:text-slate-500`;

   return (
      <section className="bg-white">
         <Header title="" variant="ACCOUNTS" />
         <div className="wrapper min-h-screen mx-auto bg-[#F3F3F3] rounded-t-xl border-2">
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px]">
               <h1 className={title}>Upload Members</h1>
               <div className="self-start w-full">
                  <h2 className="sm:text-xl md:text-2xl"> New Members</h2>
                  <p>Add New Members from Excel file</p>
               </div>

               <div className="relative h-[348px] cursor-pointer w-full flex flex-col items-center">
                  <label
                     className={`w-full h-full flex flex-col justify-center items-center cursor-pointer ${
                        dragState === "enter" ? "bg-blue-200" : "bg-white"
                     }`}
                     {...dragRegister()}
                  >
                     <input type="file" {...clickRegister()} className="w-full h-full hidden" />
                     <div className="pointer-events-none">
                        {clickData ? (
                           <BsFillFileEarmarkCheckFill className="w-28 h-32 pointe" />
                        ) : (
                           <BsFillFileEarmarkPlusFill className="w-28 h-32" />
                        )}

                        <p>{clickData ? "change .csv file" : "select .csv file"}</p>
                     </div>
                  </label>
               </div>

               <div className="flex justify-center">
                  <Button size="WIDE" onClick={() => createMembers({ members: clickData })}>
                     upload
                  </Button>
               </div>
               <hr className="w-4/5 mx-auto border border-slate-300 my-6" />
            </div>
         </div>
      </section>
   );
}
