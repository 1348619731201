import ModalContainer from "features/modal/modal-container.component";
import Numpad from "./numpad.component";

export default function NumPadModal({
   title,
   pin,
   setPin,
   amount,
   receiver,
   message,
   btnText,
   isOpen,
   bg = "BLUE",
   btnOnClick,
   modalPosition,
   onClose,
   children,
}) {
   return (
      <ModalContainer
         isOpen={isOpen}
         color={bg}
         modalPosition={modalPosition}
         onClose={onClose}
         variant="NUMPAD"
      >
         <Numpad
            title={title}
            pin={pin}
            setPin={setPin}
            amount={amount}
            receiver={receiver}
            message={message}
            btnText={btnText}
            btnOnClick={btnOnClick}
            children={children}
         />
      </ModalContainer>
   );
}
