import { useState } from "react";

import { useSearchParams } from "react-router-dom";
import Header from "features/ui/layout/headers/dashboard-header";
import FilterModal from "features/ui/modals/filter-modal";
import TransactionsTable from "features/transactions/components/table";
import useTransactions from "features/transactions/api/all-transactions";
import Pagination from "features/ui/layout/nav/pagination";

export default function Transactions() {
   const filterOptions = ["all", "payout", "funding"];
   const [queryParams, setQueryParams] = useState("");
   const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

   const [query] = useSearchParams();
   const page = query.get("page");

   const {
      totalCount,
      totalPages,
      transactions: transactionsData,
      transactionsError,
      transactionsLoading,
      refetchTransactions,
   } = useTransactions(queryParams, page ?? 1);

   const handleFilter = ({ option, from, to }) => {
      const dateQuery = `from=${from}&to=${to}`;
      const optionQuery = option === "all" ? "" : `transaction_type=${option}`;
      setQueryParams(`?${optionQuery}&${dateQuery}`);
   };

   return (
      <section className="wrapper bg-[##FCFDFF] md:bg-[##EDF5FC] min-h-screen">
         <Header variant="TRANSACTIONS" title="Transaction History" settingsUrl="../settings" />
         <TransactionsTable
            transactionsError={transactionsError}
            refetchTransactions={refetchTransactions}
            transactionsData={transactionsData}
            transactionsLoading={transactionsLoading}
            toggleModal={() => setFilterModalIsOpen((prev) => !prev)}
         />
         <Pagination size={50} total={totalCount} pageCount={totalPages} />
         <FilterModal
            defaultValue="all"
            options={filterOptions}
            handleSubmit={handleFilter}
            isOpen={filterModalIsOpen}
            onClose={() => setFilterModalIsOpen((prev) => !prev)}
         />
      </section>
   );
}
