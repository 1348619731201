import { getFormattedPercentage, getFormattedAmount } from "./number-formatter";

export default function getTransactionsSummary(payouts, fundings) {
   const currentDate = new Date();

   const getCurrentDay = (dateObj) => {
      if (dateObj.getDay() === 0) {
         return 6;
      } else {
         return dateObj.getDay() - 1;
      }
   };

   const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - getCurrentDay(currentDate)
   );

   const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth()
   );

   const transactionDate = (createdDate) => new Date(createdDate).getTime();

   const getCalculatedPeriod = (transactions, filter) => {
      const amount = transactions
         .filter((e) => transactionDate(e.created) >= filter)
         .reduce((r, e) => {
            return r + e.amount;
         }, 0);
      const formattedAmount = getFormattedAmount(amount);

      return { amount, formattedAmount };
   };

   //monthly transactions
   const monthPayouts = getCalculatedPeriod(payouts, startOfMonth.getTime());
   const monthFundings = getCalculatedPeriod(fundings, startOfMonth.getTime());
   const monthTotal = monthPayouts.amount + monthFundings.amount;
   const monthFundingsPct = getFormattedPercentage(
      monthFundings.amount / monthTotal
   );
   const monthPayoutsPct = getFormattedPercentage(
      monthPayouts.amount / monthTotal
   );

   //weekly transactions
   const weekPayouts = getCalculatedPeriod(payouts, startOfWeek.getTime());
   const weekFundings = getCalculatedPeriod(fundings, startOfWeek.getTime());
   const weekTotal = weekPayouts.amount + weekFundings.amount;
   const weekFundingsPct = getFormattedPercentage(
      weekFundings.amount / weekTotal
   );
   const weekPayoutsPct = getFormattedPercentage(
      weekPayouts.amount / weekTotal
   );

   //daily transactions.
   const dayPayouts = getCalculatedPeriod(payouts, currentDate.getTime());
   const dayFundings = getCalculatedPeriod(fundings, currentDate.getTime());
   const dayTotal = dayPayouts.amount + dayFundings.amount;
   const dayFundingsPct = getFormattedPercentage(dayFundings.amount / dayTotal);
   const dayPayoutsPct = getFormattedPercentage(dayPayouts.amount / dayTotal);

   return {
      month: {
         payouts: monthPayouts.formattedAmount,
         fundings: monthFundings.formattedAmount,
         total: getFormattedAmount(monthTotal),
         fundingsPct: monthFundingsPct,
         payoutsPct: monthPayoutsPct,
      },
      week: {
         payouts: weekPayouts.formattedAmount,
         fundings: weekFundings.formattedAmount,
         total: getFormattedAmount(weekTotal),
         fundingsPct: weekFundingsPct,
         payoutsPct: weekPayoutsPct,
      },
      day: {
         payouts: dayPayouts.formattedAmount,
         fundings: dayFundings.formattedAmount,
         total: getFormattedAmount(dayTotal),
         fundingsPct: dayFundingsPct,
         payoutsPct: dayPayoutsPct,
      },
   };
}
