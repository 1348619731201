import Select from "features/ui/forms/select";
import RadioBtn from "features/ui/forms/radio-btn";
import Form from "features/ui/forms/form";
import { getDays } from "utils/lib/getDays";

export default function SetPayoutForm({ handleSubmit, formId }) {
   const filterOptions = ["weekly", "monthly"];
   const { monthDays, weekDays } = getDays();
   return (
      <Form
         id={formId}
         onSubmit={(data, resetForm) => handleSubmit(data, resetForm)}
         className="self-stretch my-8"
      >
         {({
            Controller,
            control,
            resetField,
            watch,
            formState: { errors },
         }) => {
            const schedule = watch("schedule");
            const dayOptions =
               schedule === undefined
                  ? weekDays
                  : schedule === "weekDays"
                  ? weekDays
                  : monthDays;
            /*
             *when component first mounts, schedule is
             *undefined and so that is set to weekDays since
             *the default schedule is weekly
             */
            return (
               <>
                  <Controller
                     control={control}
                     name="schedule"
                     defaultValue="weekly"
                     render={({ field: { onChange, value } }) => (
                        <RadioBtn
                           selected={value}
                           options={filterOptions}
                           handleSelect={(value) => {
                              onChange(value);
                              resetField("day");
                           }}
                        />
                     )}
                  />
                  <Controller
                     control={control}
                     name="day"
                     defaultValue=""
                     render={({ field: { onChange, name, value } }) => (
                        <Select
                           lineSelect
                           value={value}
                           name={name}
                           label="day"
                           error={errors.day}
                           options={dayOptions}
                           handleChange={(value) => {
                              onChange(value);
                           }}
                        />
                     )}
                  />
               </>
            );
         }}
      </Form>
   );
}
