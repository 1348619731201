import ModalContainer from "features/modal/modal-container.component";

export default function BlankModal({
   onClose,
   isOpen,
   modalPosition,
   children,
   hideOnBlur,
   showCloseBtn,
   size,
   variant,
}) {
   return (
      <>
         <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            modalPosition={modalPosition}
            hideOnBlur={hideOnBlur}
            showCloseBtn={showCloseBtn}
            size={size}
            variant={variant}
         >
            <div className="text-lg text-center container min-h-screen justify-center md:min-h-0 md:py-20">
               {children}
            </div>
         </ModalContainer>
      </>
   );
}
