import Header from "features/ui/layout/headers/dashboard-header";
import { Link, useParams } from "react-router-dom";
import { useAdmin } from "features/auth/admin-context";
import { useFormData } from "utils/hooks/useFormData";
import { getFormattedAmount } from "utils/lib/number-formatter";
import useSingleSavings from "features/savings/api/single-savings.api";
import { useState } from "react";
import RangeModal from "features/ui/modals/range-modal";
import useHandleFilter from "utils/hooks/useHandleFilter";
import { getFormattedDate } from "utils/lib/number-formatter";

import { BiCalendar } from "react-icons/bi";

import { FiTarget } from "react-icons/fi";

import LockBlack from "../../src/assets/icons/LedgaV2 Icons/Lock Black.svg";
import AccountsTable from "features/bills/components/table";
import WithdrawToModal from "features/withdraw/withdraw-to-modal";
import CustomDeleteIcon from "assets/custom-icons/delete-icon";
import CustomEditIcon from "assets/custom-icons/edit-icon";
import CustomWithdrawIcon from "assets/custom-icons/withdraw-icon";

export default function SavingsAccountDetails() {
   const initialFormData = {
      filterStartDate: "",
      filterEndDate: "",
      downloadStartDate: "",
      downloadEndDate: "",
   };
   // form states
   const [state, dispatch] = useFormData(initialFormData);
   const { handleFilterSelect, handleDateChange, handleFilter } = useHandleFilter(state, dispatch);
   const { id: savingsId } = useParams();

   // modal states
   const [filterModal, setFilterModal] = useState(false);
   const [withdrawModal, setWithdrawModal] = useState(false);
   const toggleWithdrawModal = () => setWithdrawModal((prev) => !prev);

   const {
      savings,
      savingsInfo,
      savingsTransactions,
      savingsError,
      savingsLoading,
      savingsFilterOptions,
   } = useSingleSavings(savingsId);

   const { adminId } = useAdmin();

   if (!savings) {
      return null;
   }

   // const pStyle =
   //    "self-start capitalize text-white py-1 px-6 mx-1 rounded-2xl bg-primary  text-sm md:font-semibold md:rounded-none md:bg-none";

   return (
      <section className="wrapper">
         <div className="flex flex-col items-center md:items-stretch md:flex-row  md:w-5/6 md:max-w-3xl mx-auto">
            <Header
               settingsUrl="../settings"
               variant="SAVINGS"
               schoolId={adminId}
               title={savingsInfo.savings_type === "cashTarget" ? "Stash" : "Fixed Lock"}
            >
               <div className="w-full flex flex-row  justify-between py-[20px] px-[20px] bg-black-one h-[152px] rounded-[10px]">
                  <div className="flex flex-row gap-[20px]">
                     <div className="bg-black-four h-full w-[120px] rounded-[10px] items-center justify-center flex">
                        <img src={LockBlack} alt="lock" />
                     </div>

                     <div className="flex flex-col justify-between h-full">
                        <div className="">
                           <p className="text-black-three text-lg font-semibold">
                              {savingsInfo?.title}
                           </p>
                           <p className="text-black-three text-sm  font-semibold">
                              {savingsInfo && getFormattedAmount(savingsInfo?.meta?.target)}
                           </p>
                        </div>

                        <p className="text-3xl font-semibold sm:text-4xl md:text-5xl">
                           {savingsInfo && getFormattedAmount(savingsInfo?.meta?.balance)}
                        </p>
                     </div>
                  </div>

                  <div className="flex gap-[5px]">
                     <BiCalendar
                        className={
                           savingsInfo?.savings_type === "dateTarget"
                              ? "text-white"
                              : "text-black-three"
                        }
                     />

                     <FiTarget
                        className={
                           savingsInfo?.savings_type === "cashTarget"
                              ? "text-white"
                              : "text-black-three"
                        }
                     />
                  </div>
               </div>
               <div className="flex px-[10px] flex-row justify-between w-full mt-[10px]">
                  <div>
                     <p className="text-black-three">Due</p>
                     <p className="text-black-one font-bold">
                        {" "}
                        {savingsInfo && getFormattedAmount(savingsInfo?.meta?.target)}
                     </p>
                  </div>
                  <div>
                     <p className="text-black-three">Created At</p>
                     <p className="text-black-one font-bold">
                        {getFormattedDate(savingsInfo?.created_at)}
                     </p>
                  </div>
                  <div>
                     <p className="text-black-three">Interest</p>
                     <p className="text-black-one font-bold">0%</p>
                  </div>
               </div>
               <div className="flex mt-[20px] flex-row w-full justify-between items-center">
                  <button
                     onClick={toggleWithdrawModal}
                     className="bg-black-three rounded-3xl p-2 flex justify-start items-center gap-2 h-[50px] font-semibold px-6"
                  >
                     <CustomWithdrawIcon className={"w-[20px]"} color={"#0A0A0A"} />{" "}
                     <span className="text-black-one">Withdraw</span>
                  </button>

                  <div className="flex ">
                     <Link
                        to={`/dashboard/savings-accounts/${savingsInfo._id}/edit`}
                        className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
                     >
                        <CustomEditIcon className={"w-[20px]"} color={"#848484"} />
                        <span className="text-black-three">edit</span>
                     </Link>
                     <button
                        onClick={() => console.log("clicked")}
                        className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold "
                     >
                        <CustomDeleteIcon className={"w-[20px]"} color={"#848484"} />
                        <span className="text-black-three">delete</span>
                     </button>
                  </div>
               </div>
            </Header>
         </div>
         {/* <div className="flex flex-col text-sm max-w-sm  sm:text-base sm:max-w-lg md:w-5/6 md:max-w-3xl mx-auto bg-primary text-white rounded-lg p-4 my-4">
            <h2>Bills</h2>
            {savingsInfo.funding_source.map((s) => (
               <div className="flex gap-1" key={s.bill_id._id}>
                  <p className="basis-[230px] shrink-0 grow-0">
                     {s.bill_id.unique_id}:
                  </p>
                  <p>{s.percentage}%</p>
               </div>
            ))}
         </div> */}

         <AccountsTable
            toggleModal={() => setFilterModal((prev) => !prev)}
            className="mb-[220px]"
            billTransactions={savingsTransactions}
            billTransactionsError={savingsError}
            billTransactionsLoading={savingsLoading}
         />

         <WithdrawToModal
            id={savingsInfo._id}
            title={savingsInfo.title}
            source="savings"
            isOpen={withdrawModal}
         />

         <RangeModal
            onClose={() => setFilterModal(false)}
            isOpen={filterModal}
            dispatch={dispatch}
            options={savingsFilterOptions}
            startDate={state.startDate}
            endDate={state.endDate}
            selectedFilter={state.selectedFilter}
            handleSubmit={handleFilter}
            handleDateChange={handleDateChange}
            handleRangeSelect={handleFilterSelect}
         />
      </section>
   );
}
