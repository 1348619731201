export default function CardItem({ className, children }) {
   const cardClass = `
        ${className}
        p-5 
        shadow-bottom
        shadow-lg
        w-full
        p-2
        rounded-xl
        border 
        border-none
    `;
   return <div className={`${cardClass}`}>{children}</div>;
}
