import { axios } from "utils/lib/axios";

export const getNubanName = async (queryObj) => {
   return axios.post("payment/nuban-name-enquiry", queryObj);
};

export const getBanksUrl = "payment/banks";

export const updateBankDetails = async (queryObj) => {
   return axios.put("admin/bank-details", queryObj);
};
