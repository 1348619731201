import Button from "features/ui/forms/button";

export default function DuplicatesMessage({
   duplicatesCount,
   showDuplicatesModal,
}) {
   return (
      <div className="text-red-600 text-center flex flex-col items-center">
         {duplicatesCount} duplicate entrie(s) in your schoool database were
         discovered.
         <div className="flex items-center whitespace-pre">
            <Button
               variant="TERTIARY"
               color="YELLOW_TEXT"
               size="ZERO"
               onClick={showDuplicatesModal}
            >
               <span className="underline">see duplicates</span>
            </Button>
            <span className="ml-1">to review them.</span>
         </div>
      </div>
   );
}
