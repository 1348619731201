import { useState } from "react";

import { useGlobalModalContext } from "features/modal/global-modal.context";
import getUploadsPDF from "../uploaded-users-pdf";
import DuplicatesMessage from "../components/duplicates-message";
import { getTableData } from "../users.utils";

export default function useCreateUser(createFn) {
   const [duplicateEntries, setDuplicateEntries] = useState([]);
   const [duplicatesModal, setDuplicatesModal] = useState(false);
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const showDuplicatesModal = () => {
      hideModal();
      setDuplicatesModal(true);
   };
   const closeDuplicateModal = () => setDuplicatesModal(false);

   const createUsers = (queryObj, handleReset) => {
      showModal(MODAL_TYPES.LOADING);
      createFn(queryObj, {
         onSuccess: (data) => {
            const duplicatesFound = data?.duplicatesFound;
            // setDuplicateEntries(duplicatesFound);
            const users = data?.membersAdded;
            const usersCount = users?.length || data?.totalMembersAdded;
            // handleReset()
            showModal(MODAL_TYPES.SUCCESS, {
               title: `${usersCount} members uploaded`,
               message:
                  usersCount > 0 &&
                  `Your members will receive an email shortly.\n Please ensure to download before closing this modal`,
               btnText: usersCount > 0 ? "download" : "retry",
               icon: usersCount < 0 && " ",
               hideOnBlur: false,
               onClose: () => hideModal(),
               btnOnClick: () => {
                  usersCount > 0 && getUploadsPDF("members", getTableData(users || []));
                  hideModal();
               },
               children: duplicatesFound?.length > 0 && (
                  <DuplicatesMessage
                     duplicatesCount={duplicatesFound?.length}
                     showDuplicatesModal={showDuplicatesModal}
                  />
               ),
            });
         },
         onError: (err) => {
            console.log(err);
            showModal(MODAL_TYPES.ERROR);
         },
      });
   };

   return {
      createUsers,
      duplicateEntries,
      duplicatesModal,
      closeDuplicateModal,
   };
}
