export default function RadioBtn({ options, selected, handleSelect }) {
   return (
      <div className="flex flex-wrap justify-center">
         {options.map((option) => {
            return (
               <div
                  key={option}
                  className={`text-center text-xs cursor-pointer px-4 py-2 my-2 min-w-[20px] mx-1 rounded-3xl border-2 border-primary ${
                     selected === option ? "bg-primary text-white" : "bg-white text-primary"
                  } md:text-lg md:min-w-[80px]`}
                  onClick={() => handleSelect(option)}
               >
                  {option}
               </div>
            );
         })}
      </div>
   );
}
