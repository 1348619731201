import { Link, useSearchParams } from "react-router-dom";

import AddMemberIcon from "assets/icons/LedgaV2 Icons/addMemberIcon";
import UsersTable from "features/add-users/components/users-table";
import Header from "features/ui/layout/headers/dashboard-header";
import SidebarRight from "features/ui/layout/nav/sidebar-right";
import useMembers from "features/add-users/api/get-members.api";
import SearchBar from "features/shared/components/searchbar";
import Pagination from "features/ui/layout/nav/pagination";

export default function Members() {
   const [query] = useSearchParams();
   const search = query.get("search");
   const page = query.get("page");

   const {
      totalCount, //
      totalPages,
      membersData,
      membersError,
      membersIsLoading,
      mutateMembers,
   } = useMembers(search ?? "", page ?? 1);

   return (
      <section className="wrapper bg-white flex flex-col px-[20px] md:px-[50px] min-h-screen">
         <Header variant="OTHERS" title="Members" settingsUrl="../settings" to={"../settings"} />
         <SearchBar />

         <div className="w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#EDF5FC] rounded-t-xl flex flex-col items-center">
            <UsersTable
               userType="members"
               usersData={membersData}
               usersDataError={membersError}
               usersDataIsLoading={membersIsLoading}
               mutateUsers={mutateMembers}
            />
         </div>
         <Pagination size={50} total={totalCount} pageCount={totalPages} />
         <SidebarRight>
            <Link className="flex absolute bottom-24 w-full justify-center" to="../members/add">
               <AddMemberIcon className="w-14 h-14" />
            </Link>
         </SidebarRight>
      </section>
   );
}
