import { Link, useParams } from "react-router-dom";

import BillCard from "features/bills/components/bill-card";
import EditIcon from "../assets/icons/LedgaV2 Icons/Edit Square.svg";
import DeleteIcon from "../assets/icons/LedgaV2 Icons/Delete.svg";
import Header from "features/ui/layout/headers/dashboard-header";
import { useState } from "react";
import TabSelect from "features/ui/layout/nav/tabselect";
import useSingleGroup from "features/groups/api/group-single.api";
import MemberCard from "features/add-users/components/member-card";
import useDeleteGroup from "features/groups/api/group-delete.api";

export default function GroupDetails() {
   const { id } = useParams();
   const { groupInfo, groupBills, groupMembers } = useSingleGroup(id);
   const [tabSelect, setTabSelect] = useState("Sub Accounts");
   const { onDeleteClick } = useDeleteGroup(groupInfo);

   const tabSelectOptions = [
      {
         title: "Sub Accounts",
         onClick: () => setTabSelect("Sub Accounts"),
         isActive: tabSelect === "Sub Accounts",
      },
      {
         title: "Members",
         onClick: () => setTabSelect("Members"),
         isActive: tabSelect === "Members",
      },
   ];

   if (!groupInfo) return null;

   const pStyle =
      "flex items-center border-none mt-[12px] text-[18px] poppins-bold text-black-one capitalize sm:text-xl md:mb-7 lg:text-[22px] lg:font-normal";
   return (
      <section className="wrapper">
         <div className="flex flex-col items-center md:items-stretch md:flex-row  md:w-5/6 md:max-w-3xl mx-auto">
            <Header settingsUrl="../settings" variant="OTHERS"></Header>
         </div>
         <div className="mx-auto md:w-5/6 p-[10px] flex flex-col justify-center items-center ">
            <img
               src={
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
               }
               alt="icon"
               className="text-black-one border-black-one border-[4px] rounded-[50%] h-[100px] w-[100px]"
            />

            <p className={pStyle}>{groupInfo?.name}</p>
            <p
               className={`bg-black-one rounded-[5px] mt-[10px] px-[5px] py-[2px] w-fit text-white flex flex-row items-center justify-center`}
            >
               {groupInfo?.unique_id}
            </p>
            <div className="flex flex-row  w-full  justify-center mt-[20px] items-center">
               <Link
                  to={`../../dashboard/groups/${id}/edit`}
                  className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
               >
                  <img src={EditIcon} alt="edit" />
                  <span className="text-black-three">edit</span>
               </Link>

               <Link
                  to={`../../dashboard/groups/${id}/members-edit`}
                  className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
               >
                  <img src={EditIcon} alt="edit" />
                  <span className="text-black-three">edit members</span>
               </Link>

               <button
                  onClick={onDeleteClick}
                  className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold "
               >
                  <img src={DeleteIcon} alt="withdraw" />
                  <span className="text-black-three">delete</span>
               </button>
            </div>
            <div className="w-full h-full mt-[40px] p-5 mb-[20px] shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 bg-white rounded-[10px]">
               <TabSelect options={tabSelectOptions} />
               <TabDetails bills={groupBills} members={groupMembers} tabSelect={tabSelect} />
            </div>
         </div>
      </section>
   );
}

const TabDetails = ({ tabSelect, bills, members }) => {
   return (
      <div className="h-fit mb-[100px]">
         {tabSelect === "Sub Accounts" ? (
            <div>
               {bills.length > 0 ? (
                  bills.map((bill) => {
                     return <BillCard bill={bill} />;
                  })
               ) : (
                  <div className="p-6 text-xl">No Bills Yet</div>
               )}
            </div>
         ) : null}
         {tabSelect === "Members" ? (
            <div>
               {members.length > 0 ? (
                  members.map((member) => {
                     return <MemberCard member={member} />;
                  })
               ) : (
                  <div className="bg-white p-6 text-xl">No Members Yet</div>
               )}
            </div>
         ) : null}
      </div>
   );
};
