import useSWR from "swr";
import { getSingleMerchantUrl } from "../users.service";

export default function useSingleMerchant(id) {
   const {
      data: merchant,
      error: merchantError,
      mutate: mutateMerchant,
   } = useSWR(id ? [getSingleMerchantUrl(id), "", "V2"] : null);

   const merchantLoading = !merchant && !merchantError;

   return {
      merchant,
      merchantInfo: merchant,
      mutateMerchant,
      merchantError,
      merchantLoading,
   };
}
