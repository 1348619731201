import { classNames } from "utils/lib/getClassName";
import { useState, useRef } from "react";

const INPUT_MAPS = {
   PRIMARY: "block border-2 border-primary p-[14px] rounded-lg",
   SECONDARY: "block border-2 border-primary p-4 rounded-lg bg-white",
   LINE: "block border-b-2 border-primary p-4 mr-2 rounded-none py-1 outline-none focus:border-neutral-600",
   PRIMARYV2: "block p-[14px] rounded-none border border-gray-300 max-w-[85vw] md:border-none",
   PRIMARYV3: "block p-[14px] rounded-none border border-gray-300 max-w-[85vw]",
};

const LABEL_MAPS = {
   PRIMARY: "top-1/2 -translate-y-1/2 left-4 text-secondary-darker",
   SECONDARY: "top-1/2 -translate-y-1/2  left-4 text-secondary-darker",
   LINE: "-bottom-6 left-0",
   PRIMARYV2:
      "-top-[30px] -translate-y-1/2 left-1 text-black-one mt-[15px] rounded-none border-none",
   PRIMARYV3:
      "-top-[30px] -translate-y-1/2 left-1 text-black-one mt-[15px] rounded-none border-none",
};

const ERROR_LABEL_MAPS = {
   /*7.5px is added to -translate-y of label to factor in the increase in the 
   Input component height once error becomes active*/
   PRIMARY: "top-1/2 -translate-y-[calc(50%_+_7.5px)]  left-4 text-secondary-darker",
   SECONDARY: "top-1/2 -translate-y-[calc(50%_+_7.5px)]  left-4 text-secondary-darker",
   LINE: "",
   PRIMARYV2:
      "-top-[30px] -translate-y-1/2 left-1 text-secondary-darker mt-[15px] rounded-none md:border-none",
   PRIMARYV3:
      "-top-[30px] -translate-y-1/2 left-1 text-secondary-darker mt-[15px] rounded-none md:border-none",
};

const ACTIVE_MAPS = {
   PRIMARY: "top-1 text-xs left-4 text-primary",
   SECONDARY: "-top-3 px-1 left-3 bg-white",
   LINE: "-bottom-6 left-4 text-tertiary-darker",
   PRIMARYV2:
      "-top-[30px] -translate-y-1/2 left-1 text-secondary font-semibold mt-[15px] rounded-none",
   // the only difference between the v2 and v3 primary is border in larger screen. A better approach is needed
   PRIMARYV3:
      "-top-[30px] -translate-y-1/2 left-1 text-secondary font-semibold mt-[15px] rounded-none",
};

const DISABLED_MAPS = {
   PRIMARY: "text-secondary-darker",
   SECONDARY: "text-secondary-darker",
   LINE: "text-secondary-darker",
   PRIMARYV2: "",
};

const ICON_POSITION_MAPS = {
   LEFT: "left-4",
   RIGHT: "right-4",
   LEFT_EDGE: "left-0",
   RIGHT_EDGE: "right-0",
};

export default function Input({
   icon,
   label,
   type = "text",
   disabled = false,
   error,
   isEmpty,
   placeholder,
   iconOnClick,
   registration,
   iconPosition = "LEFT",
   variant = "PRIMARYV2",
   ...rest
}) {
   const labelRef = useRef();
   const [toggleLabel, setToggleLabel] = useState(false);

   document.addEventListener("onautocomplete", function (e) {
      e.target.hasAttribute("autocompleted"); // true or false
      e.preventDefault();
   });

   //styles
   const inputClass = classNames(
      "w-full text-base disabled:bg-white disabled:border-secondary",
      type === "password" && "tracking-[0.5em]",
      INPUT_MAPS[variant]
   );

   const labelClass = classNames(
      "capitalize absolute transition-all",
      toggleLabel ? ACTIVE_MAPS[variant] : error ? ERROR_LABEL_MAPS[variant] : LABEL_MAPS[variant],
      disabled && DISABLED_MAPS[variant]
   );

   const iconClass = classNames(
      "absolute z-20 text-slate-500 top-1/2 -translate-y-1/2",
      ICON_POSITION_MAPS[iconPosition]
   );

   const errorClass = classNames(
      "whitespace-pre-wrap text-xs pl-1 text-red-500 font-bolder block",
      variant === "LINE" && "absolute -bottom-4 right-0 pl-0"
   );

   return (
      <label
         className="relative mt-10 block"
         ref={labelRef}
         onBlur={() => isEmpty && setToggleLabel(false)}
      >
         <input
            autoComplete="off"
            {...rest}
            {...registration}
            type={type}
            className={inputClass}
            disabled={disabled}
            placeholder={toggleLabel ? placeholder : ""}
            onFocus={() => setToggleLabel(true)}
         />
         <span className={labelClass}>{label}</span>
         <span className={iconClass} onClick={iconOnClick}>
            {icon}
         </span>
         <span className={errorClass}>{error?.message}</span>
      </label>
   );
}

/*Structure */
//an input field typically has a border, a label positioned absolute and
//error message as well as an icon

/* Error Message*/
//when the input field is blurred, the error message can become displayed
//on the input page based on the field validation in the form schema
