import useSWR from "swr";
import { getSingleGroupUrl } from "../groups.service";

export default function useSingleGroup(id) {
   const {
      data: group,
      error: groupError,
      mutate: mutategroup,
   } = useSWR(id ? [getSingleGroupUrl(id), "", "V2"] : null);

   const groupLoading = !group && !groupError;

   return {
      group,
      groupInfo: group?.group,
      groupBills: group?.bills,
      groupMembers: group?.members,
      mutategroup,
      groupError,
      groupLoading,
   };
}
