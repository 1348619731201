import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import useCreateSavings from "features/savings/api/create-savings.api";

import SavingsEditorForm from "features/savings/components/savings-editor-form";

export default function SavingsAccountCreate() {
   const { handleSubmit } = useCreateSavings();
   //   const [query] = useSearchParams();
   //   const targetType = query.get("type");

   return (
      <section className="wrapper bg-[#FCFDFF] md:bg-[#F3F3F3]">
         <Link to="../../dashboard/savings-accounts">
            <IoArrowBack className="fixed top-10 left-4 w-12 md:w-8 cursor-pointer md:hidden text-4xl" />
         </Link>
         <div className="container sef left text-primary pt-20 md:max-w-xl">
            <div className="self-stretch justify-items-center gap-3 lg:gap-6">
               <SavingsEditorForm
                  handleSubmit={handleSubmit}
                  editingMode="create"
               />
            </div>
         </div>
      </section>
   );
}
