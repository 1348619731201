import { axios, axiosClient } from "utils/lib/axios";

export const withdrawFromBill = (queryObj, id, to) => {
   return axios.post(
      `admin/bills/${id}/withdraw?internal=${to === "main" ? true : false}`,
      queryObj
   );
};

export const withdrawFromSavings = (queryObj, id, to) => {
   return axiosClient.post(`admin/savings/withdraw?internal=${to === "main" ? true : false}`, {
      ...queryObj,
      savings_id: id,
   });
};

export const withdrawFromMain = (queryObj, id, to, toId) => {
   return axiosClient.post(`admin/ledga/withdraw?destination=${to}&unique_id=${toId}`, {
      ...queryObj,
   });
};
