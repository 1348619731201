import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

const fundingSchema = {
   bill_id: yup.string().required("Bill Id is required"),
   percentage: yup
      .number()
      .min(10, "Percentage must be greater than 9")
      .max(100, "Percentage must be less than 100")
      .required("Percentage is required")
      .typeError("Percentage is required"),
};

export const savingsSchema = yup.object().shape({
   title: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please enter a valid savaings name")
      .required("savings name cannot be empty"),
   savings_type: yup
      .mixed()
      .oneOf(["cashTarget", "dateTarget"], "please select a savings type")
      .required("please select a savings type"),
   target: yup
      .number("please enter a valid target")
      .positive("please enter a valid target")
      .required("target cannot be empty")
      .typeError("please enter a valid target"),
   funding_source: yup.array().of(yup.object().shape(fundingSchema)),
});

const getValidFundingSources = (bills, funding_sources) => {
   const billIds = bills.map((b) => b._id);
   let validatedSources = funding_sources.filter((fs) => billIds.includes(fs.bill_id));

   if (validatedSources.length < 1) {
      validatedSources = [{ bill_id: "", percentage: "" }];
   }

   return validatedSources;
};

const savingsAccountUtils = {
   getValidFundingSources,
};

export default savingsAccountUtils;
