import { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "features/ui/layout/nav/sidebar";
import {} from "react-router-dom";
import SidebarRight from "features/ui/layout/nav/sidebar-right";

export default function Dashboard() {
   const { pathname } = useLocation();

   const excludePaths = [
      "/dashboard/members",
      "/dashboard/merchants",
      "/dashboard/groups",
      "/dashboard/subadmins",
   ];

   return (
      <Fragment>
         <div className="bg-[#FCFDFF] md:bg-[#EDF5FC]">
            <Sidebar />
            <Outlet />
            {excludePaths.includes(pathname) ? null : <SidebarRight />}
         </div>
      </Fragment>
   );
}
