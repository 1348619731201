import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const groupSchema = yup.object().shape({
   title: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please enter a valid group name")
      .required("group name cannot be empty"),
   manager_access: yup
      .array()
      .of(
         yup.object().shape({
            admin_id: yup.string().required(),
            permissions: yup.object().shape({
               withdraw: yup.bool(),
               edit_account: yup.bool(),
            }),
         })
      )
      .compact((v) => !v.admin_id)
      .min(1, "Please select at least one group manager")
      .required("Please select at least one group manager"),
});

const permissions = [
   { label: "Edit Members", fieldName: "edit_members" },
   { label: "View Bills", fieldName: "view_bills" },
];

const groupTypes = [
   { name: "Default", value: "default" },
   { name: "Other", value: "other" },
];

const updateTypes = [
   { name: "Add", value: "add" },
   { name: "Remove", value: "remove" },
];

const groupUtils = {
   groupSchema,
   permissions,
   groupTypes,
   updateTypes,
};

export default groupUtils;
