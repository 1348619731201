import { axios } from "utils/lib/axios";

export const getAllRequestsUrl = "admin/requests/all";

export const acceptPayoutRequest = async (queryObj) => {
   return axios.post("admin/payouts/student/accept", queryObj);
};

export const rejectPayoutRequest = async (queryObj) => {
   return axios.post("admin/payouts/student/reject", queryObj);
};

export const requestPersonalPayout = async (queryObj) => {
   return axios.post("admin/payouts/request", queryObj);
};
