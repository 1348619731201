import { Link } from "react-router-dom";

import { getFormattedAmount, getFormattedDate } from "utils/lib/number-formatter";
import AccountIcon from "../../../assets/icons/LedgaV2 Icons/folder-gray.png";

export default function SavingsCard({ savings, enableLink = true }) {
   if (!savings) {
      return null;
   }

   return (
      <div className="group bg-white flex flex-row shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80  justify-start px-[20px] py-[15px] md:px-10 gap-4 items-center text-black-three relative  rounded-xl  md:py-[10px] w-full md:max-w-full">
         {enableLink ? (
            <Link
               to={savings._id}
               className="after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0"
            ></Link>
         ) : null}

         <img src={AccountIcon} alt="icon" className="text-black-one h-[40px]" />
         <div className="h-[90px] w-[2px] bg-black-two"></div>

         <div className="text-start capitalize text-base p-4 font-semibold md:text-lg md:py-6">
            <p className="text-black-one poppins-bold text-[20px]">
               <span className="text-[16px]">₦</span>

               {getFormattedAmount(savings.meta.balance).slice(1)}
            </p>
            <p className="poppins-medium text-[16px] text-black-three">{savings.title}</p>
            <p className="poppins-medium text-[16px] text-black-three">
               {savings.savings_type === "cashTarget"
                  ? getFormattedAmount(savings.meta.target)
                  : getFormattedDate(savings.meta.target, { year: true })}
            </p>
         </div>
      </div>
   );
}
