import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Button from "features/ui/forms/button";
import SkeletonComponent from "features/ui/feedbacks/loading-skeleton";
import useSavings from "features/savings/api/savings.api";
import SavingsCard from "features/savings/components/saving-card";
import Header from "features/ui/layout/headers/dashboard-header";
import TabSelect from "features/ui/layout/nav/tabselect";
import { Fragment } from "react";

export default function SavingsAccounts() {
   return (
      <section className="wrapper min-h-screen flex flex-col items-center justify-start w-[5/6] pb-20">
         <Header title="Accounts" variant="ACCOUNTS" />
         <div className="w-full  text-primary md:max-w-xl">
            <div className="self-stretch justify-center items-center flex flex-col gap-5">
               <Content />
            </div>
         </div>
      </section>
   );
}

const Content = () => {
   const { pathname } = useLocation();
   const [query] = useSearchParams();
   const savingsType = query.get("type");

   const tabSelectOptions = [
      {
         title: "Sub Accounts",
         href: "/dashboard/bill-accounts",
         isActive: pathname.includes("bill-accounts"),
      },
      {
         title: "Savings",
         href: "/dashboard/savings-accounts",
         isActive: pathname.includes("savings-accounts"),
      },
   ];

   if (["cashTarget", "dateTarget"].includes(savingsType)) {
      return <SavingsList savingsType={savingsType} />;
   }

   return (
      <Fragment>
         <TabSelect options={tabSelectOptions} />
         <SavingsOption
            title="Cash Stash"
            message="Save a percentage on every transfer made to your account"
            href="?type=cashTarget"
            isFixed={false}
            color={"LIGHTBLUE"}
         />
         <SavingsOption
            title="Fixed Savings"
            message="Deposit and secure your funds for a specified period of time"
            href="?type=dateTarget"
            isFixed={true}
            color={"BLUE"}
         />
      </Fragment>
   );
};

const SavingsList = ({ savingsType }) => {
   const { cashSavings, dateSavings, getAllSavingsLoading } = useSavings();
   const savings = savingsType === "cashTarget" ? cashSavings : dateSavings;
   const title = savingsType === "cashTarget" ? "Cash Stash" : "Fixed Account";
   const message =
      savingsType === "cashTarget"
         ? "Automatically saving a percentage on all incoming transfers into your accounts"
         : "Lock your money for a specified period and earn interest";

   return (
      <Fragment>
         <div className="flex flex-col gap-3 items-center md:w-[100%] w-[80vw]">
            <div className="flex flex-col mb-8">
               <h2 className="text-black-one text-2xl font-bold">{title}</h2>
               <p className="text-black-three">{message}</p>
               <div className="mt-10 col-span-2 block self-end">
                  <Button to={`create?type=${savingsType}`}>Create Savings</Button>
               </div>
            </div>
            <hr className="w-screen h-[2px] bg-black-three" />
            {getAllSavingsLoading && <SkeletonComponent />}
            {savings?.map((s) => (
               <SavingsCard key={s._id} savings={s} />
            ))}
         </div>
      </Fragment>
   );
};

const SavingsOption = ({ title, message, href, isFixed, color }) => {
   return (
      <div
         className={`${
            isFixed ? "bg-mainGrey-one text-black-one shadow" : "bg-accent-one text-mainGrey-one"
         } flex flex-col items-start shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 justify-between h-[200px] md:w-[100%] w-[80%] px-[20px] py-[20px] rounded-[10px]`}
      >
         <div className="flex flex-col">
            <h2 className="font-semibold md:text-3xl text-2xl">{title}</h2>
            <p>{message}</p>
         </div>
         <Button color={color} href={href}>
            Create
         </Button>
      </div>
   );
};
