import Button from "features/ui/forms/button";
import ModalContainer from "./modal-container.component";
import { useGlobalModalContext } from "./global-modal.context";
import CustomErrorIcon from "assets/custom-icons/error";

export default function ErrorModal({
   src,
   title = "something went wrong",
   btnText = "try again",
   to,
   href,
   btnOnClick,
   isOpen,
   onClose,
   hideOnBlur,
   modalPosition,
   message = `this process could not be completed. 
   Please try again`,
}) {
   const { hideModal } = useGlobalModalContext();
   const handleClick = btnOnClick ? btnOnClick : hideModal;

   return (
      <ModalContainer
         isOpen={isOpen}
         size="THIN"
         onClose={onClose}
         showCloseBtn={false}
         hideOnBlur={hideOnBlur}
         modalPosition={modalPosition}
      >
         <div className="container h-screen justify-center md:h-auto md:py-24">
            <CustomErrorIcon className={"mx-auto mb-4 w-[40px]"} color={"#dc2626"} />

            <h1 className="text-2xl font-bold capitalize text-secondary-darkest">{title}</h1>

            <p className="mt-1 whitespace-pre-wrap text-center text-secondary-darkest md:text-sm">
               {message}
            </p>

            <div className="mt-14">
               <Button
                  to={to}
                  href={href}
                  color="RED"
                  size="WIDE"
                  onClick={!to ? handleClick : undefined}
               >
                  <span className="px-10">{btnText}</span>
               </Button>
            </div>
         </div>
      </ModalContainer>
   );
}
