export default function CustomDeleteIcon({ className, color }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.57 87.84" className={className}>
         <defs>
            <style>{`.cls-delete{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-delete"
                  d="M54,87.84H22.13c-9.14,0-16.58-8.52-16.58-19V30.63a4.5,4.5,0,0,1,9,0V68.85c0,5.42,3.47,10,7.58,10H54c4.1,0,7.57-4.57,7.57-10V30.63a4.5,4.5,0,1,1,9,0V68.85C70.55,79.32,63.12,87.84,54,87.84Z"
               />
               <path
                  className="cls-delete"
                  d="M74.07,23.37H4.5a4.5,4.5,0,0,1,0-9H74.07a4.5,4.5,0,0,1,0,9Z"
               />
               <path
                  className="cls-delete"
                  d="M60.84,17.22h-9v-7A1.25,1.25,0,0,0,50.59,9H28a1.25,1.25,0,0,0-1.24,1.25v7h-9v-7A10.26,10.26,0,0,1,28,0H50.59A10.26,10.26,0,0,1,60.84,10.25Z"
               />
               <path
                  className="cls-delete"
                  d="M30.54,70.56a4.5,4.5,0,0,1-4.5-4.5V36.25a4.5,4.5,0,0,1,9,0V66.06A4.49,4.49,0,0,1,30.54,70.56Z"
               />
               <path
                  className="cls-delete"
                  d="M45.47,70.56a4.5,4.5,0,0,1-4.5-4.5V36.25a4.5,4.5,0,0,1,9,0V66.06A4.49,4.49,0,0,1,45.47,70.56Z"
               />
            </g>
         </g>
      </svg>
   );
}
