import TableRow from "features/ui/tables/table-row";
import TableCol from "features/ui/tables/table-col";
import { BsFilter } from "react-icons/bs";

export default function TableHeader({
   gridTemplateCols = "grid-cols-4",
   toggleModal,
   firstCol,
   otherCols,
   lastCol,
}) {
   return (
      <div className="text-slate-400 text-sm font-medium mb-6 w-full mx-auto max-w-screen-md md:text-base">
         <TableRow gridTemplateCols={gridTemplateCols} rowTypeHeader={true}>
            <TableCol className="cursor-pointer">
               <BsFilter fontSize={25} onClick={toggleModal} />
               <p className="ml-2">{firstCol}</p>
            </TableCol>
            {otherCols.map((col, i) => (
               <TableCol key={i} className="justify-center">
                  {col}
               </TableCol>
            ))}
            <TableCol className="justify-end mr-3">{lastCol}</TableCol>
         </TableRow>
      </div>
   );
}
