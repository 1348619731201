import { Link } from "react-router-dom";

import { getFormattedAmount } from "utils/lib/number-formatter";

import AccountIcon from "../../../assets/icons/LedgaV2 Icons/folder-gray.png";

export default function BillCard({ bill, enableLink = true }) {
   return (
      <div className="group bg-white flex flex-row justify-start px-5 md:px-10 gap-4 items-center text-black-three relative shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 rounded-xl w-full md:py-[10px] max-w-full md:max-w-full">
         {enableLink ? (
            <Link
               to={`/dashboard/bill-accounts/${bill.unique_id}`}
               className="after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0"
            ></Link>
         ) : null}
         <img src={AccountIcon} alt="icon" className="text-black-one h-[40px]" />
         <div className="h-[90px] w-[2px] bg-black-two"></div>
         <div className="text-start capitalize text-base p-5 font-semibold md:text-lg md:py-6">
            <p className="text-black-one poppins-bold text-[20px]">
               <span className="text-[16px] poppins-medium">₦</span>
               {getFormattedAmount(bill.meta.balance).slice(1)}
            </p>
            <p className="font-normal poppins-medium text-[16px]">{bill.title}</p>
            <p className="font-normal poppins-medium text-[16px]">{bill.unique_id}</p>
         </div>
      </div>
   );
}
