import { useState } from "react";

import { deleteMember } from "../users.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { useNavigate } from "react-router-dom";
import useRequest from "utils/hooks/useRequest";
import useMembers from "./get-members.api";

export default function useDeleteMember(member) {
   const navigate = useNavigate();
   const { mutateMembers } = useMembers();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const [deleteLoading, setDeleteLoading] = useState(false);
   const { queryFn: deleteMemberFn } = useRequest(deleteMember);

   const handleDelete = () => {
      setDeleteLoading(true);
      const queryObj = { id: member._id };

      deleteMemberFn(queryObj, {
         onSuccess: (data) => {
            mutateMembers();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "member deleted successfully",
               message: data.message,
               btnText: "continue",
               size: "THIN",
               modalPosition: "CENTER",
               btnOnClick: () => navigate("/dashboard/members"),
               onClose: () => navigate("/dashboard/members"),
            });
         },
         onError,
      });
   };

   const onError = (message = "member deletion could not be completed. Please try again") => {
      showModal(MODAL_TYPES.ERROR, {
         message: message,
         modalPosition: "CENTER",
      });
   };

   const onDeleteClick = () => {
      showModal(MODAL_TYPES.DELETE, {
         name: member.title,
         title: "Delete Member",
         message: (
            <p>
               Are you sure you want to delete <span className="font-semibold">{member.name}</span>
            </p>
         ),
         modalPosition: "CENTER",
         onClose: hideModal,
         deleteLoading,
         handleDelete,
      });
   };

   return { onDeleteClick };
}
