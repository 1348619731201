export default function Checkbox({
   variant = "PRIMARY",
   value,
   checked,
   label,
   handleChange,
   ...rest
}) {
   if (variant === "SECONDARY") {
      return (
         <label className="text-base w-full cursor-pointer justify-between gap-[.5em] flex">
            <span>{label}</span>
            <input
               type="checkbox"
               name="checkbox"
               value={value}
               checked={checked || value}
               className="
                  cursor-pointer appearance-none m-0 h-6 w-14  border-2 border-gray-200 bg-gray-200 checked:bg-primary  checked:border-primary rounded-[1em] translate-y-[0.075em] grid place-content-center
                  before:content-[''] before:w-5 before:h-5 before:rounded-full  before:bg-white  before:-translate-x-[80%]
                  checked:before:translate-x-[80%]
               "
               {...rest}
               onChange={handleChange}
            />
         </label>
      );
   }

   return (
      <label className="text-base grid grid-cols-[1em_auto] gap-[.5em]">
         <input
            type="checkbox"
            name="checkbox"
            value={value}
            checked={value}
            className="
               appearance-none bg-white m-0 h-5 w-5 border-2 border-primary rounded-[.15em] translate-y-[0.075em] grid place-content-center
               before:content-[''] before:w-3 before:h-3 before:scale-0  before:shadow-[inset_1em_1em] before:bg-[CanvasText] before:clip-checkmark
               checked:before:scale-100
            "
            {...rest}
            onChange={handleChange}
         />
         {label}
      </label>
   );
}
