import { useState, useEffect, useRef, useCallback } from "react";

export default function useCountdownTimer(futureDate) {
   const intervalRef = useRef(null);
   const [timer, setTimer] = useState("00:00:00");
   const [expired, setExpired] = useState(false);
   const resetExpired = () => setExpired(false);

   const updateTime = (timeRemaining) => {
      const diff = timeRemaining - Date.parse(new Date());
      const seconds = Math.floor((diff / 1000) % 60);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const hours = Math.floor((diff / 1000 / 60 / 60) % 24);

      if (diff >= 0) {
         setTimer(
            (hours > 9 ? hours : "0" + hours) +
               ":" +
               (minutes > 9 ? minutes : "0" + minutes) +
               ":" +
               (seconds > 9 ? seconds : "0" + seconds)
         );
      } else {
         setExpired(true);
         clearInterval(intervalRef.current);
      }
   };

   /*memoize function so it can be used as dependency in useEffect*/
   const startTimer = useCallback((target) => {
      /* if interval already exists, clear it and start a new one */
      if (intervalRef.current) {
         clearInterval(intervalRef.current);
      }

      // show reset value before timer starts
      setTimer("00:00:00");

      const id = setInterval(() => {
         updateTime(target);
      }, 1000);
      intervalRef.current = id;
   }, []);

   /*
    *timer can be called in a conditionally rendered component.
    *only start timer when value of futureDate isn't falsy.
    */
   useEffect(() => {
      if (futureDate < new Date().getTime()) {
         setExpired(true);
      } else if (futureDate) {
         startTimer(futureDate);
      }
      return () => clearInterval(intervalRef.current);
   }, [futureDate, startTimer]);

   return { timer, expired, resetExpired };
}
