import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import Select from "features/ui/forms/select";
import FormGroup from "features/ui/forms/form-group";
import SearchInput from "features/ui/forms/search-input";
// import { useWatch } from "react-hook-form";

import { organizationDetailsSchema } from "./setup.validation";
import { accountOptions } from "./setup.utils";
import { handleSearchInputSelect } from "utils/lib/onchange-handlers";
import { institutionOptions, federalStatesOptions } from "./setup.utils";
import NumberFormat from "react-number-format";
import { getSetupDateFormat } from "utils/lib/number-formatter";

export default function OrganizationDetailsForm({ handleNext, organizationDetails }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         organization_name: "",
         institution_type: "",
         state: "",
         street: "",
         phone: "",
         date_of_birth: "",
         account_type: "",
      },
   };

   const handleSubmit = (data) => {
      const corporate_account = data.account_type === "corporate";
      delete data.account_type;
      const formattedDate = getSetupDateFormat(data.date_of_birth);
      handleNext({ ...data, corporate_account, date_of_birth: formattedDate });
   };

   return (
      <Form
         id="organizationDetailsForm"
         options={formOptions}
         schema={organizationDetailsSchema}
         onSubmit={(data) => handleSubmit(data)}
      >
         {(methods) => <FormDetails {...methods} organizationDetails={organizationDetails} />}
      </Form>
   );
}

export const FormDetails = ({
   register,
   getValues,
   setValue,
   clearErrors,
   control,
   Controller,
   watch,
   formState: { errors, dirtyFields },
}) => {
   // const accountType = useWatch({ name: "account_type", control });

   return (
      <FormGroup className="self-stretch mb-10">
         <Input
            label="name of organization"
            error={errors.organization_name}
            isEmpty={!dirtyFields.organization_name}
            registration={register("organization_name")}
            variant="PRIMARYV3"
         />

         <FormGroup className="mt-6">
            <Controller
               control={control}
               name="institution_type"
               defaultValue=""
               render={({ field: { onChange, name, value } }) => (
                  <Select
                     value={value}
                     name={name}
                     label="Type of Organization"
                     error={errors.institution_type}
                     options={institutionOptions}
                     handleChange={(value) => {
                        onChange(value);
                     }}
                  />
               )}
            />
         </FormGroup>

         <Input
            label="address"
            error={errors.street}
            isEmpty={!dirtyFields.street}
            registration={register("street")}
            variant="PRIMARYV3"
         />

         <div className="flex justify-between">
            <FormGroup className="w-7/12">
               <Input value="Nigeria" readOnly variant="PRIMARYV3" />
            </FormGroup>

            <FormGroup className="w-2/5">
               <SearchInput
                  error={errors.state}
                  value={watch("state")}
                  name="state"
                  label="state"
                  options={federalStatesOptions}
                  registration={register("state")}
                  isEmpty={!dirtyFields.state}
                  handleSelect={(value) =>
                     handleSearchInputSelect("state", value, setValue, clearErrors)
                  }
                  variant="PRIMARYV3"
               />
            </FormGroup>
         </div>

         <div className="flex justify-between items-center">
            <FormGroup className="w-1/4 mr-1 self-start">
               <Input value="+234" readOnly variant="PRIMARYV3" />
            </FormGroup>
            <FormGroup className="w-3/4 ml-1">
               <Input
                  label="phone number"
                  error={errors.phone}
                  isEmpty={!dirtyFields.phone}
                  registration={register("phone")}
                  variant="PRIMARYV3"
               />
            </FormGroup>
         </div>

         <div className="flex gap-2 items-center justify-between">
            <FormGroup className="mt-6">
               <Select
                  error={errors.account_type}
                  value={getValues("account_type")}
                  name="account_type"
                  label="account type"
                  options={accountOptions}
                  handleChange={(value) => {
                     setValue("account_type", value);
                     clearErrors("account_type");
                  }}
               />
            </FormGroup>
            {/* {accountType === "individual" ? ( */}
            <Controller
               control={control}
               name="date_of_birth"
               defaultValue=""
               render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                     customInput={Input}
                     isEmpty={value.length < 1}
                     format="##-##-####"
                     mask={["d", "d", "m", "m", "y", "y", "y", "y"]}
                     placeholder="dd-mm-yyyy"
                     error={errors.date_of_birth}
                     label="date of birth"
                     name={name}
                     value={value}
                     onValueChange={(v) => {
                        onChange(v.formattedValue);
                     }}
                     variant="PRIMARYV3"
                  />
               )}
            />
            {/* ) : null} */}
         </div>

         <div className="flex justify-center mt-6">
            <Button variant="PRIMARYV2" size="WIDE" type="submit" form="organizationDetailsForm">
               Go Next
            </Button>
         </div>
      </FormGroup>
   );
};
