import useSWR from "swr";
import { getAllSubAdminsUrl } from "../subadmins.service";

export default function useSubAdmins() {
   const {
      data: subadmins,
      error: getAllSubAdminsError,
      mutate: mutateSubAdmins,
   } = useSWR([getAllSubAdminsUrl, "", "V2"]);

   const getAllSubAdminsLoading = !subadmins && !getAllSubAdminsError;

   return { subadmins, getAllSubAdminsLoading, mutateSubAdmins };
}
