import { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { decodeJWT } from "./auth.utils";
import { getToken } from "utils/lib/storage";
import { signOut } from "features/auth/auth.service";
import { getAdminUrl } from "features/auth/auth.service";
import { getAdminLedgerBalanceUrl } from "features/auth/auth.service";

const imageDefault = "https://unsplash.com/photos/silhouette-of-man-illustration-2LowviVHZ-E";

//adminContext is created and consumed once here in useAdmin
const adminContext = createContext();
export const useAdmin = () => useContext(adminContext);

export const AdminContextProvider = () => {
   const navigate = useNavigate();
   const [adminId, setAdminId] = useState("");
   const [adminMongoId, setAdminMongoId] = useState("");
   const [adminProfile, setAdminProfile] = useState("");
   const [ledgerBal, setLedgerBal] = useState("");
   const [adminBankDetails, setAdminBankDetails] = useState({});
   const [institutionTypes, setInstitutionTypes] = useState([]);
   const [adminPermissions, setAdminPermissions] = useState({});
   const [tokenIsValid, setTokenIsValid] = useState(false);

   useEffect(() => {
      const token = getToken();
      if (!token) {
         navigate("/signin", { replace: true });
      } else {
         const decoded = decodeJWT(token);

         if (decoded.exp * 1000 < Date.now()) {
            signOut();
         } else {
            setTokenIsValid(true);
         }
      }
      // eslint-disable-next-line.
   }, [navigate]);

   const {
      data: admin,
      error: adminError,
      mutate: mutateAdmin,
   } = useSWRImmutable(tokenIsValid ? getAdminUrl : null);

   const {
      data: ledgerData,
      error: ledgerDataError,
      mutate: mutateLedgerData,
   } = useSWRImmutable(admin ? getAdminLedgerBalanceUrl : null);

   const getAdminError = adminError || ledgerDataError;
   const getAdminLoading = !admin && !ledgerData && !getAdminError && !ledgerDataError;

   useEffect(() => {
      if (admin) {
         if (!admin.unique_id) {
            navigate("../setup", { replace: true });
         } else if (!admin.meta?.hasSetTransactionPin) {
            navigate("../create-pin", { replace: true });
         } else {
            const {
               bank_name: bankName,
               bank_code: bankCode,
               account_name: accountName,
               account_number: accountNumber,
            } = admin.bank_details;

            setAdminId(admin.unique_id);
            setAdminMongoId(admin._id);
            setAdminProfile(admin?.profile_image ? admin.profile_image : imageDefault);
            setInstitutionTypes(admin.organization_details.institutionTypes);
            setAdminBankDetails({ bankName, accountName, accountNumber, bankCode });
            setAdminPermissions({
               ...admin.permissions,
               owner: admin._id.toString() === admin.organization_id.toString() ? true : false,
               commission: admin?.commission ?? false,
            });
         }
      }
   }, [admin, navigate]);

   useEffect(() => {
      if (ledgerData) {
         setLedgerBal(ledgerData.TotalBillBalance);
      }
   }, [ledgerData]);

   return (
      <adminContext.Provider
         value={{
            admin,
            adminId,
            adminMongoId,
            adminBankDetails,
            institutionTypes,
            adminPermissions,
            adminProfile,
            ledgerData,
            ledgerBal,
            getAdminError,
            getAdminLoading,
            mutateAdmin,
            mutateLedgerData,
            pendingFunding: {
               reference: admin?.meta?.funding?.pending_transaction_reference,
               expiration: admin?.meta?.funding?.expiration_time,
            },
         }}
      >
         <Outlet />
      </adminContext.Provider>
   );
};
