import { useNavigate, useParams } from "react-router-dom";

import Transfer from "features/add-users/components/transfer";
import useSingleMerchant from "features/add-users/api/merchant-single.api";

export default function MerchantTransfer() {
   const { id: merchantIds } = useParams();
   const navigate = useNavigate();
   const [mongoId, uniqueId] = merchantIds.split("-");
   const { mutateMerchant } = useSingleMerchant(mongoId);
   const onClose = () => navigate(`/dashboard/merchants/${mongoId}`);

   return (
      <Transfer
         mongoId={mongoId}
         uniqueId={uniqueId}
         mutateUserFn={mutateMerchant}
         userType="merchant"
         onClose={onClose}
      />
   );
}
