import { axios, axiosClient } from "utils/lib/axios";

export const completeSignup = async (queryObj) => {
   return axios.post("admin/signup-complete", queryObj);
};

export const verifyCacNumber = async (queryObj) => {
   return axiosClient.post("admin/verify-cac", queryObj);
};

export const completeConfig = async (queryObj) => {
   return axiosClient.post("admin/config", queryObj);
};
