import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import WithdrawModals from "features/withdraw/withdraw-modal";
import { withdrawFromBill, withdrawFromMain } from "features/withdraw/withdraw.service";
import { withdrawFromSavings } from "features/withdraw/withdraw.service";

import { useAdmin } from "features/auth/admin-context";

import useSingleSavings from "features/savings/api/single-savings.api";
import useSingleBill from "features/bills/api/bill.api";

export default function WithdrawDetails() {
   const { source, destination } = useParams();
   const [query] = useSearchParams();
   const sourceId = query.get("sourceId");
   const destinationId = query.get("destinationId");

   const { adminBankDetails: bankDetails, ledgerData, mutateLedgerData } = useAdmin();

   switch (source) {
      case "bill": {
         return <BillWithdraw id={sourceId} to={destination} mutateLedgerData={mutateLedgerData} />;
      }

      case "savings": {
         return (
            <SavingsWithdraw
               id={sourceId}
               to={destination}
               bankDetails={bankDetails}
               mutateLedgerData={mutateLedgerData}
            />
         );
      }

      default: {
         return (
            <MainWithdraw
               id={sourceId}
               bankDetails={bankDetails}
               ledgerData={ledgerData}
               to={destination}
               toId={destinationId}
               mutateLedgerData={mutateLedgerData}
            />
         );
      }
   }
}

const BillWithdraw = ({ id, to, mutateLedgerData }) => {
   const navigate = useNavigate();
   const [withdrawModal, setWithdrawModal] = useState(true);
   const { billInfo, mutateBill } = useSingleBill(id);
   if (!billInfo) return null;

   const mutateFn = () => {
      mutateBill();
      mutateLedgerData();
   };

   const handleFinish = () => {
      navigate(`/dashboard`);
   };

   return (
      <WithdrawModals
         to={to}
         id={id}
         withdrawService={withdrawFromBill}
         modalDisplay={withdrawModal}
         setModalDisplay={setWithdrawModal}
         bankDetails={billInfo.payoutAccountDetails}
         modalPosition="CENTER"
         balance={billInfo?.meta?.balance}
         mutateFn={mutateFn}
         onClose={handleFinish}
         changePayoutLink={`/dashboard/bill-accounts/${billInfo.unique_id}/edit`}
      />
   );
};

const SavingsWithdraw = ({ id, bankDetails, to, mutateLedgerData }) => {
   const navigate = useNavigate();
   const [withdrawModal, setWithdrawModal] = useState(true);
   const { savingsInfo, mutateSavings } = useSingleSavings(id);

   const mutateFn = () => {
      mutateSavings();
      mutateLedgerData();
   };

   const handleFinish = () => {
      navigate(`/dashboard`);
   };

   if (!savingsInfo) return null;
   return (
      <WithdrawModals
         to={to}
         id={id}
         withdrawService={withdrawFromSavings}
         modalDisplay={withdrawModal}
         setModalDisplay={setWithdrawModal}
         bankDetails={bankDetails}
         mutateFn={mutateFn}
         onClose={handleFinish}
         modalPosition="CENTER"
         defaultAmountValue={savingsInfo?.meta?.balance}
         disabledAmountInput={true}
         balance={savingsInfo?.meta?.balance}
      />
   );
};

const MainWithdraw = ({ id, bankDetails, ledgerData, to, toId, mutateLedgerData }) => {
   const navigate = useNavigate();
   const [withdrawModal, setWithdrawModal] = useState(true);
   const handleFinish = () => {
      navigate(`/dashboard`);
   };

   if (!ledgerData) return null;
   return (
      <WithdrawModals
         to={to}
         id={id}
         toId={toId}
         withdrawService={withdrawFromMain}
         modalDisplay={withdrawModal}
         setModalDisplay={setWithdrawModal}
         bankDetails={bankDetails}
         mutateFn={mutateLedgerData}
         onClose={handleFinish}
         modalPosition="CENTER"
         balance={ledgerData?.MainAccountBalance}
      />
   );
};
