// takes users name and reduce the char count through
// abbreviation.

export const getShortendName = (name) => {
   let lowerCaseName = name?.toLowerCase();

   if (!lowerCaseName) {
      return;
   } else if (lowerCaseName.length > 24) {
      // destructure directly from the array resulting from split
      const [firstName, secondName, thirdName] = lowerCaseName.split(" ");

      const formats = {
         firstMLast: `${firstName} ${secondName[0]}. ${thirdName}`,
         firstML: `${firstName} ${secondName[0]}. ${thirdName[0]}.`,
      };

      // check if abbreviating the secondName can bring the char count
      // down to 20 or below. otherwise abbreviate everything but the firstName
      return formats.firstMLast.length <= 24
         ? formats.firstMLast
         : formats.firstML;
   } else {
      return lowerCaseName;
   }
};

// returns a nested array of receipt rows
export const getUserDetails = (userObj, name) =>
   userObj
      ? [
           [
              {
                 content: name,
                 colSpan: 2,
                 styles: {
                    cellPadding: { top: 10, right: 0, bottom: 3, left: 0 },
                 },
              },
           ],
           userObj.id && ["Unique Id:", userObj.id],
           userObj.uniqueId && ["Unique Id:", userObj.uniqueId],
           userObj.name && ["Name:", userObj.name],
           userObj.accountNumber && ["Account Number:", userObj.accountNumber],
           userObj.bankName && ["Bank Name:", userObj.bankName],
        ].filter(Boolean)
      : [];
