export const getFormattedDate = (createdAt, options = { year: false }) => {
   const date = new Date(createdAt);

   return Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      ...(options.year ? { year: "numeric" } : {}),
   }).format(date);
};

export const getFormattedTime = (createdAt) => {
   const date = new Date(createdAt);

   return Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
   }).format(date);
};

export const getFormattedAmount = (amount) => {
   return Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      maximumFractionDigits: 0,
   }).format(amount);
};

export const getIsoCurrencyFormat = (value) => "NGN " + new Intl.NumberFormat().format(value);

/*Iso 4217 currency code format is used in cases where utf-8 
is not supported e.g. in jspdf receipts */

export const getFormattedPercentage = (value) => {
   const newValue = Number.isNaN(value) ? 0 : value;

   return Intl.NumberFormat("en-GB", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
   }).format(newValue);
};

/*this converts date in the format 12-04-2014 to 12 April 2014 */
export const getSetupDateFormat = (inputDate) => {
   if (!inputDate) {
      return "";
   }

   const [day, month, year] = inputDate.split("-");
   const dateObject = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
   const options = { year: "numeric", month: "long", day: "numeric" };
   const formattedDate = dateObject.toLocaleDateString("en-GB", options);
   return formattedDate;
};

export const getFormattedThousand = (number) => {
   return Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
   }).format(number);
};
