import * as yup from "yup";
import FormGroup from "features/ui/forms/form-group";

import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormError from "features/ui/forms/form-error";

const schema = yup.object().shape({
   password: yup
      .string()
      .required("password cannot be empty")
      .matches(/.{8,}/, "password cannot be less than 8 characters")
      .matches(/[0-9]/, "password must contain at least one digit")
      .matches(/[a-z]/, "password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "password must contain at least one uppercase letter"),
   passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "password does not match"),
});

const ErrorComponent = ({ message }) => {
   const errorClass = `whitespace-pre-wrap text-xs pl-1 text-red-500 font-bolder -mt-5 mb-5`;
   return (
      <p className={message && errorClass} role="alert" aria-label={message}>
         {message}
      </p>
   );
};

export default function ResetForm({
   loading,
   handleSubmit,
   error,
   resetError,
}) {
   const hookFormOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         password: "",
         passwordConfirmation: "",
      },
   };

   return (
      <div className="self-stretch">
         <FormError formError={error} />
         <Form
            options={hookFormOptions}
            schema={schema}
            onSubmit={handleSubmit}
            formError={error}
            resetFormError={resetError}
            autoComplete="new-password"
         >
            {({ register, getValues, formState: { errors, dirtyFields } }) => (
               <>
                  <input type="hidden" value="something" />
                  <FormGroup>
                     <Input
                        type="password"
                        label="password"
                        isEmpty={!dirtyFields.password}
                        registration={register("password")}
                     />
                     <div>
                        {errors?.password?.type === "required" ? (
                           <ErrorComponent
                              message={errors?.password?.message}
                           />
                        ) : (
                           []
                              .concat(errors?.password?.types?.matches)
                              .map((errorMessage, i) => (
                                 <ErrorComponent
                                    key={"errorMessage" + i}
                                    message={errorMessage}
                                 />
                              ))
                        )}
                        {/*concat into an empty array because error.matches can
                              either return a string or an array */}
                     </div>
                  </FormGroup>

                  <FormGroup className="mb-14">
                     <Input
                        type="password"
                        label="password confirmation"
                        isEmpty={!dirtyFields?.passwordConfirmation}
                        registration={register("passwordConfirmation")}
                     />
                     {getValues("password") !==
                        getValues("passwordConfirmation") && (
                        <ErrorComponent
                           message={errors?.passwordConfirmation?.message}
                        />
                     )}
                  </FormGroup>
                  <div className="flex justify-center">
                     <Button disabled={loading} isLoading={loading} size="WIDE">
                        reset password
                     </Button>
                  </div>
               </>
            )}
         </Form>
      </div>
   );
}
