import Spinner from "features/ui/feedbacks/spinner";
import FailModal from "features/ui/feedbacks/failure-modal";
import TransactionsRow from "./newmonth";

export default function TransactionsTable({
   toggleModal,
   transactionsData,
   transactionsError,
   transactionsLoading,
   refetchTransactions,
}) {
   return (
      <div className="mx-auto px-[20px] sm:max-w-lg sm:text-lg md:max-w-3xl md:text-xl md:mt-6 py-4">
         {/* <TableHeader
            toggleModal={toggleModal}
            gridTemplateCols="grid-cols-custom-table-1"
            firstCol={"type"}
            otherCols={["date", "all"]}
            lastCol={"amount"}
         /> */}
         {transactionsError && !transactionsLoading ? (
            <FailModal modalDisplay={true} btnOnClick={refetchTransactions} />
         ) : transactionsLoading ? (
            <div className="my-32 flex items-center justify-center">
               <Spinner color="GREY" size="MEDIUM" />
            </div>
         ) : (
            Object.keys(transactionsData).map((day) => {
               return (
                  <section className="mb-10" key={day}>
                     <h2 className="text-[15px] md:text-[20px] mb-[10px] font-semibold text-secondary-darker sm:pl-1 ">
                        {day}
                     </h2>
                     <div className="bg-white px-4 shadow-faintShadow-one shadow-sm rounded-[10px]">
                        {transactionsData[day].map((daily, index) => (
                           <TransactionsRow key={index} day={daily} />
                        ))}
                     </div>
                  </section>
               );
            })
         )}
      </div>
   );
}
