import { updateSubAmdin } from "../subadmins.service";
import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import useSingleSubAdmin from "./subamin-single.api";
import { useNavigate } from "react-router-dom";

export default function useUpdateSubAdmin(subadminId) {
   const navigate = useNavigate();
   const { queryFn: updateSubAmdinFn } = useRequest(updateSubAmdin, subadminId);
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { mutateSubAdmin } = useSingleSubAdmin(subadminId);

   const handleSubAdminUpdate = (queryObj) => {
      showModal(MODAL_TYPES.LOADING, {
         modalPosition: "CENTER",
      });

      updateSubAmdinFn(queryObj, {
         onSuccess: () => {
            mutateSubAdmin();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "success",
               modalPosition: "CENTER",
               message: "sub admin updated successfully",
               btnText: "ok",
               btnOnClick: () => {
                  hideModal();
                  navigate("/dashboard/subadmins");
               },
            });
         },
         onError: () => {
            showModal(MODAL_TYPES.ERROR, {
               message: "sub amdin could not be updated, please try again",
            });
         },
      });
   };

   return { handleSubAdminUpdate };
}
