import { useState } from "react";
import Papa from "papaparse";
import { useForm, Controller } from "react-hook-form";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { BsFillFileEarmarkCheckFill } from "react-icons/bs";

import Alert from "features/ui/feedbacks/alert";
import Button from "features/ui/forms/button";
import { fieldArrayValidator } from "../users.utils";
import KeysToLowerCase from "utils/lib/keys-to-lowercase";

export default function AddUsersSpreadsheet({ usersType, createUsers }) {
   const [fileUploaded, setFileUploaded] = useState();

   const {
      control,
      reset,
      handleSubmit,
      formState: { errors },
   } = useForm({
      mode: "onTouched",
      criteriaMode: "all",
   });

   const handleParse = (spreadsheet, onChangeFn) => {
      Papa.parse(spreadsheet, {
         header: true,
         skipEmptyLines: true,
         complete: (data) => {
            onChangeFn(fieldArrayValidator(KeysToLowerCase(data.data)));
         },
      });
   };

   const handleFileSubmit = (data) => {
      setFileUploaded(false);
      createUsers(data, reset);
   };

   //styles
   const title = `self-start capitalize pt-10 mb-6 text-2xl md:pt-16 md:text-3xl md:text-slate-500`;
   const icon = "w-28 h-32 absolute top-0 bottom-0 left-0 right-0";

   return (
      <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px]">
         <h1 className={title}>Upload {usersType}</h1>
         <div className="self-start w-full">
            <h2 className="sm:text-xl md:text-2xl"> New {usersType}</h2>
            <p>Add New {usersType} from csv file</p>
         </div>

         <form
            id="spreadsheet-upload"
            onSubmit={handleSubmit(handleFileSubmit)}
            className="self-stretch"
         >
            {/* {usersType === "students" && (
               <ClassForm
                  errors={errors}
                  dirtyFields={dirtyFields}
                  Controller={Controller}
                  control={control}
               />
            )} */}

            <div className="flex flex-col justify-center items-center border-2 border-secondary-lighter p-6 mb-6">
               <label className=" relative w-28 h-32 cursor-pointer">
                  <Controller
                     control={control}
                     name={usersType}
                     defaultValue=""
                     rules={{
                        required: {
                           value: true,
                           message: "please provide a valid file in CSV format ",
                        },
                     }}
                     render={({ field: { onChange } }) => (
                        <input
                           type="file"
                           accept=".csv"
                           className="w-full opacity-0"
                           onChange={(e) => {
                              const spreadsheet = e.target.files[0];
                              setFileUploaded(true);
                              handleParse(spreadsheet, onChange);
                           }}
                        />
                     )}
                  />
                  {fileUploaded ? (
                     <BsFillFileEarmarkCheckFill className={icon} />
                  ) : (
                     <BsFillFileEarmarkPlusFill className={icon} />
                  )}
               </label>
               <p>{fileUploaded ? "change .csv file" : "select .csv file"}</p>
            </div>
            <Alert message={errors[usersType]?.message} />
         </form>
         <div className="flex justify-center">
            <Button size="WIDE" type="submit" form="spreadsheet-upload">
               upload
            </Button>
         </div>
         <hr className="w-4/5 mx-auto border border-slate-300 my-6" />
      </div>
   );
}
