import AuthLayout from "features/auth/components/layout";
import SignupForm from "features/auth/components/signup-form";

export default function Signup() {
   return (
      <AuthLayout>
         <SignupForm />
      </AuthLayout>
   );
}
