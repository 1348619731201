import { Link } from "react-router-dom";
import { useAdmin } from "features/auth/admin-context";
import { classNames } from "utils/lib/getClassName";

import CustomNotificationIcon from "assets/custom-icons/notification-icon";

const VARIANT_MAPS = {
   PRIMARY:
      "mb-2 pt-6 md:border-none bg-white  md:bg-white md:text-slate-500 md:mx-auto md:max-w-[85%] lg:max-w-screen-md",
   SECONDARY: "w-full py-10 bg-white rounded-custom-b-lr-6xl mb-4 md:rounded-2xl md:mb-0",
   DASHBOARD:
      "py-10 dark:bg-white bg-white  shadow-[#F5F5F5] shadow-[0_1px_8px] shadow-opacity-100 bg-white flex flex-col items-start  md:text-slate-500 md:py-5  ",
   TRANSACTIONS: "text-black py-[20px] px-[20px] md:px-[200px] pr-[40px] bg-black/0 md:bg-white",
   BILL: "w-full px-[20px] flex flex-col items-center justify-center items-start align-start py-10 bg-[#1358CC] h-[360px] rounded-custom-b-lr-bill mb-4 md:rounded-2xl md:mb-0",
   SAVINGS:
      "w-full px-[20px] flex flex-col items-center justify-center items-start align-start py-10 bg-none h-fit pt-[80px] rounded-custom-b-lr-bill md:rounded-2xl md:mb-0",
   SETTINGS:
      "py-10 dark:bg-white px-[20px] flex flex-col items-start rounded-lg md:px-[250px] px-[30px] md:rounded-none text-black md:py-5 mb-[30px]",
   REQUESTS:
      "py-10 px-[20px] dark:bg-white  flex flex-col items-start rounded-lg md:px-[250px] px-[30px] md:rounded-none text-black md:py-5 mb-10  ",
   ACCOUNTS:
      "py-10 w-full px-[20px] dark:bg-white  flex flex-col items-start rounded-lg md:px-[250px] px-[30px] md:rounded-none text-black md:py-5 mb-10",
   OTHERS:
      "w-full px-[20px] flex flex-col items-center justify-center items-start align-start py-10 bg-none h-fit pt-[80px] rounded-custom-b-lr-bill md:rounded-2xl md:mb-0",
};

// const variantOptions = ["TRANSACTIONS", "REQUEST", "SETTINGS", "ACCOUNTS"];

export default function Header({ variant, settingsUrl, title, children, to }) {
   const { adminId: schoolId, adminProfile } = useAdmin();
   //styles
   const headerClass = classNames(`text-white `, VARIANT_MAPS[variant]);

   const titleClass = `text-black-one  text-[24px] md:text-[40px] md:pl-0 md:border-none lg:text-4xl`;

   return (
      <header className={headerClass}>
         <div className="flex flex-row w-full  justify-between items-center self-stretch">
            {
               variant === "DASHBOARD" ? (
                  <div className="absolute top-12 flex gap-2 items-center  px-6">
                     <Link to={settingsUrl || "../settings"}>
                        <img
                           src={adminProfile}
                           alt="user profile"
                           className="rounded-[50%] w-[30px] h-[30px] border border- border-white"
                        />
                     </Link>
                     <p className="text-xs leading-[14px] text-black-one">
                        Welcome,
                        <br />
                        ORGANIZATION ID{" "}
                        <span className="block px-[2px] mt-[2px] py-[2px] rounded-sm w-fit text-white bg-blue-400  text-[10px]">
                           {schoolId}
                        </span>
                     </p>
                  </div>
               ) : null
               // !variantOptions.includes(variant) && (
               //    <ArrowBack
               //       color={variant === "SAVINGS" || variant === "OTHERS" ? "BLACK" : "WHITE"}
               //       variant="PRIMARY"
               //       to={to}
               //    />
               // )
            }

            <h1 className={title ? titleClass : "hidden"}>{title}</h1>
            {variant !== "OTHERS" && (
               <CustomNotificationIcon
                  color={`${
                     variant === "DASHBOARD" || variant === "SAVINGS" ? "#000000" : "#FFFFFF"
                  }`}
                  className={`absolute top-6 right-6 w-[20px]`}
               />
            )}
         </div>
         {children}
      </header>
   );
}
