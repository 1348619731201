import { Link } from "react-router-dom";

import useUsers from "features/add-users/api/get-users.api";
import AddMemberIcon from "assets/icons/LedgaV2 Icons/addMemberIcon";
import UsersTable from "features/add-users/components/users-table";
import Header from "features/ui/layout/headers/dashboard-header";
import { getMerchantsUrl } from "features/add-users/users.service";
import SidebarRight from "features/ui/layout/nav/sidebar-right";

export default function Merchants() {
   const {
      usersData, //
      usersDataError,
      usersDataIsLoading,
      mutateUsers,
   } = useUsers(getMerchantsUrl, "V1");

   return (
      <section className="wrapper bg-white flex flex-col px-[20px] md:px-[50px] min-h-screen">
         <Header variant="OTHERS" title="" settingsUrl="../settings" to={"../settings"} />
         <div className="w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#EDF5FC] rounded-t-xl flex flex-col items-center">
            <h2 className="self-stretch w-full mx-auto max-w-screen-md text-black-one  text-[24px] md:text-[40px] md:pl-0 lg:text-4xl mt-14">
               Merchants
            </h2>
            <UsersTable
               userType="merchants"
               usersData={usersData}
               usersDataError={usersDataError}
               usersDataIsLoading={usersDataIsLoading}
               mutateUsers={mutateUsers}
            />
         </div>
         <SidebarRight>
            <Link className="flex absolute bottom-24 w-full justify-center" to="../merchants/add">
               <AddMemberIcon className="w-14 h-14" />
            </Link>
         </SidebarRight>
      </section>
   );
}
