import { useState } from "react";
import { FaCoins } from "react-icons/fa";

import EarningsTable from "features/earnings/table";
import FilterModal from "features/ui/modals/filter-modal";
import EarningsCard from "features/earnings/card";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import BarChartComponent from "features/earnings/bar-chart";
import useEarnings from "features/earnings/earnings.api";
import { requestPersonalPayout } from "features/requests/services";
import WithdrawModals from "features/withdraw/withdraw-modal";
import Button from "features/ui/forms/button";
import { getFormattedAmount } from "utils/lib/number-formatter";
import getTablePDF from "features/ui/tables/table-pdf";

export default function Earnings() {
   const [queryParams, setQueryParams] = useState("");
   const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
   const [withdrawModal, setWithdrawModal] = useState(false);

   const handleFilter = ({ from, to }) => {
      const dateQuery = `from=${from}&to=${to}`;
      setQueryParams(`?${dateQuery}`);
   };

   //querie
   const {
      earningsData,
      todayEarnings,
      thisWeekEarnings,
      weekBarChartData,
      lifeTimeEarnings,
      availableBal,
      bankDetails,
      tableData,
   } = useEarnings(queryParams);

   //styles
   const title = `capitalize font-semibold text-xl mb-6 text-center md:self-start md:font-normal md:pt-2 md:text-3xl md:text-slate-500 `;

   return (
      <section className="wrapper">
         <div className="bg-white text-primary pt-16 min-h-full w-5/6 mx-auto flex flex-col items-center md:min-h-0 md:max-w-4xl">
            <ArrowBack to="../../dashboard" />
            <h1 className={title}>earning summary</h1>
            <FaCoins className="mb-4 md:hidden" fontSize={70} />
            <div className="flex justify-evenly items-center md:flex-col">
               <div className="table mx-auto p-4 mb-10 md:p-0 md:border-b-2 md:border-slate-400">
                  <p className="hidden md:block">Available Bal:</p>
                  <p className="text-3xl  font-semibold md:text-4xl lg:text-5xl">
                     {getFormattedAmount(availableBal || 0)}
                  </p>
                  <p className="text-xs md:hidden">available balance</p>
               </div>
               <div className="mb-10">
                  <BarChartComponent
                     className="hidden md:block"
                     weekBarChartData={weekBarChartData}
                  />
                  <EarningsCard
                     todayEarnings={todayEarnings}
                     thisWeekEarnings={thisWeekEarnings}
                     lifeTimeEarnings={lifeTimeEarnings}
                  />
               </div>
            </div>
            <EarningsTable
               earningsData={earningsData}
               className="self-stretch"
               showFilterModal={() => setFilterModalIsOpen(true)}
            />

            <div className="fixed-btn">
               <Button
                  variant="TERTIARY"
                  color="YELLOW_TEXT"
                  onClick={() =>
                     getTablePDF({
                        head: [["date", "amount"]],
                        rows: tableData,
                     })
                  }
               >
                  click to download summary
               </Button>
               <Button size="WIDE" onClick={() => setWithdrawModal(true)}>
                  withdraw
               </Button>
            </div>
            <FilterModal
               defaultValue="all"
               handleSubmit={handleFilter}
               isOpen={filterModalIsOpen}
               onClose={() => setFilterModalIsOpen((prev) => !prev)}
            />
         </div>
         <WithdrawModals
            modalDisplay={withdrawModal}
            setModalDisplay={setWithdrawModal}
            mutateFn={() => window.location.reload()}
            withdrawService={requestPersonalPayout}
            balance={availableBal}
            bankDetails={bankDetails}
         />
      </section>
   );
}
