import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { useAdmin } from "features/auth/admin-context";
import { createTransactionPin } from "./pin.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useCreatePin() {
   const navigate = useNavigate();
   const [step, setStep] = useState(0);
   const [pin, setPin] = useState("");
   const [pinConfirmation, setPinConfirmation] = useState("");

   const modalPosition = "CENTER";
   const { mutateAdmin } = useAdmin();
   const { queryFn: createPinFn } = useRequest(createTransactionPin);
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const goToHomePage = () => {
      hideModal();
      navigate("../dashboard");
   };

   const showLoadingModal = () => {
      showModal(MODAL_TYPES.LOADING, {
         modalPosition: modalPosition,
      });
   };

   //handle functions
   const handleNext = () => setStep((prev) => prev + 1);

   const handleSuccess = async () => {
      showLoadingModal();
      await mutateAdmin();
      goToHomePage();
   };

   const handleSubmit = () => {
      if (pin === pinConfirmation) {
         showLoadingModal();
         const queryObj = { pin };
         createPinFn(queryObj, {
            onSuccess: () => {
               showModal(MODAL_TYPES.SUCCESS, {
                  modalPosition: modalPosition,
                  title: "Setup complete",
                  message: `You have successfully created your ledga \n
                  Start collecting payments now`,
                  btnText: "continue",
                  btnOnClick: handleSuccess,
               });
            },
            onError: (message) => {
               if (message.includes("already created a transaction pin")) {
                  hideModal();
                  showModal(MODAL_TYPES.ERROR, {
                     modalPosition: modalPosition,
                     title: "Pin Already Created",
                     message: `${message}. Go to home page`,
                     btnText: "home",
                     hideOnBlur: false,
                     btnOnClick: goToHomePage,
                  });
               } else {
                  showModal(MODAL_TYPES.ERROR, {
                     modalPosition: modalPosition,
                     hideOnBlur: false,
                     btnOnClick: () => window.location.reload(),
                  });
               }
            },
         });
         setPin("");
         setPinConfirmation("");
      }
   };

   return {
      step,
      pin,
      pinConfirmation,
      setPin,
      setPinConfirmation,
      handleSubmit,
      handleNext,
   };
}
