import { useSWRConfig } from "swr";
import { BsQuestionCircleFill } from "react-icons/bs";

import ReceiptField from "./receipt-fieldN";
import ReceiptDownload from "./receipt-download";
import ModalContainer from "features/modal/modal-container.component";
import * as NumberFormatter from "utils/lib/number-formatter";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import UserDetailsFields from "./user-details-fields";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { IoDownload } from "react-icons/io5";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import jsPDF from "jspdf";
import CustomCreditIcon from "assets/custom-icons/credit-icon";
import CustomDebitIcon from "assets/custom-icons/debit-icon";
import CustomIntraIcon from "assets/custom-icons/intra-icon";

export default function TransactionReceipt(props) {
   const { isOpen, onClose } = props;

   const { hideModal } = useGlobalModalContext();
   const handleClose = () => {
      hideModal();
      onClose && onClose();
   };

   const ref = useRef(null);

   const handlePrint = useReactToPrint({
      content: () => ref.current,
      documentTitle: "doc.pdf",
      copyStyles: true,
      print: async (printIframe) => {
         const document = printIframe.contentDocument;
         if (document) {
            const html = document.getElementById("printing");
            get_PDF_in_base64(html);
         }
      },
   });

   return (
      <>
         <ModalContainer isOpen={isOpen} onClose={handleClose} modalPosition="CENTER" showCloseBtn>
            <ReceiptContent {...props} handleDownload={handlePrint} />

            <div ref={ref} style={{ display: "none" }} handleDownload={handlePrint}>
               <ReceiptDownload {...props} />
            </div>
         </ModalContainer>
      </>
   );
}

const ReceiptContent = (props) => {
   const {
      created, //
      amount,
      type,
      reference,
      sender,
      recipient,
      status,
      handleDownload,
   } = props;

   /*destructure from props  rather than directly so props can be passed whole into getReceipt function */
   const { cache } = useSWRConfig();
   const member = cache.get("member/account/info");
   const organization = member?.admin?.organization_details?.name;

   /*
    *member is gotten from cache because the global modal which
    *is responsible for rendering the receipt in most cases wraps
    *auth-context and as a result does not have access to useStudent()
    *exported from authContext.
    */

   const formattedDate = NumberFormatter.getFormattedDate(created);
   const formattedTime = NumberFormatter.getFormattedTime(created);
   const formattedAmount = NumberFormatter.getFormattedAmount(amount);

   return (
      <>
         <style jsx>{`
            ::-webkit-scrollbar {
               width: 8px;
               height: 8px;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-track {
               background: inherit;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 5px;
            }

            ::-webkit-scrollbar-thumb:hover {
               background: #555;
            }
         `}</style>
         <section className="mx-auto flex  w-full items-center justify-center max-w-[99%] py-2">
            <div className="max-h-[100vh] md:max-h-[90vh] overflow-y-scroll mx-auto w-full py-16">
               <div className="mx-auto max-w-[500px] px-12">
                  <h2 className="poppins-semibold text-[24px] text-black-one">
                     Transaction Details
                  </h2>

                  <div className="mb-[20px] flex w-full flex-col items-center rounded-[5px] bg-white px-[20px] py-[20px] shadow-lg shadow-[#F5F5F5]">
                     {type === "CREDIT" && (
                        <div
                           className={`flex h-[50px] w-[50px] items-center justify-center rounded-full bg-green-100`}
                        >
                           <CustomCreditIcon className={"w-[20px]"} color={"#86efac"} />
                        </div>
                     )}
                     {type === "DEBIT" && (
                        <div
                           className={`flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#EDC3C4] `}
                        >
                           <CustomDebitIcon className={"w-[20px]"} color={"#DC2626"} />
                        </div>
                     )}
                     {type === "INTERNAL" && (
                        <div
                           className={`flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#00000]`}
                        >
                           <CustomIntraIcon className={"w-[20px]"} color={"#0A0A0A"} />
                        </div>
                     )}

                     <p className="poppins-regular text-[16px] text-[#474747]">
                        {type.toUpperCase()}{" "}
                     </p>
                     <p className="poppins-semibold text-[24px] text-[#121212]">
                        {formattedAmount}
                     </p>
                  </div>

                  <div className="flex w-full flex-col rounded-[5px] bg-white px-[20px] py-[20px] shadow-lg shadow-[#F5F5F5]">
                     <div>
                        <p className="poppins-semibold text-black mb-[16px] text-[16px] text-black-two">
                           Payment Details
                        </p>
                     </div>
                     <ReceiptField label="organization" value={organization} />
                     <UserDetailsFields
                        name="Sender"
                        data={sender}
                        className="my-[5px] flex flex-col justify-between"
                     />
                     <UserDetailsFields
                        name="Recipent"
                        data={recipient}
                        className="my-[5px] flex flex-col justify-between"
                     />
                     <ReceiptField label="transaction type" value={type} />
                     <ReceiptField
                        label="Payment time"
                        value={`${formattedTime} ${formattedDate}`}
                     />
                     <ReceiptField label="Total Payment" value={formattedAmount} />
                     <ReceiptField label="transaction status" value={status} />
                     <ReceiptField label="reference" value={reference} />
                  </div>

                  <Link
                     to="dashboard/settings/contact-us"
                     className="bg-[FCFDFF] mt-[20px] flex  flex-row items-center justify-between gap-2 rounded-md px-[20px] py-[20px] text-black-one shadow-lg shadow-[#F5F5F5]"
                  >
                     <BsQuestionCircleFill size={30} className="text-black-three" />
                     <div>
                        <p className="poppins-medium text-2xl text-[14px]">
                           Trouble With Your Payment?
                        </p>
                        <p className="poppins-regular text-[12px]">
                           Let us know know on help center now!
                        </p>
                     </div>
                     <FaAngleRight size={30} color="#3B16A7" />
                  </Link>
                  <div
                     onClick={handleDownload}
                     className="poppins-medium mt-[10px] flex cursor-pointer items-center justify-center gap-[10px] rounded-[10px] border-[2px] border-[#1358CC] px-[20px] py-[10px]"
                  >
                     Get PDF Reciept <IoDownload />
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export const get_PDF_in_base64 = async (htmldoc) => {
   if (htmldoc) {
      const pdf = new jsPDF("p", "pt", "b5");
      pdf.html(htmldoc, {
         callback: function (doc) {
            doc.save("sample-document.pdf");
         },
      });
   }
};
