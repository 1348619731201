export default function useHandleFilter(state, dispatch) {
   const handleFilterSelect = (option) => {
      dispatch({
         type: "updateInput",
         payload: { name: "selectedFilter", value: option },
      });
   };

   const handleDateChange = (name, value) => {
      dispatch({
         type: "updateInput",
         payload: { name, value },
      });
   };

   const handleFilter = () => console.log(state.selectedFilter);

   return { handleFilterSelect, handleDateChange, handleFilter };
}
