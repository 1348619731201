import useSWR from "swr";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getAdminUrl } from "features/auth/auth.service";
import { setToken } from "utils/lib/storage";
import useRequest from "utils/hooks/useRequest";
import { signinWithEmailAndPassword } from "features/auth/auth.service";

export default function useSignin() {
   const { queryFn: signinFn } = useRequest(signinWithEmailAndPassword);

   const navigate = useNavigate();

   const [error, setError] = useState({});
   const [isLoggedIn, setIsLoggedIn] = useState(false);
   const [signinLoading, setSigninLoading] = useState(false);

   const [resetPasswordIsOpen, setResetPassowrdIsOpen] = useState(false);
   const showResetPasswordModal = () => setResetPassowrdIsOpen(true);
   const closeResetPasswordModal = () => setResetPassowrdIsOpen(false);

   const signin = (queryObj) => {
      setSigninLoading(true);
      signinFn(queryObj, {
         onSuccess: (data) => {
            setToken(data);
            setIsLoggedIn(data);
         },
         onError: (message) => {
            setSigninLoading(false);
            if (message.includes("Invalid sign in")) {
               setError({
                  link: "../signup",
                  linkText: "sign up",
                  message: `${message} Don't have an account? Please`,
               });
            } else {
               setError({ message: "Something went wrong, please try again" });
            }
         },
      });
   };

   //conditional fetching to verify registration status
   useSWR(isLoggedIn ? getAdminUrl : null, {
      onSuccess: (data) => {
         if (!data.meta.has_verified_email) {
            navigate(`../email-verification/${data._id}`);
         } else if (!data.unique_id) {
            navigate("../setup");
         } else {
            navigate("../dashboard");
         }
      },
      onError: () => {
         setSigninLoading(false);
         setError({ message: "Something went wrong, please try again" });
      },
   });

   const resetError = () => setError({});

   return {
      signin,
      signinLoading,
      signinError: error,
      resetError,
      resetPasswordIsOpen,
      showResetPasswordModal,
      closeResetPasswordModal,
   };
}
