import { useAdmin } from "features/auth/admin-context";
import { signOut } from "features/auth/auth.service";
import LinkItemColored from "features/settings/link-item-colored";
import SettingsFormColored from "features/settings/form-colored";
import Header from "features/ui/layout/headers/dashboard-header";
import { BsFillChatFill } from "react-icons/bs";
import CustomChangePinIcon from "assets/custom-icons/change-pin";
import CustomSetBankIcon from "assets/custom-icons/set-bank";
// import CustomResetPasswordIcon from "assets/custom-icons/reset-password";
import CustomLogOutIcon from "assets/custom-icons/logout";
import CustomContactSupportIcon from "assets/custom-icons/contact-support";
import CustomGroupIcon from "assets/custom-icons/group-icon";
import CustomMembersIcon from "assets/custom-icons/members-icon";
import CustomManagersIcon from "assets/custom-icons/manager-icon";

export default function Settings() {
   //queries
   const { admin } = useAdmin();

   //styles
   // const iconClass = "mr-3 text-xl md:text-3xl";

   return (
      <section className="wrapper min-h-screen">
         <Header title="Settings" variant="SETTINGS"></Header>

         <SettingsFormColored userData={admin} />

         <div className="mx-auto h-full mb-[100px] w-5/6 max-w-screen-sm text-black">
            <p className="font-semibold mb-[12px] mt-[40px] px-2 md:text-2xl text-xl">Advanced</p>
            <div className="flex flex-col  bg-white shadow p-5 gap-3 rounded-lg mb-7">
               {admin?.permissions.add_admin && (
                  <LinkItemColored
                     label="Add Managers & Admins"
                     icon={<CustomManagersIcon className={"w-[20px]"} />}
                     to="../../subadmins"
                  />
               )}
               {admin?.permissions.add_members && (
                  <LinkItemColored
                     label="Manage Members"
                     icon={<CustomMembersIcon className={"w-[20px]"} />}
                     to="../../members"
                  />
               )}
               {admin?.permissions.add_members && (
                  <LinkItemColored
                     label="Manage Merchants"
                     alt="merchants"
                     icon={<CustomMembersIcon className={"w-[20px]"} />}
                     to="../../merchants"
                  />
               )}
               {admin?.permissions.create_group_circle && (
                  <LinkItemColored
                     label="Manage Groups"
                     icon={<CustomGroupIcon className={"w-[20px]"} />}
                     to="../../groups"
                  />
               )}

               {/* <LinkItemColored
                  label="set spending limit"
                  icon={<GoStop className="mr-3 text-xl md:text-3xl" />}
                  to="spending-limit"
               />

               <LinkItemColored
                  to="set-payout"
                  label="set payout date"
                  icon={
                     <AiOutlineCalendar className="mr-3 text-xl md:text-3xl" />
                  }
               /> */}
            </div>
            <div className="mx-auto max-w-screen-sm">
               <p className="font-semibold mb-[12px] mt-[40px] px-2 md:text-2xl text-xl">
                  Security
               </p>
               <div className="flex flex-col bg-white shadow gap-3 p-5 rounded-lg mb-7">
                  <LinkItemColored
                     label="set bank details"
                     icon={<CustomSetBankIcon className={"w-[20px]"} />}
                     to="bank-details"
                  />
                  <LinkItemColored
                     to="change-pin"
                     label="change transaction pin"
                     icon={<CustomChangePinIcon className={"w-[20px]"} />}
                  />
                  {/* <LinkItemColored
                     to="reset-password"
                     label="reset password"
                     icon={<CustomResetPasswordIcon className={"w-[20px]"} />}
                  /> */}
               </div>
            </div>
            <div className="mx-auto max-w-screen-sm">
               <p className="font-semibold mb-[12px] mt-[40px] px-2 md:text-2xl text-xl">Support</p>
               <div className="flex flex-col bg-white shadow gap-3 p-5 rounded-lg mb-7">
                  <LinkItemColored
                     to="contact-us"
                     label="contact support"
                     icon={<CustomContactSupportIcon className={"w-[20px]"} />}
                  />
                  <LinkItemColored
                     to="https://tawk.to/chat/65e1a6eb8d261e1b5f67340b/1hnsn82pl"
                     label="live chat"
                     external={true}
                     icon={<BsFillChatFill className="text-accent-one" />}
                  />
               </div>
            </div>
            <div
               onClick={signOut}
               className="flex flex-row items-center justify-center cursor-pointer gap-[10px] text-black-one"
            >
               <CustomLogOutIcon className={"w-[20px]"} />
               <p className="text-black-one font-bold text-[20px]">Logout</p>
            </div>
         </div>
      </section>
   );
}
