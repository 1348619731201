import { axios, axiosClient } from "utils/lib/axios";

export const createBillAccount = (queryObj) => {
   return axios.post("admin/bills/new", queryObj);
};

export const updateBill = (queryObj, id) => {
   return axiosClient.put(`admin/bills/${id}`, queryObj);
};

export const deleteBill = (id) => {
   return axiosClient.delete(`admin/bills/${id}`);
};

export const getAllBillsUrl = "admin/bills";

export const getAllBillsInfoUrl = "admin/account/bills-info";

export const getSingleBillUrl = (id) => {
   return `admin/bills/info/${id}`;
};

export const getBillTransactions = (id, page = 1) => {
   return `admin/bills/transactions/${id}?page=${page}`;
};

export const createBillAccountV2 = (queryObj) => {
   return axiosClient.post("admin/create-bills", queryObj);
};
