import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

export default function SearchBar({ btnText }) {
   const [searchParams, setSearchParams] = useSearchParams();
   const methods = useForm({
      defaultValues: {},
   });

   function onSubmit(data) {
      searchParams.set("page", 1);
      searchParams.set("search", data.search);
      setSearchParams(searchParams);
   }

   return (
      <section data-type="section" className="space-y-4 w-full mb-4">
         <form className="w-full" onSubmit={methods.handleSubmit(onSubmit)}>
            <div id="select-category" className="flex items-center gap-2">
               <input
                  {...methods.register("search")}
                  placeholder="search"
                  className="border w-full grow p-3 rounded-md text-md"
               />
               <button
                  type="submit"
                  className="block px-6 py-3 bg-black-one text-white rounded-md cursor-pointer"
               >
                  {btnText ? btnText : "search"}
               </button>
            </div>
         </form>
      </section>
   );
}
