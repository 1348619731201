import useSavings from "./savings.api";
import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { createSavingsAccount } from "../savings.service";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function useCreateSavings() {
   const { mutateSavings } = useSavings();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: createSavingsFn } = useRequest(createSavingsAccount);

   const navigate = useNavigate();

   const handleSubmit = (queryObj, resetForm) => {
      const { funding_source } = queryObj;

      queryObj.funding_source = funding_source.map((s) => ({
         ...s,
         percentage: s.percentage / 100,
      }));

      showModal(MODAL_TYPES.LOADING, {
         message: "Wait a moment while we create your unique Savings ID",
      });

      createSavingsFn(queryObj, {
         onSuccess: () => {
            resetForm();
            mutateSavings();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Success",
               message: "Your Savings Account Have Been Created Successfully",
               btnText: "OK",
               modalPosition: "CENTER",
               bg: "WHITE",
               size: "THIN",
               showCloseBtn: false,
               hideOnBlur: false,
               icon: <IoCheckmarkCircleOutline />,
               btnOnClick: () => {
                  hideModal();
                  navigate(`/dashboard/savings-accounts?type=${queryObj.savings_type}`);
               },
            });
         },
         onError: () => {
            showModal(MODAL_TYPES.ERROR);
         },
      });
   };

   return {
      handleSubmit,
   };
}
