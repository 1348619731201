import AmountModal from "features/modal/amount-modal";
import NumPadModal from "features/numpad/numpad-modal.component";

import { useAdmin } from "features/auth/admin-context";
import useTransfer from "features/add-users/api/user-tranfer.api";

export default function Transfer({
   modalPosition = "CENTER",
   mongoId,
   uniqueId,
   mutateUserFn,
   userType,
   onClose,
}) {
   const { ledgerData, mutateLedgerData } = useAdmin();

   const mutationFn = () => {
      mutateUserFn();
      mutateLedgerData();
   };

   const {
      pin,
      setPin,
      state,
      dispatch,
      numpadModal,
      amountModal,
      toggleAmountModal,
      toggleNumpadModal,
      handleNext,
      handleSubmit,
   } = useTransfer({
      receiver_id: uniqueId,
      receiver_type: userType,
      mutationFn,
      modalPosition,
      onClose,
   });

   return (
      <>
         <AmountModal
            dispatch={dispatch}
            isOpen={amountModal}
            handleClick={handleNext}
            onClose={toggleAmountModal}
            balance={ledgerData?.MainAccountBalance}
            modalPosition={modalPosition}
            hideOnBlur={false}
            showCloseBtn={false}
         />
         <NumPadModal
            pin={pin}
            setPin={setPin}
            title="transaction pin"
            background="BLUE"
            btnText="proceed"
            btnOnClick={handleSubmit}
            amount={state.formattedAmount}
            message="confirm transfer"
            isOpen={numpadModal}
            onClose={toggleNumpadModal}
            modalPosition={modalPosition}
            hideOnBlur={false}
            showCloseBtn={false}
         />
      </>
   );
}
