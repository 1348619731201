export default function AccountExpired() {
   return (
      <section>
         <p className="text-center text-sm">
            the funding transaction account has expired. If payment has already
            been made, please check your funding balance. You can also try this
            payment again.
         </p>
         <p className="py-16 text-center text-2xl font-semibold md:pb-32 md:pt-28">
            Account Expired
         </p>
      </section>
   );
}
