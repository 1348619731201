import Button from "../forms/button";
import Input from "../forms/input";
import NumberFormat from "react-number-format";
import ModalContainer from "../../modal/modal-container.component";
import { AiOutlineCalendar } from "react-icons/ai";
import FormGroup from "../forms/form-group";
import RadioBtn from "../forms/radio-btn";
import { useWindowResize } from "utils/hooks/useWindowResize";

export default function RangeModal({
   startDate,
   endDate,
   selectedFilter,
   isOpen,
   onClose,
   title = "",
   options,
   handleSubmit,
   handleRangeSelect,
   handleDateChange,
   showDateRange = true,
}) {
   const handleNumberFormat = (values, name) => {
      const { formattedValue } = values;
      handleDateChange(name, formattedValue);
   };

   const { width } = useWindowResize();

   return (
      <ModalContainer variant="AUTO" isOpen={isOpen} onClose={onClose}>
         <div className="container min-h-0 py-6 md:py-8 lg:py-12">
            {options && (
               <RadioBtn
                  options={options}
                  selected={selectedFilter}
                  handleSelect={handleRangeSelect}
               />
            )}
            <h2 className="col-span-2 mb-6 font-semibold text-blue-600">
               {title}
            </h2>
            {showDateRange && (
               <FormGroup className="flex justify-between mb-6">
                  <FormGroup className="mx-2">
                     <NumberFormat
                        variant="LINE"
                        customInput={Input}
                        value={startDate}
                        label="from"
                        format="##/##"
                        placeholder="dd/mm"
                        iconPosition="RIGHT_EDGE"
                        icon={<AiOutlineCalendar />}
                        mask={["d", "d", "m", "m"]}
                        onValueChange={(values) =>
                           handleNumberFormat(values, "startDate")
                        }
                     />
                  </FormGroup>
                  <FormGroup className="mx-2">
                     <NumberFormat
                        variant="LINE"
                        customInput={Input}
                        format="##/##"
                        value={endDate}
                        label="to"
                        placeholder="dd/mm"
                        iconPosition="RIGHT_EDGE"
                        icon={<AiOutlineCalendar />}
                        mask={["d", "d", "m", "m"]}
                        onValueChange={(values) =>
                           handleNumberFormat(values, "endDate")
                        }
                     />
                  </FormGroup>
               </FormGroup>
            )}
            <Button
               size={width < 640 ? "SMALL" : "NORMAL"}
               onClick={() => {
                  handleSubmit();
                  onClose();
               }}
            >
               {" "}
               apply
            </Button>
         </div>
      </ModalContainer>
   );
}
