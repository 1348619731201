import React from "react";

const CustomFundIcon = ({ className, color }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 86.02 86.02"
         className={className}
      >
         <defs>
            <style>{`.cls-fund{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-fund"
                  d="M28.31,0h29.4a4.09,4.09,0,0,1,0,8.18H28.31A20.15,20.15,0,0,0,8.18,28.31v29.4A20.16,20.16,0,0,0,28.31,77.85h29.4A20.17,20.17,0,0,0,77.85,57.71V28.31a4.09,4.09,0,1,1,8.17,0v29.4A28.34,28.34,0,0,1,57.71,86H28.31A28.34,28.34,0,0,1,0,57.71V28.31A28.34,28.34,0,0,1,28.31,0Z"
               />
               <path
                  className="cls-fund"
                  d="M73.73,11.81a4.1,4.1,0,0,0-5.79-.06L36.76,42.26V34.91a4.09,4.09,0,0,0-8.17,0V52c0,.09,0,.17,0,.25a4.53,4.53,0,0,0,.05.53c0,.13.07.24.11.37s.07.26.12.39.13.26.2.38a2.59,2.59,0,0,0,.17.33,4.54,4.54,0,0,0,.33.41c.06.07.1.14.16.2h0a3.8,3.8,0,0,0,.62.52,2,2,0,0,0,.2.11,3.1,3.1,0,0,0,.51.27c.12,0,.25.08.37.12s.26.09.4.12a4.26,4.26,0,0,0,.8.08H50.05a4.09,4.09,0,1,0,0-8.18H42.7l31-30.3A4.08,4.08,0,0,0,73.73,11.81Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomFundIcon;
