export default function useNavRange({
   total,
   currentPage,
   pageCount,
   mobileBtnsNum,
   desktopBtnsNum,
}) {
   if (!total) {
      return { mobile: [1], desktop: [1] };
   }

   const pagesArr = Array.from(Array(pageCount).keys()).map((i, idx) => idx + 1);

   const mobileBounds = getStartAndEndRange(currentPage, mobileBtnsNum);
   const desktopBounds = getStartAndEndRange(currentPage, desktopBtnsNum);

   return {
      mobileRange: pagesArr.slice(mobileBounds.rangeStart, mobileBounds.rangeEnd),
      desktopRange: pagesArr.slice(desktopBounds.rangeStart, desktopBounds.rangeEnd),
      pageCount,
   };
}

const getStartAndEndRange = (currentPage, range) => {
   const remainder = currentPage % range;
   const start = currentPage - remainder;
   const rangeStart = remainder === 0 && currentPage >= range ? start - range : start;
   const rangeEnd = rangeStart + range;
   return { rangeStart, rangeEnd };
};
