import useSWR from "swr";
import { getSingleMemberUrl } from "../users.service";

export default function useSingleMember(id) {
   const {
      data: member,
      error: memberError,
      mutate: mutateMember,
   } = useSWR(id ? [getSingleMemberUrl(id), "", "V2"] : null);

   const memberLoading = !member && !memberError;

   return {
      member,
      memberInfo: member?.member,
      mutateMember,
      memberError,
      memberLoading,
   };
}
