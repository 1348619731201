export default function AddMemberIcon({ className }) {
   return (
      <svg
         width="91"
         height="91"
         viewBox="0 0 91 91"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         className={className}
      >
         <rect
            x="1.01532"
            y="1.01532"
            width="88.618"
            height="88.618"
            rx="44.309"
            fill="#0A0A0A"
            stroke="#FCFDFF"
            strokeWidth="1.55895"
         />
         <path
            d="M39.2783 51.3089C46.6748 51.3089 52.9189 52.5259 52.9189 57.2263C52.9189 61.9267 46.6348 63.1031 39.2783 63.1031C31.8818 63.1031 25.6377 61.8842 25.6377 57.1857C25.6377 52.4854 31.9201 51.3089 39.2783 51.3089ZM56.5546 37.6406C57.4571 37.6406 58.1896 38.3853 58.1896 39.2986V41.4379H60.3777C61.2783 41.4379 62.0127 42.1826 62.0127 43.0959C62.0127 44.0093 61.2783 44.7539 60.3777 44.7539H58.1896V46.8951C58.1896 47.8084 57.4571 48.5531 56.5546 48.5531C55.6539 48.5531 54.9196 47.8084 54.9196 46.8951V44.7539H52.7352C51.8327 44.7539 51.1002 44.0093 51.1002 43.0959C51.1002 42.1826 51.8327 41.4379 52.7352 41.4379H54.9196V39.2986C54.9196 38.3853 55.6539 37.6406 56.5546 37.6406ZM39.2783 28.5469C44.2882 28.5469 48.3041 32.6147 48.3041 37.6894C48.3041 42.7641 44.2882 46.832 39.2783 46.832C34.2684 46.832 30.2525 42.7641 30.2525 37.6894C30.2525 32.6147 34.2684 28.5469 39.2783 28.5469Z"
            fill="#FCFDFF"
         />
      </svg>
   );
}
