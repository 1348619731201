import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function WelcomeSlideOne({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/real estate _ blueprint, blueprints, plan, plans, city, development, building 1Admin.png"
         title="Build Your Own System"
         text="Build a financial system that allows members and merchants to
          transact seamlessly within your organization"
         screen={1}
         setScreen={setScreen}
      />
   );
}
