import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useGroups from "./groups.api";
import { createGroup } from "../groups.service";

export default function useAddGroup() {
   const navigate = useNavigate();
   const { mutateGroups } = useGroups();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: createGroupFn } = useRequest(createGroup);

   const handleSubmit = (queryObj, resetForm) => {
      showModal(MODAL_TYPES.LOADING, {
         message: "Wait a moment while we create the group",
         modalPosition: "CENTER",
      });

      createGroupFn(queryObj, {
         onSuccess: () => {
            resetForm();
            mutateGroups();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Success",
               message: "Group has been created Successfully.",
               btnText: "OK",
               modalPosition: "CENTER",
               bg: "WHITE",
               size: "THIN",
               showCloseBtn: false,
               hideOnBlur: false,
               icon: <IoCheckmarkCircleOutline />,
               btnOnClick: () => {
                  hideModal();
                  navigate("/dashboard/groups");
               },
            });
         },
         onError: () => {
            showModal(MODAL_TYPES.ERROR, {
               modalPosition: "CENTER",
            });
         },
      });
   };

   return {
      handleSubmit,
   };
}
