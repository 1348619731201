import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonComponent = () => (
   <SkeletonTheme color="#202020" highlightColor="#444">
      <section>
         <Skeleton count={3} />
         <Skeleton width={100} />
         <Skeleton circle={true} height={50} width={50} />
      </section>
   </SkeletonTheme>
);

export default SkeletonComponent;
