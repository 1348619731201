export default function CustomIntraIcon({ className, color }) {
   return (
      <svg
         width="32"
         height="32"
         viewBox="0 0 32 32"
         xmlns="http://www.w3.org/2000/svg"
         className={className}
      >
         <path
            d="M22.4528 26.8857V8.72864"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M27.8897 21.4242L22.4527 26.8864L17.0156 21.4242"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M9.21484 5.11053V23.2676"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M3.77795 10.572L9.21499 5.1098L14.652 10.572"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   );
}
