import TableRow from "features/ui/tables/table-row";
import TableCol from "features/ui/tables/table-col";
import { BsBank2 } from "react-icons/bs";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import CustomCreditIcon from "assets/custom-icons/credit-icon";
import CustomDebitIcon from "assets/custom-icons/debit-icon";
import CustomIntraIcon from "assets/custom-icons/intra-icon";

export default function DayRow({ day }) {
   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const showReceiptModal = (transaction) => {
      showModal(MODAL_TYPES.RECEIPT, {
         id: transaction._id,
         amount: transaction.amount,
         fullAmount: transaction.orginalAmount,
         created: transaction.created,
         type: transaction.payment_type,
         reference: transaction.transaction_reference,
         sender: transaction.sender,
         recipient: transaction.recipient,
      });
   };

   return (
      <>
         <div className="mb-6 md:mb-12">
            <div key={day._id}>
               <div onClick={() => showReceiptModal(day)}>
                  <TableRow
                     gridTemplateCols="grid-cols-4"
                     className="cursor-pointer hover:bg-blue-50"
                  >
                     <div className="flex gap-[10px] col-span-2 items-center">
                        {/* <div className="bg-gray-200 h-[40px] w-[40px] rounded-full"></div> */}

                        <BsBank2 />
                        <TableCol className="items-start flex flex-col text-black font-medium">
                           <p className="text-[14px]">
                              {day?.payment_type === "CREDIT"
                                 ? `${day?.sender.account_name}`
                                 : day?.payment_type === "DEBIT"
                                 ? `${day?.recipient.account_name}`
                                 : day?.payment_type === "INTERNAL"
                                 ? `${day?.recipient.account_name}`
                                 : "PENDING"}
                           </p>
                           <p className=" leading-tight text-[10px] text-black-three w-full text-left">
                              {day?.formattedTime}
                           </p>
                        </TableCol>
                     </div>

                     <TableCol className="justify-center font-medium text-secondary-darker">
                        {day.payment_type === "CREDIT" && (
                           <CustomCreditIcon className={"w-[15px]"} color={"#86efac"} />
                        )}
                        {day.payment_type === "DEBIT" && (
                           <CustomDebitIcon className={"w-[15px]"} color={"#DC2626"} />
                        )}
                        {day.payment_type === "INTERNAL" && (
                           <CustomIntraIcon className={"w-[15px]"} color={"#0A0A0A"} />
                        )}
                     </TableCol>
                     <TableCol className={`justify-end font-bold text-black`}>
                        <span className="text-xs">₦</span>
                        {day.formattedAmount.slice(1)}
                     </TableCol>
                  </TableRow>
               </div>
            </div>
         </div>
      </>
   );
}
