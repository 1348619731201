import { useAdmin } from "features/auth/admin-context";
import FundingActionsModal from "features/fund-account/components/funding-actions";
import LoadingModal from "features/ui/feedbacks/loading-modal";

export default function FundAccountActions() {
   const { pendingFunding, getAdminLoading } = useAdmin();

   if (getAdminLoading) {
      return <LoadingModal isOpen={true} modalPosition="CENTER" />;
   }

   return (
      <section className="wrapper ">
         <div className="text-black mx-auto  h-full w-5/6 max-w-screen-sm px-10 py-10">
            <FundingActionsModal actionsIsOpen={true} pendingFunding={pendingFunding} />
         </div>
      </section>
   );
}
