import { axios } from "utils/lib/axios";
import { clearToken } from "utils/lib/storage";

export const signupWithEmailAndPassword = async (queryObj) => {
   return axios.post("admin/signup", queryObj);
};

export const verifyEmailOTP = async (queryObj) => {
   return axios.post("admin/signup/verify-otp", queryObj);
};

export const signinWithEmailAndPassword = async (queryObj) => {
   return axios.post("admin/signin", queryObj);
};

export const getAdminUrl = () => "admin/account/info";

export const getAdminLedgerBalanceUrl = () => "admin/ledger-balance";

export const signOut = () => {
   window.location.replace("/signin");
   clearToken();
};
