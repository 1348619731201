import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import PageTitle from "features/ui/layout/headers/page-title";
import FormGroup from "features/ui/forms/form-group";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import ModalContainer from "features/modal/modal-container.component";

export default function BankDetailsDisplay({
   isOpen,
   onClose,
   bankDetails,
   handleNext,
   modalPosition,
   hideOnBlur,
   showCloseBtn,
   changePayoutLink = "/dashboard/settings/bank-details",
}) {
   const { bankName, accountName, accountNumber } = bankDetails;
   return (
      <ModalContainer
         isOpen={isOpen}
         onClose={onClose}
         modalPosition={modalPosition}
         hideOnBlur={hideOnBlur}
         showCloseBtn={showCloseBtn}
      >
         <ArrowBack handleBack={onClose} />
         <div className="container pt-32 md:py-16">
            <PageTitle
               variant="SECONDARY"
               title="withdraw"
               message="Confirm account payment should be made to"
            />
            <FormGroup className="self-stretch mb-4">
               <FormGroup className="mb-12">
                  <Input
                     disabled={true}
                     variant="LINE"
                     value={bankName}
                     icon={accountNumber}
                     label="bank account"
                     iconPosition="RIGHT"
                     readOnly
                  />
               </FormGroup>
               <Input
                  disabled={true}
                  variant="LINE"
                  value={accountName}
                  label="account name"
                  readOnly
               />
            </FormGroup>
            <div className="mb-10">
               <Button variant="TERTIARY" color="YELLOW_TEXT" to={changePayoutLink}>
                  change payout account
               </Button>
            </div>
            <Button size="WIDE" onClick={handleNext}>
               confirm
            </Button>
         </div>
      </ModalContainer>
   );
}
