import useSWR from "swr";
import { useState } from "react";

import { getTransactionsUrl } from "../services";
import { getTransformedTransaction } from "utils/lib/getTransformedTransactions";
import { formatDataByDay } from "utils/lib/getSortedData";

const getCombinedMonthsData = (data) => {
   return data.reduce((result, month) => {
      return [...result, ...month.docs];
   }, []);
};

export default function useTransactions(queryParams, page) {
   const [payouts, setPayouts] = useState([]);
   const [fundings, setFundings] = useState([]);
   const fundingsUrlParam = `${getTransactionsUrl(page)}&transaction_type=funding`;
   const payoutsUrlParam = `${getTransactionsUrl(page)}&transaction_type=payouts`;

   const {
      data: transactionsData,
      error: transactionsError,
      mutate: mutateTransactions,
      isValidating: transactionsMutating,
   } = useSWR([getTransactionsUrl(page), queryParams]);

   const { transactions: retrievedTransactions, totalCount, totalPages } = transactionsData ?? {};

   const { error: payoutsError } = useSWR(fundingsUrlParam, {
      onSuccess: (data) => setPayouts(getCombinedMonthsData(data.transactions)),
   });

   const { error: fundingsError } = useSWR(payoutsUrlParam, {
      onSuccess: (data) => setFundings(getCombinedMonthsData(data.transactions)),
   });

   //refetch transactions, mutate admin alongside transactions
   //so the useEffect can run
   // DEPRECATED
   // const refetchTransactions = () => {
   //    mutateTransactions();
   //    mutateAdmin();
   // };

   const payoutsLoading = !payouts && !payoutsError;
   const fundingsLoading = !fundings && !fundingsError;
   const transactionsLoading =
      (!retrievedTransactions && !transactionsError) || transactionsMutating;

   const formattedTransactions =
      retrievedTransactions && getTransformedTransaction(retrievedTransactions);

   const transactions = retrievedTransactions && formatDataByDay(formattedTransactions);

   return {
      payouts,
      fundings,
      payoutsError,
      fundingsError,
      payoutsLoading,
      fundingsLoading,
      transactionsError,
      transactionsLoading,
      mutateTransactions,
      refetchTransactions: mutateTransactions,
      transactions,
      totalCount,
      totalPages,
   };
}
