import { ReactComponent as ArrowForward } from "../../../../src/assets/icons/LedgaV2 Icons/Arrow Complete.svg";

const imgUrl =
   "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1031&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

export default function UserTab({ userData }) {
   const { ProfileIcon } = userData;
   return (
      <div className="mx-auto w-full rounded-[10px] pt-[30px] px-[20px]">
         <div className="flex flex-row gap-2 items-center justify-between">
            <div className="flex gap-[10px]">
               <div className="rounded-[10px]  w-[40px] h-[40px]">
                  {ProfileIcon ? (
                     <ProfileIcon className="w-full h-full stroke-black-one fill-black-one" />
                  ) : (
                     <img
                        src={userData.profile_image ?? imgUrl}
                        alt="user profile"
                        className="w-full h-full  border-[2px] border-black-one"
                     />
                  )}
               </div>

               <div>
                  <p className="font-bold">{userData.name}</p>
                  <p className="text-gray-400">{userData.unique_id}</p>
               </div>
            </div>

            <ArrowForward className="text-black" />
         </div>
         <hr className="text-black-three mt-[10px]" />
      </div>
   );
}
