import useBills from "./bills.api";
import { updateBill } from "../bills.service";
import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useUpdateBill(billId) {
   const { mutateBills } = useBills();
   const { queryFn: updateBillFn } = useRequest(updateBill, billId);
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const handleBillUpdateBill = (queryObj) => {
      const {
         bank_name: bankName,
         bank_code: bankCode,
         account_name: accountName,
         account_number: accountNumber,
      } = queryObj;

      delete queryObj.bank_name;
      delete queryObj.bank_code;
      delete queryObj.account_name;
      delete queryObj.account_number;

      showModal(MODAL_TYPES.LOADING, {
         modalPosition: "CENTER",
      });

      updateBillFn(
         {
            ...queryObj, //
            bankName,
            bankCode,
            accountName,
            accountNumber,
         },
         {
            onSuccess: () => {
               mutateBills();
               showModal(MODAL_TYPES.SUCCESS, {
                  modalPosition: "CENTER",
                  title: "success",
                  message: "bill details updated successfully",
                  btnText: "ok",
                  btnOnClick: hideModal,
               });
            },
            onError: () => {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition: "CENTER",
                  message: "bill could not be updated, please try again",
               });
            },
         }
      );
   };

   return { handleBillUpdateBill };
}
