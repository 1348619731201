import TableHeader from "features/ui/tables/table-header";
import EarningsTableData from "./table-data";
import Spinner from "features/ui/feedbacks/spinner";

export default function EarningsTable({
   className,
   showFilterModal,
   earningsData,
   earningsLoading,
}) {
   return (
      <div
         className={`${className} w-5/6 mb-52 mx-auto sm:max-w-lg sm:text-lg  md:max-w-3xl md:text-xl`}
      >
         <TableHeader
            toggleModal={showFilterModal}
            gridTemplateCols="grid-cols-custom-table-1"
            firstCol={"date"}
            otherCols={[" ", " "]}
            lastCol={"amount"}
         />
         {earningsLoading ? (
            <div className="my-32 flex items-center justify-center">
               <Spinner color="GREY" size="MEDIUM" />
            </div>
         ) : (
            earningsData?.map((e, i, arr) => {
               const thisYear = e._id.year;
               const lastYear = arr[i - 1]?._id.year;
               const isNewYear = i === 0 || thisYear !== lastYear;

               return (
                  <EarningsTableData
                     year={isNewYear && thisYear}
                     data={e.docs}
                     month={e._id.month}
                     key={`${e._id.year}${e._id.month}`}
                  />
               );
            })
         )}
      </div>
   );
}
