import Button from "features/ui/forms/button";
import ModalContainer from "./modal-container.component";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function SuccessModal({
   isOpen,
   icon,
   title,
   message,
   btnText,
   btnOnClick,
   children,
   size,
   modalPosition,
   bg,
   onClose,
   variant,
   showCloseBtn,
   hideOnBlur,
}) {
   return (
      <ModalContainer
         isOpen={isOpen}
         size={size}
         role="alert"
         color={bg}
         onClose={onClose}
         variant={variant}
         showCloseBtn={showCloseBtn}
         hideOnBlur={hideOnBlur}
         modalPosition={modalPosition}
      >
         <div className="container justify-center h-screen md:h-auto md:py-20 ">
            <div className="flex justify-center text-7xl mb-6 md:text-8xl">
               {icon ? icon : <IoCheckmarkCircleOutline />}
            </div>

            <h1 className="text-2xl font-bold capitalize text-center">
               {title}
            </h1>
            <p className="mt-1 sm:text-base md:text-lg text-center">
               {message}
            </p>

            {children && <div className="whitespace-pre-wrap">{children}</div>}

            <div className="mt-10">
               {btnText && (
                  <Button
                     onClick={btnOnClick ? btnOnClick : onClose}
                     size="WIDE"
                     color={bg === "BLUE" ? "WHITE" : "BLUE"}
                  >
                     {btnText}
                  </Button>
               )}
            </div>
         </div>
      </ModalContainer>
   );
}
