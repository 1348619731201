import jsPDF from "jspdf";
import "jspdf-autotable";
import "assets/fonts/Roboto-Regular-normal";
import "assets/fonts/FuzzyBubbles-normal";
import "assets/fonts/Montserrat-normal";
import "assets/fonts/Montserrat-Regular-normal";

export default function getTablePDF(tableData, pdfName) {
   const doc = new jsPDF("p", "mm", [210, 297]);
   const { head, rows } = tableData;

   /*cell padding is defined here because cellPaddingX is reused*/
   const pX = 3;
   const pY = 4;

   doc.setFont("Montserrat-Regular");

   doc.autoTable({
      head: head,
      body: rows,
      theme: "grid",

      headStyles: {
         font: "Montserrat-Regular",
         fontSize: 11,
         valign: "middle",
         textColor: "#64748b",
         fillColor: "#fff",
         cellPadding: { top: 6, right: 3, bottom: 3, left: 3 },
      },

      bodyStyles: {
         font: "Montserrat-Regular",
         fontSize: 13,
         textColor: "#64748b",
         fillColor: "#fff",
         cellPadding: { top: pY, right: pX, bottom: pY, left: pX },
         lineWidth: { top: 0, right: 0, bottom: 0.1, left: 0 },
      },

      willDrawCell: (data) => {
         // style text or set to empty string if its a year row
         if (data.column.index === 0 && data.row.raw.includes("year")) {
            doc.setFontSize(20);
         } else if (data.cell.raw === "year") {
            data.cell.text = "";
         }

         // remove bottomBorder (all borders) if its a year row
         if (data.row.raw.includes("year")) {
            data.cell.styles.lineWidth = "";
         }

         // set text to empty string if its a year row, a rect is later drawn
         // and the month text value written in index position 0 in didDrawCell
         if (data.row.raw.includes("month")) {
            data.cell.text = "";
         }

         if (data.row.raw[0] === "payout" && data.column.index === 2) {
            doc.setTextColor(220, 38, 38);
         }

         if (data.row.raw[0] === "funding" && data.column.index === 2) {
            doc.setTextColor(22, 163, 74);
         }
      },

      didDrawCell: (data) => {
         //style the month
         if (data.row.raw[1] === "month" && data.column.index === 0) {
            const heightDiff = 3;
            const rectHeight = data.row.height - heightDiff;
            const rectWidth = data.column.wrappedWidth;
            const cellPositionX = data.cursor.x;
            const cellPositionY = data.cursor.y;

            doc.setFillColor(37, 99, 235);
            doc.roundedRect(
               Number(cellPositionX),
               Number(cellPositionY + heightDiff / 2),
               rectWidth,
               rectHeight,
               5,
               5,
               "F"
            );
            doc.setTextColor(255, 255, 255);
            doc.text(
               data.cell.raw,
               Math.floor(cellPositionX + pX),
               Math.floor(cellPositionY + rectHeight / 2),
               { baseline: "top" }
            );
         }
      },
   });
   // setter(doc.output("bloburi"));
   // // return doc;
   doc.save(`${pdfName || "ledga-data612wsd12423"}.pdf`);
}
