import useSWR from "swr";
import { getAllBillsUrl } from "features/bills/bills.service";
import { fetcherV2 } from "utils/lib/fetcher";

export default function useBills() {
   const {
      data: bills,
      error: getAllBillsError,
      mutate: mutateBills,
   } = useSWR(getAllBillsUrl, fetcherV2);

   const getAllBillsLoading = !bills && !getAllBillsError;

   return { bills, getAllBillsLoading, mutateBills };
}
