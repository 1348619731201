import { Link } from "react-router-dom";
import { classNames } from "utils/lib/getClassName";
import { ReactComponent as ReturnWhite } from "../../../../../src/assets/icons/LedgaV2 Icons/Return White.svg";
import { ReactComponent as ReturnBlack } from "../../../../../src/assets/icons/LedgaV2 Icons/Arrow Black.svg";

import { BsArrowLeft } from "react-icons/bs";

const VARIANT_MAPS = {
   PRIMARY: "absolute w-12  top-6 left-6 sm:left-16 md:hidden",
   SECONDARY: "absolute w-12  top-6 left-6 sm:left-16 md:left-40 lg:left-64",
   TERTIARY: "absolute w-12  top-6 left-12 sm:left-20 md:sticky -ml-6",
   PRIMARYV2: "absolute top-2 ",
   MODAL: "",
   OTHER: "",
};

const COLOR_MAPS = {
   WHITE: "text-white md:text-secondary-darker",
   BLUE: "text-primary md:text-secondary-darker",
   GREY: "text-secondary-darker",
   BLACK: "text-black-one",
};

export default function ArrowBack({
   to,
   handleBack,
   background,
   color = "BLUE",
   variant = "PRIMARYV2",
}) {
   const className = classNames(
      `self-start md:w-8 cursor-pointer text-4xl
      ${background === "BLUE" ? "text-white" : "text-primary"}`,
      COLOR_MAPS[color],
      VARIANT_MAPS[variant]
   );

   if (to) {
      return (
         <Link to={to} className={className}>
            <BsArrowLeft onClick={handleBack} />
         </Link>
      );
   } else {
      return (
         <div className={className} onClick={handleBack}>
            {color === "BLACK" ? <ReturnBlack /> : <ReturnWhite />}
         </div>
      );
   }
}
