import { axiosClient } from "utils/lib/axios";

export const createSavingsAccount = (queryObj) => {
   return axiosClient.post("admin/savings/new", queryObj);
};

export const getAllSavingssUrl = "admin/savings";

export const deleteSavingsAccount = (id) => {
   return axiosClient.delete(`admin/savings/${id}`);
};

export const getSingleSavingsUrl = (id) => {
   return `admin/savings/info/${id}`;
};
