import useCreateUser from "features/add-users/api/create-user.api";
import DuplicatesFound from "features/add-users/components/duplicates-modal";
import AddMembers from "features/add-users/components/add-members";
import { addMembers } from "features/add-users/users.service";
import useRequest from "utils/hooks/useRequest";

export default function Addmembers() {
   const { queryFn: createMembersFn } = useRequest(addMembers);

   const {
      createUsers, //
      duplicateEntries,
      duplicatesModal,
      closeDuplicateModal,
   } = useCreateUser(createMembersFn, "students");

   return (
      <section className="wrapper">
         <AddMembers createMembers={createUsers} />
         <DuplicatesFound
            usersType="students"
            isOpen={duplicatesModal}
            onClose={closeDuplicateModal}
            duplicateEntries={duplicateEntries}
         />
      </section>
   );
}
