import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import useRequest from "utils/hooks/useRequest";
import { completeConfig, verifyCacNumber } from "./setup.service";
import { useAdmin } from "features/auth/admin-context";
import { bankDetailsSchema } from "features/bank-details/bank-details.validation";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useSetup() {
   // signatories, profile_image_url are set by default because they are skippable steps
   const [setupDetails, setSetupDetails] = useState({
      signatories: [],
      profile_image_url:
         "https://ik.imagekit.io/thelastkingpin/clouddust/book%20covers/sanyay.jpg?updatedAt=1694393545671",
   });
   /*
      the  details state used here above to temporarily hold 
      setupDetails data once that part of the form is filled
      as the submission of the form doesn't take place until
      the final step
   */

   const hookFormMethods = useForm({
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         bank_name: "",
         bank_code: "",
         account_number: "",
         account_name: "",
      },
      resolver: yupResolver(bankDetailsSchema),
   });
   /*
      useForm is called here above for bank form as the form 
      doesnt manage its state since is used in multiple
      components
   */

   const { reset: resetBankForm } = hookFormMethods;

   const modalPosition = "CENTER";
   const navigate = useNavigate();
   const [screen, setScreen] = useState(1);
   const { mutateAdmin, getAdminLoading } = useAdmin();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const { queryFn: setupFn, loading: setupLoading } = useRequest(completeConfig);

   const handleBack = () => {
      screen === 4 && !setupDetails.corporate_account
         ? setScreen(1)
         : setScreen((prev) => prev - 1);
      resetBankForm();
   };

   const handleNextScreen = (data) => {
      screen === 1 && !data.corporate_account ? setScreen(3) : setScreen((prev) => prev + 1);
      setSetupDetails((prev) => ({ ...prev, ...data }));
   };

   const handleFinish = async () => {
      showModal(MODAL_TYPES.LOADING, {
         modalPosition: "CENTER",
      });
      await mutateAdmin();
      navigate("../create-pin");
      hideModal();
   };
   /* 
      the idCreatedModal button mutates the admin
      so that the admin state will be updated with unique id
      PS: after the aforementioned update, the useEffect in the 
      admin will navigate to ../create-pin automatically but it is 
      also explicitly and perhaps cosmestically handled here. 
   */

   const handleFormSubmit = (finalFormData) => {
      const { phone } = setupDetails;
      const phoneNo = phone.length === 10 ? "0" + phone : phone;

      const queryObj = {
         ...setupDetails,
         ...finalFormData,
         phone: phoneNo,
      };

      resetBankForm();
      setupFn(queryObj, {
         onSuccess: (id) => {
            showModal(MODAL_TYPES.ID_CREATED, {
               id: id,
               modalPosition: modalPosition,
               isLoading: getAdminLoading,
               message: `Your unique \n Organization ID has been created`,
               handleClick: handleFinish,
            });
         },

         onError: (message) => {
            if (message.includes("already setup")) {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition: modalPosition,
                  title: "Setup Already Completed",
                  to: "../signin",
                  message: `${message} Please signin to continue`,
                  btnText: "signin",
                  btnOnClick: hideModal,
               });
            } else {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition: modalPosition,
                  btnOnClick: () => window.location.reload(),
               });
            }
         },
      });
   };

   return {
      screen,
      setupLoading,
      hookFormMethods,
      setupDetails,
      handleBack,
      handleNextScreen,
      handleFormSubmit,
      setScreen,
   };
}

export const useCACVerification = () => {
   const { queryFn: cacVerificationFn, loading: cacVerificationLoading } =
      useRequest(verifyCacNumber);

   const verifyCac = (queryObj, onSuccess, onError) => {
      cacVerificationFn(queryObj, { onSuccess, onError });
   };

   return { verifyCac, cacVerificationLoading };
};
