export const VARIANT_MAPS = {
   FULL_SCREEN: "min-h-full w-full md:min-h-0 md:rounded-xl",
   NUMPAD: "min-h-full w-full md:min-h-0 md:rounded-xl",
   AUTO: "w-5/6",
   FULL_SCREEN_NEW: "min-h-full w-full md:min-h-[500px]",
};
export const SIZE_MAPS = {
   THIN: "md:max-w-[430px]",
   MEDIUM: "md:max-w-[550px] lg:max-w-screen-sm",
   LARGE: "md:max-w-[550px] lg:max-w-screen-md",
   SMALL: "md:max-w-[430px] px-4 pt-8",
};
export const COLOR_MAPS = (variant) => {
   return {
      BLUE: `bg-primary text-white ${variant === "NUMPAD" && "md:bg-white md:text-secondary"}`,
      WHITE: `bg-white text-primary ${variant === "NUMPAD" && "md:bg-white md:text-secondary"}`,
   };
};
export const POSITION_MAPS = {
   CENTER: " ",
   RIGHT: "md:-translate-x-[calc(50%-var(--sidebar-width-base)/2)] lg:-translate-x-[calc(50%-var(--sidebar-width)/2)]",
};
