import useSWR from "swr";
import { getSingleSavingsUrl } from "../savings.service";
import getSorted, { formatDataByDay } from "utils/lib/getSortedData";
import { getTransformedTransaction } from "utils/lib/getTransformedTransactions";

export default function useSingleSavings(id) {
   const {
      data: savings,
      error: savingsError,
      mutate: mutateSavings,
   } = useSWR(id ? [getSingleSavingsUrl(id), "", "V2"] : null);

   const savingsLoading = !savings && !savingsError;
   const savingsInfo = savings?.savings;
   const savingsTransformedTransactions =
      savings && getTransformedTransaction(savings?.transactions);
   const savingsTransactions = savings && formatDataByDay(savingsTransformedTransactions);
   const savingsFilterOptions =
      savings && savings.savings.funding_source.map((fs) => fs?.bill_id?.unique_id);
   savingsFilterOptions?.unshift("all");

   return {
      savings,
      savingsInfo,
      mutateSavings,
      savingsError,
      savingsLoading,
      savingsTransactions,
      savingsFilterOptions,
   };
}
