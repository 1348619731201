import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import ModalContainer from "features/modal/modal-container.component";
import PageTitle from "features/ui/layout/headers/page-title";
import Spinner from "features/ui/feedbacks/spinner";
import useInputUpdate from "utils/hooks/useInputUpdate";

export default function EnterPassword({
   isOpen,
   onClose,
   state,
   dispatch,
   loading,
   btnText,
   handleSubmit,
}) {
   const handleChange = useInputUpdate(dispatch);

   return (
      <ModalContainer isOpen={isOpen} onClose={onClose}>
         <div className="container justify-center min-h-screen md:min-h-0 md:py-28">
            <PageTitle
               variant="TERTIARY"
               title="enter password"
               message="please enter your ledga account password"
            />
            <form className="self-stretch mb-10" autoComplete="new-password">
               <input type="hidden" value="something" />
               <Input
                  value={state.password}
                  name="password"
                  label="password"
                  autoComplete="new-password"
                  onChange={handleChange}
               />
            </form>
            <Button disabled={loading} size="WIDE" onClick={handleSubmit}>
               {loading ? <Spinner color="WHITE" /> : btnText}
            </Button>
         </div>
      </ModalContainer>
   );
}
