export const BVN_VALIDATION = /^[0-9]{11}$/;
export const CVV_VALIDATION = /^[0-9]{3,4}$/;
export const PIN_VALIDATION = /^[0-9]{4}$/;
export const DAY_VALIDATION = /^[0-9]{1,2}$/;
export const AMOUNT_VALIDATION = /^[1-9]\d*$/;
export const NAME_VALIDATION = /^[a-zA-Z ]{2,60}$/;
export const ALPHANUMERIC_NAME_VALIDATION = /^([a-zA-Z0-9 _-]+)$/;
export const UNIQUE_ID_VALIDATION = /^([a-zA-Z0-9]+){6}$/;
export const EMAIL_VALIDATION = /[^@]+@[^@]+\.[^@]+/;
export const STUDENT_COUNT_VALIDATION = /^[0-9]{2,6}$/;
export const CARD_DATE_VALIDATION = /^(0[1-9]|1[0-2])[/-]\d{2}$/;
export const PHONE_NUMBER_VALIDATION = /^[0-9]{10,11}$/;
export const ACCOUNT_NUMBER = /^[0-9]{10}$/;
export const PASSWORD_VALIDATION = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const BIRTH_DATE_VALIDATION =
   /^(?:0[1-9]|[12]\d|3[01])([/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/;
export const CARD_NUMBER_VALIDATION =
   /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
export const DATE_LEAPYEAR_VALIDATION =
   /(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/;
