import { useParams } from "react-router-dom";

import User from "features/add-users/components/user-details";
import useSingleMember from "features/add-users/api/member-single.api";
import useDeleteMember from "features/add-users/api/member-delete.api";

export default function MemberDetails() {
   const { id: memberId } = useParams();
   const { memberInfo } = useSingleMember(memberId);
   const { onDeleteClick } = useDeleteMember(memberInfo);
   if (!memberInfo) return null;

   return (
      <User
         userType="members"
         name={memberInfo.name}
         userId={memberId}
         uniqueId={memberInfo.unique_id}
         balance={memberInfo.meta.balance}
         bills={memberInfo.bills}
         groupCircles={memberInfo.group_circle}
         handleDelete={onDeleteClick}
      />
   );
}
