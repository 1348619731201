import AccountCard from "features/ui/cards/bank-card";
import { useState } from "react";
import { BiTimer } from "react-icons/bi";
import { IoCopyOutline } from "react-icons/io5";

import { getFormattedAmount } from "utils/lib/number-formatter";

export default function AccountCreated({ amount, accountNo, timer }) {
   const [isCopied, setIsCopied] = useState(false);

   const handleCopy = (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(accountNo);
      setIsCopied(true);

      const timeout = setTimeout(() => {
         setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
   };

   //styles
   const copiedAlertClass = `${
      isCopied ? "opacity-100" : "opacity-0"
   } absolute -bottom-16 text-lg right-1/2 translate-x-1/2 bg-secondary-darker z-50 text-neutral-200 px-6 py-2 rounded-sm transition-all duration-500 ease-in-out`;

   return (
      <section className="flex flex-col items-center justify-center">
         <h1 className="poppins-semibold self-center text-center text-lg text-black-one">
            Account successfully generated
         </h1>
         <p className="poppins-regular text-center text-sm text-black-three">please send exactly</p>
         <p className="poppins-bold mt-3 text-center text-2xl font-semibold text-blue-900">
            {getFormattedAmount(amount)}
         </p>
         <div className=" h-[20px] w-[2px] bg-black-three"></div>
         <p className="poppins-semibold my-[5px] text-xs text-black-three">to</p>
         <div className=" h-[20px] w-[2px] bg-black-three"></div>

         <div className="flex flex-col items-center justify-center">
            <AccountCard
               bankName={accountNo}
               accountNo={"VFD MICROFINANCE BANK"}
               variant={"FUNDING"}
               funding={true}
            />
            <button className="relative px-4">
               <IoCopyOutline
                  onClick={(e) => handleCopy(e)}
                  className="text-black-one"
                  fontSize={30}
               />

               <span role="alert" className={copiedAlertClass}>
                  copied
               </span>
            </button>
         </div>
         <p className="poppins-regular my-[5px] text-center text-xs text-black-three">
            this is a one time account that
            <br /> can only be used for this transaction
         </p>
         <p className="poppins-regular my-[5px] text-center text-lg text-black-one">
            Account Expires In
         </p>
         <BiTimer className="text-[40px] text-black-one md:text-[60px]" />
         <span className="poppins-bold text-lg font-semibold text-black-one">{timer}</span>
      </section>
   );
}
