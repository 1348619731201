import { axios } from "utils/lib/axios";

export const resetPasswordEmail = async (queryObj) => {
   return axios.post("admin/password-reset/email", queryObj);
};

export const resetPasswordOTP = async (queryObj) => {
   return axios.post("admin/password-reset/otp", queryObj);
};

export const resetPasswordNewPassword = async (queryObj) => {
   return axios.patch("admin/password-reset/new-password", queryObj);
};
