import AmountModal from "../modal/amount-modal";
import NumPadModal from "features/numpad/numpad-modal.component";
import BankDetailsDisplay from "./bank-details-display";
import useWithdrawal from "features/withdraw/withdraw.api";
import { useEffect } from "react";

export default function WithdrawModals({
   modalDisplay,
   setModalDisplay,
   modalPosition,
   id = "",
   to,
   toId = "",
   withdrawService,
   mutateFn,
   balance,
   bankDetails,
   changePayoutLink,
   onClose,
   defaultAmountValue,
   disabledAmountInput,
}) {
   const {
      pin,
      setPin,
      state,
      dispatch,
      numpadModal,
      amountModal,
      toggleAmountModal,
      toggleNumpadModal,
      handleNext,
      handleSubmit,
      handleClick,
   } = useWithdrawal({ withdrawService, id, to, toId, mutateFn, setModalDisplay, onClose });

   useEffect(() => {
      if (to !== "external") {
         handleNext();
      }
      // eslint-disable-next-line
   }, [to]);

   if (!bankDetails) return null;

   return (
      <>
         <BankDetailsDisplay
            isOpen={modalDisplay}
            bankDetails={bankDetails}
            handleNext={handleNext}
            onClose={() => setModalDisplay(false)}
            modalPosition={modalPosition}
            hideOnBlur={false}
            showCloseBtn={false}
            changePayoutLink={changePayoutLink}
         />
         <AmountModal
            dispatch={dispatch}
            isOpen={amountModal}
            handleClick={handleClick}
            onClose={toggleAmountModal}
            balance={balance}
            modalPosition={modalPosition}
            hideOnBlur={false}
            showCloseBtn={false}
            defaultValue={defaultAmountValue}
            disabledInput={disabledAmountInput}
         />
         <NumPadModal
            pin={pin}
            setPin={setPin}
            title="transaction pin"
            background="BLUE"
            btnText="proceed"
            btnOnClick={handleSubmit}
            amount={state.formattedAmount}
            message="confirm withdrawal"
            isOpen={numpadModal}
            onClose={toggleNumpadModal}
            modalPosition={modalPosition}
            hideOnBlur={false}
            showCloseBtn={false}
         />
      </>
   );
}
