import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function FinalWelcomeSlide({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/accounts _ user, users, profile, account, man, people, website, browser, webpage 1Admin.png"
         text="With Ledga, the power is in your hands to leverage your organization economy, earn from it and create your own financial system with ease"
         secondaryText="Now, let's setup your account"
         isFinalSlide={true}
         screen={4}
         setScreen={setScreen}
      />
   );
}
