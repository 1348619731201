export const getCombinedMonthsData = (data) => {
   return data
      ?.reduce((result, month) => {
         return [...result, ...month.docs];
      }, [])
      ?.sort((a, b) => {
         const aDate = new Date(a.created);
         const bDate = new Date(b.created);
         return bDate.getTime() - aDate.getTime();
      });
};
