import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import CardItem from "features/ui/cards/card-item";
import { useWindowResize } from "../../utils/hooks/useWindowResize";

export default function BarChartComponent({ className, weekBarChartData }) {
   const { width } = useWindowResize();
   const widthRatio = 0.5 * width;

   return (
      <CardItem className={`${className} mb-6 `}>
         <BarChart
            width={widthRatio < 758 ? widthRatio : 700}
            height={width > 1024 ? 230 : 200}
            barCategoryGap="22%"
            data={weekBarChartData}
         >
            <CartesianGrid stroke="#DADADA" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="amount" fill="#19B83280" />
         </BarChart>
      </CardItem>
   );
}
