import { axiosClient } from "utils/lib/axios";

export const createGroup = (queryObj) => {
   return axiosClient.post("admin/create-group", queryObj);
};

export const updateGroup = (queryObj, id) => {
   return axiosClient.put(`admin/group/${id}`, queryObj);
};

export const deleteGroup = ({ id }) => {
   return axiosClient.delete(`admin/group/${id}`);
};

export const getAllGroupsUrl = "admin/groups";

export const getSingleGroupUrl = (id) => {
   return `admin/group/${id}`;
};

export const updateGroupMembers = (queryObj, id, updateType) => {
   return axiosClient.patch(`admin/group/${id}/members-update?update_type=${updateType}`, queryObj);
};
