/*

*/
export function getDays() {
   const monthDaysArr = Array.from(Array(31), (_, i) => i + 1);

   const weekDaysArr = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
   ];

   const dateConverter = (day) => {
      const dayString = day.toString();
      if (day === 1 || day === 21 || day === 31) {
         return { name: dayString + "st", value: day };
      } else if (day === 2 || day === 22) {
         return { name: dayString + "nd", value: day };
      } else if (day === 3 || day === 23) {
         return { name: dayString + "rd", value: day };
      } else {
         return { name: dayString + "th", value: day };
      }
   };

   const monthDays = monthDaysArr.map((i) => dateConverter(i));

   const weekDays = weekDaysArr.map((day, index) => {
      return { name: day, value: index + 1 };
   });

   return { monthDays, weekDays };
}
