export default function CustomGroupIcon({ className, color = "#1358CC" }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.46 83.78" className={className}>
         <defs>
            <style>{`.cls-groups{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-groups"
                  d="M67.84,83.78a4.54,4.54,0,0,1-4.54-4.54c0-14.65-12.16-26.56-27.11-26.56S9.09,64.59,9.09,79.24a4.55,4.55,0,0,1-9.09,0C0,59.58,16.23,43.59,36.19,43.59s36.19,16,36.19,35.65A4.54,4.54,0,0,1,67.84,83.78Z"
               />
               <path
                  className="cls-groups"
                  d="M36.19,46.33A23.17,23.17,0,1,1,59.36,23.17,23.2,23.2,0,0,1,36.19,46.33Zm0-37.24A14.08,14.08,0,1,0,50.27,23.17,14.09,14.09,0,0,0,36.19,9.09Z"
               />
               <path
                  className="cls-groups"
                  d="M99.92,62a4.55,4.55,0,0,1-4.55-4.54,14.91,14.91,0,0,0-29.81,0,4.54,4.54,0,1,1-9.08,0,24,24,0,0,1,48,0A4.54,4.54,0,0,1,99.92,62Z"
               />
               <path
                  className="cls-groups"
                  d="M80.47,39a16,16,0,1,1,16-16A16,16,0,0,1,80.47,39Zm0-22.89a6.91,6.91,0,1,0,6.9,6.9A6.91,6.91,0,0,0,80.47,16.09Z"
               />
            </g>
         </g>
      </svg>
   );
}
