import useSWR from "swr";

import { getCommissionsUrl } from "./earnings.services";
import { useAdmin } from "features/auth/admin-context";
import { getEarningsBreakDown } from "./earnings.utils";
import { getCombinedMonthsData } from "utils/lib/getCombinedMonthsData";
import { sortByDate } from "utils/lib/getSortedData";
import { getTableData } from "features/ui/tables/table-data";

export default function useEarnings(queryParams) {
   const { admin, adminBankDetails } = useAdmin();

   const { data: fundings, error: earningsError } = useSWR([
      getCommissionsUrl,
      queryParams,
   ]);
   const earningsLoading = !fundings && !earningsError;

   const combinedFundingsData = getCombinedMonthsData(fundings);
   const earningsData = sortByDate(fundings);

   const {
      lifeTimeEarnings,
      thisWeekEarnings,
      weekBarChartData,
      todayEarnings,
   } = getEarningsBreakDown(combinedFundingsData);

   const tableData = getTableData(fundings);

   //available commissions
   const availableBal = admin?.meta?.balance;

   return {
      todayEarnings,
      lifeTimeEarnings,
      thisWeekEarnings,
      earningsLoading,
      earningsError,
      weekBarChartData,
      availableBal,
      tableData,
      earningsData,
      bankDetails: adminBankDetails,
   };
}
