import Button from "features/ui/forms/button";
import PageTitle from "features/ui/layout/headers/page-title";
import ModalContainer from "features/modal/modal-container.component";
import ArrowBack from "features/ui/layout/nav/arrow-back";

export default function DuplicatesFound({
   isOpen,
   duplicateEntries,
   usersType,
   onClose,
}) {
   return (
      <ModalContainer isOpen={isOpen} onClose={onClose}>
         <div className="container py-16 max-w-sm  h-screen justify-center md:h-auto md:max-h-[600px]">
            <ArrowBack handleBack={onClose} />
            <PageTitle
               variant="SECONDARY"
               title="duplicates found"
               message="see below for list of already used email addresses in this upload"
            />
            <div className="overflow-y-scroll self-stretch mb-12 md:py-0">
               {duplicateEntries.map((e, i) => (
                  <div className="w-full mb-1 flex" key={e.name}>
                     <span className="mr-1">{i + 1}.</span>
                     {usersType === "students" ? (
                        <p>{e.name}</p>
                     ) : (
                        <p>{e.email}</p>
                     )}
                  </div>
               ))}
            </div>
            <Button size="WIDE" onClick={onClose}>
               ok
            </Button>
         </div>
      </ModalContainer>
   );
}
