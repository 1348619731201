import { useEffect } from "react";
import { useImageUpload } from "utils/hooks/useImageUpload";

import UserIcon from "../../../assets/icons/LedgaV2 Icons/Profile.png";
import UploadFileIcon from "../../../assets/icons/LedgaV2 Icons/Paper Upload.svg";
import MoonLoader from "react-spinners/MoonLoader";

export default function ImageUpload({
   label,
   errorMessage,
   onSuccess,
   onLoading,
   variant = "FILE",
}) {
   // generate uniqueIds to link label to input otherwise conflicts might ensue
   // when the component is used more than once in a page
   const uniqueId = `file-input-${label.split(" ").join("-")}`;
   const { onChange, loading, fileName, thumbnailUrl, imgUrl } = useImageUpload(onSuccess);

   useEffect(() => {
      if (loading && onLoading) {
         onLoading();
      }
   }, [loading, onLoading]);

   return (
      <div className=" w-full flex flex-col items-center">
         <input accept="image/*" className="hidden" id={uniqueId} onChange={onChange} type="file" />
         {variant === "FILE" ? (
            <FileImage
               uniqueId={uniqueId}
               errorMessage={errorMessage}
               fileName={fileName}
               loading={loading}
               thumbnailUrl={thumbnailUrl}
            />
         ) : (
            <ProfileImage
               uniqueId={uniqueId}
               errorMessage={errorMessage}
               fileName={fileName}
               loading={loading}
               imgUrl={imgUrl}
            />
         )}
         <span className="capitalize text-gray-400">{label}</span>
      </div>
   );
}

const FileImage = ({ uniqueId, errorMessage, fileName, loading, thumbnailUrl }) => {
   return (
      <label htmlFor={uniqueId} className="flex flex-col w-[60px] cursor-pointer ">
         <img src={thumbnailUrl ? thumbnailUrl : UploadFileIcon} alt="upload" />
         <span className={"inline-block truncate w-32 text-yellow-600 text-xs"}>
            {loading ? "loading..." : errorMessage ? "error" : fileName}
         </span>
      </label>
   );
};

const ProfileImage = ({ uniqueId, loading, imgUrl }) => {
   return (
      <label htmlFor={uniqueId} className="h-[200px] flex items-center justify-center">
         {loading ? (
            <MoonLoader size={100} color={"blue"} loading={loading} speedMultiplier={0.5} />
         ) : (
            <img
               src={imgUrl ? imgUrl : UserIcon}
               alt="profile"
               className="h-[200px] w-[200px] object-cover cursor-pointer rounded-full mb-4"
            />
         )}
      </label>
   );
};
