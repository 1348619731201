import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function WelcomeSlideThree({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/hobby _ net, butterfly, catch, activity, hobbies, man, people, outdoors 1Admin.png"
         title="Collect Free Payments"
         text="With Ledga, it is easier to collect payments and levies without worrying about tellers and bank drafts"
         screen={3}
         setScreen={setScreen}
      />
   );
}
