import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useParams } from "react-router-dom";

import PageTitle from "features/ui/layout/headers/page-title";
import SavingsEditorForm from "features/savings/components/savings-editor-form";
import useCreateSavings from "features/savings/api/create-savings.api";

export default function SavingsAccountEdit() {
   const { handleSubmit } = useCreateSavings();
   const { id: savingsId } = useParams();

   return (
      <section className="wrapper">
         <Link to="../../dashboard/savings-accounts">
            <IoArrowBack className="fixed top-10 left-4 w-12 md:w-8 cursor-pointer md:hidden text-4xl" />
         </Link>
         <div className="container left text-primary pt-20 md:max-w-xl">
            <PageTitle
               title="Edit Savings Accounts"
               message="Add Or Remove Bills"
            />

            <div className="self-stretch justify-items-center gap-3 lg:gap-6">
               <SavingsEditorForm
                  handleSubmit={handleSubmit}
                  editingMode="edit"
                  editingId={savingsId}
               />
            </div>
         </div>
      </section>
   );
}
