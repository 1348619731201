const storagePrefix = "ledga_awesome_";

export const getToken = () => {
   return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
};
export const setToken = (token) => {
   window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
};
export const clearToken = () => {
   window.localStorage.removeItem(`${storagePrefix}token`);
};
