import { useEffect, useRef, useState } from "react";
import { read, utils } from "xlsx";
import { getNestedValue } from "./dragUpload";

export function useSingleExcelInputUpload() {
   const { register, data } = useSingleInputUpload();
   const [excelData, setExcelData] = useState();

   useEffect(() => {
      if (!data) {
         return setExcelData(null);
      }
      const workbook = read(data);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const obj = utils.sheet_to_json(sheet);
      const newObj = obj.map((o) => {
         const nestedObj = getNestedValue(o);
         return nestedObj;
      });
      setExcelData(newObj);
   }, [data]);

   return {
      register,
      excelData,
   };
}

export function useSingleInputUpload() {
   const inputRef = useRef();
   const [data, setData] = useState();

   function handleFile() {
      const files = inputRef.current.files;

      const file = files[0];

      const fileReader = new FileReader();

      fileReader.onload = () => {
         setData(fileReader.result);
      };
      fileReader.readAsArrayBuffer(file);
   }

   return {
      data,
      register: () => {
         return {
            ref: inputRef,
            onChange: handleFile,
         };
      },
   };
}
