import Header from "features/ui/layout/headers/dashboard-header";
import GroupEditorForm from "features/groups/components/group-editor-form";
import useAddGroup from "features/groups/api/group-add.api";

export default function GroupAdd() {
   const { handleSubmit } = useAddGroup();

   return (
      <section className="wrapper bg-white">
         <Header title="Create Group" variant="OTHERS" />
         <div className="container-new-gray">
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px] pt-20">
               <GroupEditorForm handleSubmit={handleSubmit} editingMode="create" />
            </div>
         </div>
      </section>
   );
}
