import { useAdmin } from "features/auth/admin-context";
import useTransactions from "features/transactions/api/all-transactions";
import getTransactionsSummary from "utils/lib/getTransactionsSummary";
import { getFormattedAmount } from "utils/lib/number-formatter";
import useDashBoardBills from "./billsInfo";

export default function useDashboardData() {
   const { fundings, payouts } = useTransactions();
   const summaryData = getTransactionsSummary(payouts, fundings);

   //queries
   const { admin, ledgerBal, ledgerData } = useAdmin();
   const { bills } = useDashBoardBills();

   return {
      bills,
      payouts,
      fundings,
      summaryData,
      ledgerData,
      ledgerBal: getFormattedAmount(ledgerBal),
      earningsBal: getFormattedAmount(admin?.meta.balance || 0),
   };
}
