import { useWatch } from "react-hook-form";

export default function NamesSelector({ methods, nameList, fieldName }) {
   const fieldValue = useWatch({ control: methods.control, name: fieldName });

   if (!nameList) {
      return null;
   }

   return (
      <section className="w-full">
         <div className="border p-4 mb-6 rounded-lg flex flex-col items-center">
            {nameList.map((item, idx) => {
               return (
                  <label className="text-base w-full cursor-pointer" key={item.value}>
                     <input
                        className="mr-4"
                        type="checkbox"
                        value={item.value}
                        {...methods.register(fieldName)}
                        checked={fieldValue && fieldValue.includes(item.value)}
                     />
                     <span>{item.name}</span>
                  </label>
               );
            })}
         </div>
      </section>
   );
}
