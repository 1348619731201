//handleSelectChange, takes a value, a hook form select field and hook
//form methods to manually update value and error states as custom the select
//is not an actual input
export const handleMultiSelectChange = (newValue, fieldName, methods) => {
   const { watch, getValues, setValue, setError, clearErrors } = methods;
   watch(fieldName);

   //get prev state
   const prevValue = getValues(fieldName);

   //remove value from multi-select if existing or add if not
   if (prevValue.includes(newValue)) {
      const filteredArr = prevValue.filter((i) => i !== newValue);
      if (filteredArr.length < 1) {
         setError(fieldName, {
            message: "at least one institution type must be selected",
         });
      } else {
         clearErrors(fieldName);
      }
      setValue(fieldName, filteredArr);
   } else {
      setValue(fieldName, [...prevValue, newValue]);
      clearErrors(fieldName);
   }
};

export const handleSelectChange = (newValue, fieldName, methods) => {
   const { watch, setValue, clearErrors } = methods;
   watch(fieldName);
   setValue(fieldName, newValue);
   clearErrors(fieldName);
};

export const institutionOptions = [
   { name: "Corporate", value: "corporate" },
   { name: "Academic", value: "academic" },
];

export const accountOptions = [
   { name: "Individual", value: "individual" },
   { name: "Corporate", value: "corporate" },
];

export const federalStatesOptions = [
   "Abia",
   "Adamawa",
   "Akwa Ibom",
   "Anambra",
   "Bauchi",
   "Bayelsa",
   "Benue",
   "Borno",
   "Cross River",
   "Delta",
   "Ebonyi",
   "Edo",
   "Ekiti",
   "Enugu",
   "Gombe",
   "Imo",
   "Jigawa",
   "Kaduna",
   "Kano",
   "Katsina",
   "Kebbi",
   "Kogi",
   "Kwara",
   "Lagos",
   "Nasarawa",
   "Niger",
   "Ogun",
   "Ondo",
   "Osun",
   "Oyo",
   "Plateau",
   "Rivers",
   "Sokoto",
   "Taraba",
   "Yobe",
   "Zamfara",
   "Abuja F.C.T",
];
