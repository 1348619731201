import { useState, useEffect } from "react";

import Input from "./input";
import FormGroup from "./form-group";

export default function SearchInput({
   value,
   label,
   name,
   icon,
   iconPosition,
   error,
   isEmpty,
   registration,
   variant = "PRIMARYV2",
   options = [],
   handleSelect,
}) {
   const [resultArr, setResultArr] = useState([]);
   const [dropdownShouldDisplay, setDropdownShouldDisplay] = useState("");

   useEffect(() => {
      if (value?.length > 0) {
         const matches = options.filter((i) => i.toLowerCase().startsWith(value.toLowerCase()));

         matches.length > 0
            ? setResultArr([error?.message, ...matches])
            : setResultArr(["no match found"]);

         //show dropdown if value is not an item in matches
         matches.indexOf(value) === -1
            ? setDropdownShouldDisplay(true)
            : setDropdownShouldDisplay(false);
      } else {
         setDropdownShouldDisplay(false);
      }
   }, [value, error, options]);

   //styles
   const searchClass = `${dropdownShouldDisplay ? "block" : "hidden"}
   w-full bg-white absolute z-50 text-primary border border-secondary max-h-[250px] overflow-y-scroll`;

   const errorClass = "whitespace-pre-wrap text-xs pl-1 text-red-500 font-bolder block";

   return (
      <FormGroup className="relative">
         <Input
            label={label}
            variant={variant}
            name={name}
            icon={icon}
            isEmpty={isEmpty}
            iconPosition={iconPosition}
            registration={registration}
         />
         <div className={searchClass}>
            <ul>
               {resultArr.map((element, index) => (
                  <li
                     key={index}
                     className={`py-2 pl-4 cursor-pointer ${
                        index === 0 ? errorClass : "hover:bg-primary-lightest"
                     }`}
                     onClick={() => {
                        handleSelect(element);
                        setDropdownShouldDisplay(false);
                     }}
                  >
                     {element}
                  </li>
               ))}
            </ul>
         </div>
         {error && <span className={`${errorClass} -mt-5 mb-5`}>{error?.message}</span>}
      </FormGroup>
   );
}
