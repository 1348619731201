export default function CustomDebitIcon({ className, color }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.09 86.09" className={className}>
         <defs>
            <style>{`.cls-debit{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-debit"
                  d="M43.05,86.09a43.09,43.09,0,0,1-43-43,5,5,0,1,1,10,0A33,33,0,1,0,43.05,10a5,5,0,1,1,0-10,43,43,0,0,1,0,86.09Z"
               />
               <path
                  className="cls-debit"
                  d="M43.05,51.28a5,5,0,0,1-3.57-1.5L5,14.66A5,5,0,0,1,8,6.18L23.21,4.55a5,5,0,0,1,1.07,9.94l-4.9.53L46.61,42.77a5,5,0,0,1-3.56,8.51Z"
               />
               <path
                  className="cls-debit"
                  d="M6.49,30.65a4.71,4.71,0,0,1-.53,0,5,5,0,0,1-4.45-5.5L3,10.7a5,5,0,1,1,10,1l-1.5,14.43A5,5,0,0,1,6.49,30.65Z"
               />
            </g>
         </g>
      </svg>
   );
}
