import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import Spinner from "features/ui/feedbacks/spinner";
import FormError from "features/ui/forms/form-error";
import { signinSchema } from "../auth.validation";
import { Link } from "react-router-dom";

export default function SigninForm({ signin, signinLoading, signinError, resetError }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         email: "",
         password: "",
      },
   };

   return (
      <>
         <FormError formError={signinError} />
         <Form
            id="signinform"
            options={formOptions}
            schema={signinSchema}
            className="mb-20"
            onSubmit={signin}
            resetFormError={resetError}
         >
            {({ register, formState: { errors, dirtyFields } }) => {
               return (
                  <>
                     <FormGroup className="mb-20">
                        <Input
                           label="email"
                           error={errors.email}
                           isEmpty={!dirtyFields.email}
                           registration={register("email")}
                        />

                        <Input
                           type="password"
                           label="password"
                           error={errors.password}
                           isEmpty={!dirtyFields.password}
                           registration={register("password")}
                        />
                     </FormGroup>
                  </>
               );
            }}
         </Form>
         <div className="mt-12 flex items-center justify-between">
            <Button type="submit" disabled={signinLoading} form="signinform">
               <span className="flex items-center justify-center w-16 h-5 md:w-20 md:h-6 border-box">
                  {signinLoading ? (
                     <Spinner color="WHITE" />
                  ) : (
                     <span className="text-sm md:text-base">sign in</span>
                  )}
               </span>
            </Button>

            <Button
               size="ZERO"
               variant="TERTIARY"
               color="BLUE_TEXT"
               to="/reset-password"
               disabled={signinLoading}
            >
               forgot password?
            </Button>
         </div>
         <div className="self-center flex flex-col gap-1 items-center text-sm mt-6">
            <p>Don't have an account yet?</p>
            <Link to="/signup" className="text-primary underline">
               Sign Up
            </Link>
         </div>
      </>
   );
}
