import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { changeTransactionPin } from "./pin.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useChangePin() {
   const [step, setStep] = useState(0);
   const [pin, setPin] = useState("");
   const [pinConfirmation, setPinConfirmation] = useState("");
   const [previousPin, setPreviousPin] = useState("");
   const [showRequestPinOtp, setShowRequestPinOtp] = useState(false);

   const { queryFn: changePinFn } = useRequest(changeTransactionPin);

   const navigate = useNavigate();
   const reloadPage = () => window.location.reload();

   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const showLoadingModal = () => showModal(MODAL_TYPES.LOADING);

   const handleNext = () => setStep((prev) => prev + 1);
   const handlePrevious = () => setStep((prev) => prev - 1);
   const handleFinish = () => {
      navigate(-1);
      hideModal();
   };

   const handleSubmit = () => {
      if (pin === pinConfirmation) {
         showLoadingModal();
         const queryObj = { old_pin: previousPin, new_pin: pin };
         changePinFn(queryObj, {
            onSuccess: () => {
               hideModal();
               showModal(MODAL_TYPES.SUCCESS, {
                  title: "success",
                  message: "pin changed successfully",
                  btnText: "ok",
                  onClose: handleFinish,
                  btnOnClick: handleFinish,
               });
            },
            onError: (message) => {
               const incorrectPin = message.includes("pin you entered is incorrect.");
               hideModal();
               showModal(MODAL_TYPES.ERROR, {
                  title: incorrectPin && "incorrect pin",
                  message: incorrectPin && message,
                  onClose: reloadPage,
                  btnOnClick: reloadPage,
               });
            },
         });
         setPin("");
         setPreviousPin("");
         setPinConfirmation("");
      }
   };

   return {
      step,
      pin,
      pinConfirmation,
      setPin,
      setPinConfirmation,
      showRequestPinOtp,
      setShowRequestPinOtp,
      previousPin,
      setPreviousPin,
      handleSubmit,
      handleNext,
      handlePrevious,
   };
}
