import { useNavigate, useParams } from "react-router-dom";

import Transfer from "features/add-users/components/transfer";
import useSingleSubAdmin from "features/subadmins/api/subamin-single.api";

export default function SubAdminTransfer() {
   const { id: subAdminIds } = useParams();
   const navigate = useNavigate();
   const [mongoId, uniqueId] = subAdminIds.split("-");
   const { mutateSubAdmin } = useSingleSubAdmin();
   const onClose = () => navigate(`/dashboard/subadmins/${mongoId}`);

   return (
      <Transfer
         mongoId={mongoId}
         uniqueId={uniqueId}
         mutateUserFn={mutateSubAdmin}
         userType="admin"
         onClose={onClose}
      />
   );
}
