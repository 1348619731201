import * as yup from "yup";

import Form from "features/ui/forms/form";
import FormGroup from "features/ui/forms/form-group";

import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import ModalContainer from "features/modal/modal-container.component";
import useRequestResetPinOtp from "./request-reset-otp.api";

export default function RequestPinOtpModal({ onClose, isOpen }) {
   const { handleSubmit } = useRequestResetPinOtp(onClose);
   return (
      <ModalContainer size="THIN" isOpen={isOpen} onClose={onClose} modalPosition="CENTER">
         <div className="container  min-h-[100vh] full-page items-center justify-center md:min-h-0 md:py-24">
            <h2 className="text-2xl mb-10 font-semibold capitalize">Enter Password</h2>
            <ResetPinPasswordForm handleSubmit={handleSubmit} />
         </div>
      </ModalContainer>
   );
}

const ResetPinPasswordForm = ({ handleSubmit }) => {
   const hookFormOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         password: "",
      },
   };

   return (
      <>
         <Form
            id="passwordform"
            schema={requestPinOtpSchema}
            options={hookFormOptions}
            className="self-stretch mb-12"
            onSubmit={handleSubmit}
         >
            {({ register, formState: { errors, dirtyFields } }) => (
               <FormGroup className="mb-6">
                  <Input
                     label="Password"
                     error={errors.password}
                     isEmpty={!dirtyFields.password}
                     registration={register("password")}
                  />
               </FormGroup>
            )}
         </Form>
         <Button type="submit" form="passwordform">
            Request Otp
         </Button>
      </>
   );
};

const FormDetails = () => {};

const requestPinOtpSchema = yup.object().shape({
   password: yup
      .string()
      .required("password cannot be empty")
      .matches(/.{8,}/, "password cannot be less than 8 characters")
      .matches(/[0-9]/, "password must contain at least one digit")
      .matches(/[a-z]/, "password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "password must contain at least one uppercase letter"),
});
