import useBills from "./bills.api";
import useRequest from "utils/hooks/useRequest";
import { createBillAccountV2 } from "features/bills/bills.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useCreateBillNew() {
   const { mutateBills } = useBills();
   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: createBillFn } = useRequest(createBillAccountV2);

   const handleSubmit = (queryObj) => {
      showModal(MODAL_TYPES.LOADING, {
         message: "Wait a moment while we create your unique Bill ID",
         modalPosition: "CENTER",
      });

      const {
         title,
         bank_name: bankName,
         bank_code: bankCode,
         account_name: accountName,
         account_number: accountNumber,
      } = queryObj;

      createBillFn(
         {
            ...queryObj, //
            bankName,
            bankCode,
            accountName,
            accountNumber,
         },
         {
            onSuccess: (bill) => {
               mutateBills();
               showModal(MODAL_TYPES.ID_CREATED, {
                  modalPosition: "CENTER",
                  id: bill.unique_id,
                  title: title,
                  message: `Your unique \n Bill ID has been created for`,
                  to: "/dashboard/bill-accounts",
               });
            },
            onError: (err) => {
               showModal(MODAL_TYPES.ERROR, {
                  message: err,
               });
            },
         }
      );
   };

   return { handleSubmit };
}
