import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const withdrawSchema = yup.object().shape({
   source: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please select a valid source")
      .required("source cannot be empty"),
   sourceId: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please select a withdraw item")
      .required("please select a withdraw item")
      .typeError("please select a withdraw item"),
});

const withdrawUtils = {
   withdrawSchema,
};

export default withdrawUtils;
