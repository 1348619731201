import { useState } from "react";

import RequestActions from "./request-actions";
import TableRow from "features/ui/tables/table-row";
import TableCol from "features/ui/tables/table-col";
import getMonthName from "utils/lib/getMonthName";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { getFormattedDate } from "utils/lib/number-formatter";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function RequestsTableData({ year, month, data }) {
   const monthName = getMonthName(month);
   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const [selectedRequest, setSelectedRequest] = useState("");
   const [actionsModalIsOpen, setActionsModalIsOpen] = useState("");

   const showReceiptModal = (request) => {
      if (request.status === "pending") {
         setSelectedRequest(request);
         setActionsModalIsOpen(true);
      } else {
         showModal(MODAL_TYPES.RECEIPT, {
            id: request._id,
            amount: request.amount,
            fullAmount: request.orginalAmount,
            created: request.created,
            type: request.transaction_type,
            status: request.status,
            reference: request.transaction_reference,
            recipient: {
               uniqueId: request.from,
               ...request.paid_to,
               name: request.paid_to.accountName,
            },
         });
      }
   };

   const getStatusColor = (status) => {
      if (status === "pending") {
         return "text-yellow-500";
      } else if (status === "cancelled") {
         return "text-red-500";
      } else {
         return "text-green-500";
      }
   };
   // styles
   const yearClass = `
      text-2xl 
      font-semibold 
      text-secondary-darker 
      sm:pl-1 
      md:text-3xl
   `;

   return (
      <section className="mb-10" key={year}>
         <h2 className={yearClass}>{year}</h2>
         <h3 className="inline-block self-center rounded-xl bg-blue-600 px-2 font-semibold text-white sm:rounded-3xl sm:px-3  sm:py-1 sm:text-xl lg:text-2xl">
            {monthName}
         </h3>
         <div className="mb-3">
            {data.map((i) => (
               <div key={i._id} onClick={() => showReceiptModal(i)}>
                  <TableRow
                     gridTemplateCols="grid-cols-3 sm:grid-cols-4"
                     key={i._id}
                     className="cursor-pointer hover:bg-blue-50"
                  >
                     <TableCol className="hidden justify-start font-medium text-secondary-darker sm:block">
                        <p className="text-sm font-semibold text-gray-600 sm:text-base lg:text-lg">
                           {i.paid_to.accountNumber}
                        </p>
                        <p className="text-xs text-gray-400">
                           {" "}
                           {i.paid_to.accountName}
                        </p>
                     </TableCol>
                     <TableCol className="justify-start font-medium text-secondary-darker sm:justify-center">
                        {getFormattedDate(i.created)}
                     </TableCol>
                     <TableCol className="justify-center font-medium text-secondary-darker">
                        {getFormattedAmount(i.amount)}
                     </TableCol>

                     <TableCol
                        className={`${getStatusColor(
                           i.status
                        )} justify-end font-medium `}
                     >
                        {i.status}
                     </TableCol>
                  </TableRow>
               </div>
            ))}
         </div>
         <RequestActions
            isOpen={actionsModalIsOpen}
            selectedRequest={selectedRequest}
            onClose={() => setActionsModalIsOpen(false)}
         />
      </section>
   );
}
