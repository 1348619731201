import ResetForm from "./reset-form.component";
import ResetEmailForm from "./email-form.component";
import OTPVerification from "features/misc/components/otp-verification";
import useResetPassword from "features/reset-password/reset-password.api";

export default function ResetPasswordComponent() {
   const {
      step,
      error,
      resetError,
      emailSubmitLoading,
      otpVerificationLoading,
      passwordResetLoading,
      handleOTPChange,
      handleSubmit,
      handleEmailSubmit,
      handleVerification,
   } = useResetPassword();

   switch (step) {
      case 3:
         return <ResetForm handleSubmit={handleSubmit} loading={passwordResetLoading} />;
      case 2:
         return (
            <OTPVerification
               verificationError={error}
               handleChange={handleOTPChange}
               handleVerification={handleVerification}
               verificationLoading={otpVerificationLoading}
            />
         );

      default:
         return (
            <ResetEmailForm
               emailError={error}
               resetError={resetError}
               handleEmailSubmit={handleEmailSubmit}
               emailSubmitLoading={emailSubmitLoading}
            />
         );
   }
}
