import { useParams } from "react-router-dom";

import User from "features/add-users/components/user-details";
import useSingleMerchant from "features/add-users/api/merchant-single.api";

export default function MerchantDetails() {
   const { id: merchantId } = useParams();
   const { merchantInfo } = useSingleMerchant(merchantId);
   if (!merchantInfo) return null;

   return (
      <User
         userType="merchants"
         name={merchantInfo.name}
         userId={merchantId}
         uniqueId={merchantInfo.unique_id}
         balance={merchantInfo.meta.balance}
         bills={merchantInfo.bills}
         groupCircles={merchantInfo.group_circle}
      />
   );
}
