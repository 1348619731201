import { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Controller, useFieldArray, useWatch } from "react-hook-form";

import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import Select from "features/ui/forms/select";
import { savingsSchema } from "../savings.utils";
import RadioBtn from "features/ui/forms/radio-btn";
import FormGroup from "features/ui/forms/form-group";
import useBills from "features/bills/api/bills.api";
import savingsAccountUtils from "../savings.utils";
import useSingleSavings from "../api/single-savings.api";
import PageTitle from "features/ui/layout/headers/page-title";

export default function SavingsEditorForm({ handleSubmit, editingMode, editingId }) {
   const [query] = useSearchParams();
   const targetType = query.get("type");

   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         title: "",
         savings_type: targetType,
         target: "",
         funding_source: [{ bill_id: "", percentage: "" }],
      },
   };

   return (
      <Form
         id="create-savings"
         options={formOptions}
         className="self-stretch mb-24 md:mb-10"
         onSubmit={handleSubmit}
         schema={savingsSchema}
      >
         {(hookFormMethods) => (
            <FormDetails
               hookFormMethods={hookFormMethods}
               editingMode={editingMode}
               editingId={editingId}
               targetType={targetType}
            />
         )}
      </Form>
   );
}

const FormDetails = ({ hookFormMethods, editingMode, editingId, targetType }) => {
   const { bills } = useBills();
   const { savingsInfo } = useSingleSavings(editingId);

   const {
      register,
      control,
      setFocus,
      reset,
      formState: { errors, dirtyFields },
   } = hookFormMethods;

   useEffect(() => {
      if (editingMode === "edit" && savingsInfo && bills) {
         const { funding_source } = savingsInfo;

         const validatedSources = savingsAccountUtils.getValidFundingSources(bills, funding_source);

         setFocus("title");

         reset({
            ...savingsInfo,
            funding_source: validatedSources,
         });
      }
   }, [savingsInfo, bills, editingMode, editingId, reset, setFocus]);

   const [title] = useWatch({
      name: ["title"],
      control,
   });

   if (!bills) {
      return null;
   }

   return (
      <div>
         <PageTitle title={targetType === "cashTarget" ? "Create Cash Stash" : "Create Fixed"} />
         <div className="flex flex-col w-full ">
            <p className="text-primary text-2xl font-semibold">Basic Info</p>
            <p className="text-black-three">Enter basic details</p>
         </div>
         <FormGroup>
            <FormGroup className="mb-6">
               <Input
                  label="savings name"
                  error={errors.title}
                  isEmpty={title.length < 1}
                  registration={register("title")}
                  variant="PRIMARYV2"
               />
            </FormGroup>
            {/* <FormGroup>
               <Input
                  label="Savings Type"
                  error={errors.name}
                  isEmpty={!dirtyFields.type}
                  registration={register("type")}
                  variant="PRIMARYV2"
               />
            </FormGroup> */}
            {targetType === "cashTarget" ? (
               <FormGroup>
                  <Controller
                     control={control}
                     name="target"
                     render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                           prefix={"₦ "}
                           thousandSeparator={true}
                           label="Savings Target"
                           placeholder="₦"
                           isEmpty={dirtyFields?.target}
                           error={errors?.target}
                           customInput={Input}
                           name={name}
                           value={value}
                           onValueChange={(v) => {
                              onChange(Number(v.value));
                           }}
                        />
                     )}
                  />
               </FormGroup>
            ) : // <FormGroup className="mb-6">
            //    <Input
            //       label="savings target"
            //       error={errors.target}
            //       isEmpty={!dirtyFields.target}
            //       registration={register("target")}
            //       variant="PRIMARYV2"
            //    />
            // </FormGroup>

            null}
            {targetType === "dateTarget" ? (
               <div className="flex flex-col mt-[30px] items-start">
                  <p className="text-black-one">Duration</p>
                  <DateTargetInput methods={hookFormMethods} />
               </div>
            ) : null}
            <div className="mt-[50px]">
               <p className="text-2xl">Funding source and percentage</p>
               <p className="text-gray-400 mb-[20px]">Choose Funding source and details</p>
               <FundingSource methods={hookFormMethods} bills={bills} />
            </div>
            <div className="flex justify-center gap-5 md:gap-10">
               <Button
                  color="TRANSPARENT"
                  size="WIDE"
                  form="create-savings"
                  type="button"
                  to="/dashboard/savings-accounts"
               >
                  Cancel
               </Button>
               <Button size="WIDE" form="create-savings" type="submit">
                  Create
               </Button>
            </div>
         </FormGroup>
      </div>
   );
};

const DateTargetInput = ({ methods }) => {
   const filterOptions = ["1 Month", "2 Months", "3 Months", "6 Months"];
   const [selectedFilter, setSelectedFilter] = useState();

   function calculateEpochFromMonths(months) {
      const millisecondsInMonth = 30 * 24 * 60 * 60 * 1000; // assuming 30 days per month
      const currentTime = new Date().getTime(); // current time in milliseconds
      const targetTime = currentTime + months * millisecondsInMonth;
      return Math.floor(targetTime / 1000); // convert to seconds (epoch time)
   }

   const handleRangeSelect = (value) => {
      setSelectedFilter(value);
      const monthsTime = calculateEpochFromMonths(value.split(" ")[0]);
      methods.setValue("target", monthsTime);
   };

   return (
      <div>
         <RadioBtn
            options={filterOptions}
            selected={selectedFilter}
            handleSelect={handleRangeSelect}
         />
      </div>
   );
};

const FundingSource = ({ methods, bills }) => {
   const {
      register,
      control,
      setValue,
      getValues,
      formState: { errors, dirtyFields },
   } = methods;

   const { fields, remove } = useFieldArray({
      name: "funding_source",
      control: control,
   });

   const fundingSource = useWatch({
      name: "funding_source",
      control: control,
   });

   const handleAppend = () => {
      const currentValues = getValues();
      setValue("funding_source", [
         ...currentValues.funding_source,
         { bill_id: "", percentage: "" },
      ]);
   };

   const handleRemove = (index) => {
      remove(index);
   };

   const validateItem = (index, fieldName) => {
      const valuesAtIndex = getValues("funding_source")[index];
      const fieldHasValue = valuesAtIndex[fieldName];
      const anyHasValue = Object.values(valuesAtIndex).some((i) => Boolean(i));

      if (!fieldHasValue && anyHasValue) {
         return false;
      }
      return true;
   };

   const billsOptions = bills.map((b) => ({
      name: `${b.unique_id}:${b.title}`,
      value: b._id,
      disabled: fundingSource.some((bill) => bill.bill_id === b._id),
   }));

   return (
      <FormGroup className="mb-6 bg-white rounded-lg">
         {fields.map((signatory, index) => {
            return (
               <div className="mb-2 px-6 py-4 ">
                  <div className="relative flex gap-3 items-center " key={signatory.id}>
                     {index > 0 && (
                        <span
                           className="absolute text-red-600 right-6 top-6 cursor-pointer"
                           onClick={() => handleRemove(index)}
                        >
                           x
                        </span>
                     )}
                     <FormGroup className="basis-4/5">
                        <Controller
                           control={control}
                           name={`funding_source.${index}.bill_id`}
                           defaultValue=""
                           rules={{
                              validate: () => validateItem(index, "bill_id") || "required",
                           }}
                           render={({ field: { onChange, name, value } }) => (
                              <Select
                                 lineSelect={true}
                                 value={value}
                                 name={name}
                                 label="Bill"
                                 options={billsOptions}
                                 handleChange={(value) => {
                                    onChange(value);
                                 }}
                                 renderSelected={(label, value) => (
                                    <SelectedBill value={value} bills={bills} />
                                 )}
                              />
                           )}
                        />
                     </FormGroup>
                     <FormGroup>
                        <Input
                           variant="LINE"
                           label="Percentage"
                           registration={register(`funding_source.${index}.percentage`, {
                              validate: () => validateItem(index, "percentage") || "value",
                           })}
                           isEmpty={!dirtyFields?.funding_source?.[index]?.percentage}
                           type="number"
                           min="1"
                           max="100"
                        />
                     </FormGroup>
                  </div>
                  <div className="text-xs text-red-500">
                     <p>{errors?.funding_source?.[index]?.bill_id?.message}</p>
                     <p>{errors?.funding_source?.[index]?.percentage?.message}</p>
                  </div>
               </div>
            );
         })}

         <div className="flex justify-center">
            <Button variant="TERTIARY" color="BLUE_TEXT" type="button" onClick={handleAppend}>
               <FaPlusCircle className="text-3xl" />
            </Button>
         </div>
      </FormGroup>
   );
};

const SelectedBill = ({ bills, value }) => {
   const selectedBill = bills.find((b) => b._id === value);
   if (!selectedBill) {
      return <p>Select Bill</p>;
   }

   return (
      <div className="flex text-xs">
         <div>
            <p>{selectedBill.title}</p>
            <p>{selectedBill.unique_id}</p>
         </div>
      </div>
   );
};
