export const getEarningsBreakDown = (fundingsData) => {
   const currentDate = new Date();

   const today = currentDate.getTime();
   const lastMonday = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() + 1
   ).getTime();

   const { sum: todayEarnings } = getPeriodEarnings(today, fundingsData);
   const { sum: lifeTimeEarnings } = getPeriodEarnings("all", fundingsData);
   const { sum: thisWeekEarnings, data: thisWeekEarningsData } =
      getPeriodEarnings(lastMonday, fundingsData);

   const weekBarChartData = getBarChartData(thisWeekEarningsData);

   return {
      lifeTimeEarnings,
      thisWeekEarnings,
      todayEarnings,
      weekBarChartData,
   };
};

//
//
// returns a sum of the earnings from a start period
const getPeriodEarnings = (startPeriod, fundingData) => {
   const getDate = (createdDate) => new Date(createdDate).getTime();

   const filteredEarnings =
      startPeriod === "all"
         ? fundingData
         : fundingData?.filter((e) => {
              return getDate(e.created) >= startPeriod;
           });

   // 50 naira is the commissions amount from each funding transaction
   const sum = filteredEarnings?.reduce((r, e) => {
      return r + 50;
   }, 0);

   return { sum, data: filteredEarnings };
};

//
//
//
const getBarChartData = (transactionData) => {
   const weekArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

   // returning an value from the weekArr extracted with the
   // returned integer value representing the day of the week
   // a transaction took place.
   const getDay = (dateString) => {
      const dateIndex = new Date(dateString).getDay();
      return weekArr[dateIndex];
   };

   const seedData = [
      { name: "Mon", amount: 0 },
      { name: "Tue", amount: 0 },
      { name: "Wed", amount: 0 },
      { name: "Thu", amount: 0 },
      { name: "Fri", amount: 0 },
      { name: "Sat", amount: 0 },
      { name: "Sun", amount: 0 },
   ];

   const transformedData = transactionData?.reduce((r, e) => {
      //check the seed object to find the day that corresponds to the
      // day in which a transaction took place.
      const foundDay = r.find((i) => i.name === getDay(e.created));
      const foundIndex = r.indexOf(foundDay);

      //create updated object by adding the new commissions amount of N50/transaction
      const updatedDay = { ...foundDay, amount: foundDay.amount + 50 };

      // clone the record and update the record at the foundDay index with the
      // new updated object
      const newArr = [...r];
      newArr[foundIndex] = updatedDay;
      return newArr;
   }, seedData);

   return transformedData;
};
