import Button from "features/ui/forms/button";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import ModalContainer from "features/modal/modal-container.component";

export default function EmailSuccess({
   to,
   message,
   successModal,
   toggleSuccessModal,
   modalPosition,
   outsideOnClick,
   btnText,
   btnOnClick,
}) {
   return (
      <ModalContainer
         variant="AUTO"
         modalPosition={modalPosition}
         isOpen={successModal}
         onClose={toggleSuccessModal}
         outsideOnClick={outsideOnClick}
      >
         <div className="flex flex-col items-center py-6 md:py-12">
            <IoCheckmarkCircleOutline className="text-6xl mb-4" />
            <h2 className="capitalize font-semibold text-xl md:text-3xl mb-4">
               email sent
            </h2>
            <p className="text-base md:text-lg text-center text-primary mb-6">
               {message}
            </p>
            <Button onClick={btnOnClick} to={to}>
               {btnText || "home"}
            </Button>
         </div>
      </ModalContainer>
   );
}
