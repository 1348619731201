import { useForm } from "react-hook-form";
import WithdrawToForm from "./withdraw-to-form";
import Button from "features/ui/forms/button";

import ModalContainer from "features/modal/modal-container.component";
import { useNavigate } from "react-router-dom";

export default function WithdrawToModal({ id, title, source, isOpen }) {
   const navigate = useNavigate();
   const methods = useForm({
      defaultValues: {
         to: "external",
      },
   });

   const onSubmit = ({ to }) =>
      navigate(`/dashboard/withdraw/${source}/${to}?sourceId=${id}&destinationId=${to}`);

   return (
      <ModalContainer variant="AUTO" isOpen={isOpen} modalPosition="CENTER">
         <div className="container min-h-0 py-12 lg:py-12">
            <form
               id="withdraw-to-form"
               onSubmit={methods.handleSubmit(onSubmit)}
               className="flex flex-col items-center justify-center"
            >
               <h2 className="text-black-three text-xl">Withdraw from {title}</h2>
               <WithdrawToForm source={source} setValue={methods.setValue} position="CENTER" />
            </form>
            <Button form="withdraw-to-form" type="submit">
               Continue
            </Button>
         </div>
      </ModalContainer>
   );
}
