import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function WelcomeSlideTwo({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/ecology, nature _ gardening, garden, plant, ecological, decor, decoration, woman, people 1Admin.png"
         title="Earn From Your Economy"
         text="Earn commissions every time members fund their Ledga accounts and increase profits"
         screen={2}
         setScreen={setScreen}
      />
   );
}
