import Checkbox from "features/ui/forms/check-box";
import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import FormGroup from "features/ui/forms/form-group";
import Button from "features/ui/forms/button";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import useSingleSubAdmin from "../api/subamin-single.api";
import { subadminSchema } from "../subadmins.utils";
import { ErrorMessage } from "@hookform/error-message";

export default function SubAdminEditorForm({ handleSubmit, editingMode, editingId }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         email: "",
         name: "",
         permissions: {
            add_admin: false,
            create_group_circle: false,
            create_bills: false,
            add_members: false,
            view_balance: false,
            view_transactions: false,
            external_payment: false,
         },
      },
   };

   return (
      <Form
         id="create-subadmins"
         options={formOptions}
         className="self-stretch mb-24 md:mb-10"
         onSubmit={handleSubmit}
         schema={subadminSchema}
      >
         {(hookFormMethods) => (
            <FormDetails
               hookFormMethods={hookFormMethods}
               editingMode={editingMode}
               editingId={editingId}
            />
         )}
      </Form>
   );
}

const FormDetails = ({ hookFormMethods, editingMode, editingId }) => {
   const {
      register,
      control,
      reset,
      getValues,
      setValue,
      formState: { errors },
   } = hookFormMethods;

   const { subAdminInfo } = useSingleSubAdmin(editingId);

   useEffect(() => {
      if (editingMode === "edit" && subAdminInfo) {
         setValue("name", subAdminInfo.name);
         setValue("email", subAdminInfo.email);
         setValue("permissions", subAdminInfo.permissions);
      }
   }, [subAdminInfo, editingMode, editingId, reset]);

   return (
      <FormGroup>
         <h2 className="text-primary text-2xl font-semibold">Basic Info</h2>
         <FormGroup className="mb-6">
            <Input
               label="email"
               error={errors.email}
               isEmpty={false}
               registration={register("email")}
            />
         </FormGroup>

         <FormGroup className="mb-6">
            <Input
               label="name"
               error={errors.name}
               isEmpty={false}
               registration={register("name")}
            />
         </FormGroup>

         <h2 className="text-primary text-2xl font-semibold">Customization</h2>
         <p className="mb-6">Finetune account capabilities</p>
         <ErrorMessage
            errors={errors}
            name="permissions"
            render={({ message }) => <p className="text-red-600">{message}</p>}
         />
         <FormGroup className="mb-6 bg-white rounded-xl p-8">
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.add_admin"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Add Admin"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.create_group_circle"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Create Users Group/Class"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.add_members"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Add Members To Group/Class"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.create_bills"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Create Sub Accounts"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.view_balance"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="View Ledga Balance"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.view_transactions"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="View Ledga Transactions"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
            <FormGroup className="mb-2">
               <Controller
                  control={control}
                  name="permissions.external_payment"
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label="Manage External Payments"
                        handleChange={onChange}
                     />
                  )}
               />
            </FormGroup>
         </FormGroup>

         <div className="flex justify-center">
            <Button size="WIDE" form="create-subadmins" type="submit">
               {editingMode === "create" ? "Add Admin" : "Update Admin"}
            </Button>
         </div>
      </FormGroup>
   );
};
