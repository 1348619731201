import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { requestResetOtp } from "./pin.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useRequestResetPinOtp(closeModal) {
   const { queryFn: requestResetOtpFn } = useRequest(requestResetOtp);

   const navigate = useNavigate();
   const reloadPage = () => window.location.reload();

   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const showLoadingModal = () => showModal(MODAL_TYPES.LOADING);

   const handleFinish = () => {
      navigate("dashboard/settings/reset-pin");
   };

   const handleSubmit = (queryObj, resetForm) => {
      closeModal();
      showLoadingModal();
      requestResetOtpFn(queryObj, {
         onSuccess: () => {
            hideModal();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "success",
               message: "An otp has been sent to your email click continue to reset your pin",
               btnText: "continue",

               onClose: handleFinish,
               btnOnClick: handleFinish,
            });
         },
         onError: () => {
            hideModal();
            showModal(MODAL_TYPES.ERROR, {
               onClose: reloadPage,
               btnOnClick: reloadPage,
            });
         },
      });
      resetForm();
   };

   return {
      handleSubmit,
   };
}
