import { useState } from "react";

import useAllRequests from "./all-requests";
import useRequest from "utils/hooks/useRequest";
import { MdOutlineCancel } from "react-icons/md";
import { acceptPayoutRequest, rejectPayoutRequest } from "../services";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useRequestActions(requestId, closeActionsModal) {
   const { mutateRequests } = useAllRequests();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const [numpadModalIsOpen, setNumpadModalIsOpen] = useState(false);
   const numpadModalOnClose = () => setNumpadModalIsOpen(false);

   const { queryFn: rejectPayoutFn } = useRequest(rejectPayoutRequest);
   const { queryFn: acceptPayoutFn, loading: acceptPayoutIsLoading } =
      useRequest(acceptPayoutRequest);

   const handleNext = () => {
      closeActionsModal();
      setNumpadModalIsOpen(true);
   };

   const handleAcceptPayout = ({ value: transaction_pin }) => {
      const queryObj = { transaction_id: requestId, transaction_pin };

      acceptPayoutFn(queryObj, {
         onSuccess: () => {
            mutateRequests();
            numpadModalOnClose();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "request accepted",
               message: "Payout request has been accepted successfully",
               btnText: "ok",
               btnOnClick: hideModal,
            });
         },

         onError: (message) => {
            numpadModalOnClose();
            if (message.includes("pin you entered is incorrect")) {
               showModal(MODAL_TYPES.ERROR, {
                  title: "incorrect pin",
                  message: `the pin you entered is incorrect \nPlease check your transaction pin and try again`,
               });
            } else if (message.includes("do not have sufficient balance")) {
               showModal(MODAL_TYPES.ERROR, {
                  title: "insufficient funds",
                  message: `recipient no longer have sufficient balance to process this request`,
                  btnText: "back",
               });
            } else {
               showModal(MODAL_TYPES.ERROR, {
                  message: `payout request could not be completed\nplease check your network settings and try again`,
               });
            }
         },
      });
   };

   const handleRejectPayout = () => {
      const queryObj = { transaction_id: requestId };
      closeActionsModal();
      showModal(MODAL_TYPES.LOADING);

      rejectPayoutFn(queryObj, {
         onSuccess: () => {
            mutateRequests();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "request canceled",
               message: "Your payout request has been canceled successfully",
               icon: <MdOutlineCancel />,
               btnText: "ok",
               btnOnClick: hideModal,
            });
         },

         onError: () => {
            showModal(MODAL_TYPES.ERROR, {
               message: `payout request could not be canceled\nplease check your network settings and try again`,
            });
         },
      });
   };

   return {
      handleNext,
      handleRejectPayout,
      handleAcceptPayout,
      acceptPayoutIsLoading,
      numpadModalIsOpen,
      numpadModalOnClose,
   };
}
