import { useParams } from "react-router-dom";

import Header from "features/ui/layout/headers/dashboard-header";
import GroupMembersEditor from "features/groups/components/group-member-editor";
import useSingleGroup from "features/groups/api/group-single.api";

export default function GroupMembersEdit() {
   const { id } = useParams();
   const { groupMembers } = useSingleGroup(id);

   if (!groupMembers) {
      return null;
   }

   return (
      <section className="wrapper bg-white">
         <Header title="" variant="ACCOUNTS" />
         <div className="w-full min-h-screen mx-auto bg-[#F3F3F3] rounded-t-xl">
            <div className="w-5/6 mx-auto text-primary items-stretch md:max-w-[650px] pt-20">
               <h2 className="mb-16 self-stretch mx-auto text-black-one  text-[24px] md:text-[40px] md:pl-0 lg:text-4xl mt-14">
                  Edit Group Members
               </h2>
               <GroupMembersEditor editingId={id} existingGroupMembers={groupMembers} />
            </div>
         </div>
      </section>
   );
}
