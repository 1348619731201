import { Link } from "react-router-dom";

export default function TabSelect({ options }) {
   if (!options) {
      return null;
   }

   return (
      <div className="bg-mainGrey-one shadow-inner shadow-[#f5f5f5] rounded-xl p-2 w-full flex items-center justify-center gap-4 py-4  text-2xl font-semibold text-black-three">
         {options.map((item) => (
            <TabSelectItem
               key={item.title}
               isActive={item.isActive}
               title={item.title}
               href={item.href}
               onClick={item.onClick}
            />
         ))}
      </div>
   );
}

const TabSelectItem = ({ isActive, href, title, onClick }) => {
   if (onClick) {
      return (
         <TabButton isActive={isActive} key={title} onClick={onClick}>
            {title}
         </TabButton>
      );
   }

   return (
      <TabLink isActive={isActive} href={href} key={title}>
         {title}
      </TabLink>
   );
};

const TabLink = ({ children, isActive, href }) => {
   return (
      <Link
         to={href}
         className={`flex items-center justify-center ${
            isActive ? "bg-black-one text-white" : "text-black-three"
         }  w-2/5 rounded-xl poppins-medium text-[16px] px-[10px] py-[3px]`}
      >
         {children}
      </Link>
   );
};

const TabButton = ({ children, isActive, onClick }) => {
   return (
      <button
         type="button"
         onClick={onClick}
         className={`flex items-center justify-center ${
            isActive ? "bg-black-one text-white" : "text-black-three"
         }  w-2/5 rounded-xl poppins-medium text-[16px] px-[10px] py-[3px]`}
      >
         {children}
      </button>
   );
};
