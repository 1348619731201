import { useEffect } from "react";

import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import FormGroup from "features/ui/forms/form-group";
import Button from "features/ui/forms/button";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import usersUtil from "../users.validation";

import useSingleMember from "../api/member-single.api";

export default function MemberEditorForm({ handleSubmit, editingMode, editingId }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         email: "",
         name: "",
         student_info: {
            admission_year: "",
            department: "",
            faculty: "",
            student_id: "",
            session: "",
         },
         staff_info: {
            payout_date: "",
            salary: "",
         },
      },
   };

   return (
      <Form
         id="create-subadmins"
         options={formOptions}
         className="self-stretch mb-24 md:mb-10"
         onSubmit={handleSubmit}
         schema={usersUtil.memberEditSchema}
      >
         {(hookFormMethods) => (
            <FormDetails
               hookFormMethods={hookFormMethods}
               editingMode={editingMode}
               editingId={editingId}
            />
         )}
      </Form>
   );
}

const FormDetails = ({ hookFormMethods, editingMode, editingId }) => {
   const {
      register,
      reset,
      setValue,
      formState: { errors },
   } = hookFormMethods;

   const { memberInfo } = useSingleMember(editingId);

   useEffect(() => {
      if (editingMode === "edit" && memberInfo) {
         setValue("name", memberInfo.name);
         setValue("email", memberInfo.email);
         setValue("student_info", memberInfo.student_info);
         setValue("staff_info", memberInfo.staff_info);
      }
   }, [memberInfo, editingMode, editingId, reset]);

   return (
      <FormGroup>
         <h2 className="text-primary text-2xl font-semibold">Basic Info</h2>
         <FormGroup className="mb-6">
            <Input
               label="email"
               error={errors.email}
               isEmpty={false}
               registration={register("email")}
            />
         </FormGroup>

         <FormGroup className="mb-6">
            <Input
               label="name"
               error={errors.name}
               isEmpty={false}
               registration={register("name")}
            />
         </FormGroup>

         <MemberDetailsForm methods={hookFormMethods} memberType={memberInfo?.member_type} />

         <div className="flex justify-center">
            <Button size="WIDE" form="create-subadmins" type="submit">
               {editingMode === "create" ? "Add Member" : "Update Member"}
            </Button>
         </div>
      </FormGroup>
   );
};

const MemberDetailsForm = ({ methods, memberType }) => {
   if (!memberType) {
      return null;
   }

   if (memberType === "staff") {
      return <StaffInfoForm methods={methods} />;
   }

   return <StudentInfoForm methods={methods} />;
};

const StudentInfoForm = ({ methods }) => {
   const {
      register,
      formState: { errors },
   } = methods;

   return (
      <>
         <h2 className="text-primary text-2xl font-semibold">Member Details</h2>
         <FormGroup className="mb-6">
            <Input
               label="Admission Year"
               error={errors.student_info?.admission_year}
               isEmpty={false}
               registration={register("student_info.admission_year")}
            />
         </FormGroup>
         <FormGroup className="mb-6">
            <Input
               label="Department"
               error={errors.student_info?.department}
               isEmpty={false}
               registration={register("student_info.department")}
            />
         </FormGroup>
         <FormGroup className="mb-6">
            <Input
               label="Faculty"
               error={errors.student_info?.faculty}
               isEmpty={false}
               registration={register("student_info.faculty")}
            />
         </FormGroup>
         <FormGroup className="mb-6">
            <Input
               label="Student Id"
               error={errors.student_info?.student_id}
               isEmpty={false}
               registration={register("student_info.student_id")}
            />
         </FormGroup>
         <FormGroup className="mb-6">
            <Input
               label="Session"
               error={errors.student_info?.session}
               isEmpty={false}
               registration={register("student_info.session")}
            />
         </FormGroup>
      </>
   );
};

const StaffInfoForm = ({ methods }) => {
   const {
      control,
      formState: { errors, dirtyFields },
   } = methods;

   return (
      <>
         <h2 className="text-primary text-2xl font-semibold">Staff Details</h2>
         <Controller
            control={control}
            name="staff_info.payout_date"
            defaultValue=""
            render={({ field: { onChange, name, value } }) => (
               <NumberFormat
                  customInput={Input}
                  isEmpty={value.length < 1}
                  format="##-##-####"
                  mask={["d", "d", "m", "m", "y", "y", "y", "y"]}
                  placeholder="dd-mm-yyyy"
                  error={errors.staff_info?.payout_date}
                  label="Payout Date"
                  name={name}
                  value={value}
                  onValueChange={(v) => {
                     onChange(v.formattedValue);
                  }}
               />
            )}
         />
         <FormGroup className="mb-6">
            <FormGroup>
               <Controller
                  control={control}
                  name="staff_info.salary"
                  render={({ field: { onChange, name, value } }) => (
                     <NumberFormat
                        prefix={"₦ "}
                        thousandSeparator={true}
                        label="Salary"
                        placeholder="₦"
                        isEmpty={dirtyFields?.staff_info?.salary}
                        error={errors?.staff_info?.salary}
                        customInput={Input}
                        name={name}
                        value={value}
                        onValueChange={(v) => {
                           onChange(Number(v.value));
                        }}
                     />
                  )}
               />
            </FormGroup>
         </FormGroup>
      </>
   );
};
