import { useState } from "react";
import { useFormData } from "utils/hooks/useFormData";
import useRequest from "utils/hooks/useRequest";

import { useGlobalModalContext } from "features/modal/global-modal.context";
import { useNavigate } from "react-router-dom";

export default function useWithdrawal({
   withdrawService,
   id = "",
   to,
   toId = "",
   mutateFn,
   setModalDisplay,
   modalPosition = "CENTER",
   onClose,
}) {
   const navigate = useNavigate();

   //form states
   const initialState = { amount: "", formattedAmount: "" };
   const [state, dispatch] = useFormData(initialState);
   const [pin, setPin] = useState("");

   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   //modal states
   const [numpadModal, setNumpadModal] = useState(false);
   const [amountModal, setAmountModal] = useState(false);

   const toggleNumpadModal = () => setNumpadModal((prev) => !prev);
   const toggleAmountModal = () => setAmountModal((prev) => !prev);

   //queries
   const { queryFn: withdrawFn } = useRequest(withdrawService, id, to, toId);

   //handle functions
   const handleNext = () => {
      setModalDisplay(false);
      setAmountModal(true);
   };

   const handleClick = () => {
      setAmountModal(false);
      setNumpadModal(true);
   };

   const handleClose = () => {
      onClose && onClose();
      hideModal();
   };

   const handleError = () => {
      hideModal();
      navigate("/dashboard");
   };

   const handleSubmit = () => {
      setNumpadModal(false);
      showModal(MODAL_TYPES.LOADING, { modalPosition });

      const queryObj = { amount: state.amount, transaction_pin: pin };
      withdrawFn(queryObj, {
         onSuccess: () => {
            mutateFn && mutateFn();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Payout Request Successful",
               message: "",
               hideOnBlur: false,
               btnText: "OK",
               modalPosition,
               onClose: handleClose,
               btnOnClick: handleClose,
            });
         },
         onError: (message) => {
            if (message.includes("pin you entered is incorrect!")) {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition,
                  message: `the pin you entered is incorrect \nPlease check your transaction pin and try again`,
                  onClose: handleError,
                  btnOnClick: handleError,
               });
            } else if (message.includes("do not have sufficient balance")) {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition,
                  message: `you do not have sufficient balance to process this request`,
                  onClose: handleError,
                  btnOnClick: handleError,
               });
            } else {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition,
                  message: `payout request could not be completed\nplease check your network settings and try again`,
                  onClose: handleError,
                  btnOnClick: handleError,
               });
            }
         },
      });
      setPin("");
      dispatch({ type: "CLEAN_SLATE", payload: initialState });
   };

   return {
      pin,
      setPin,
      state,
      dispatch,
      numpadModal,
      amountModal,
      toggleAmountModal,
      toggleNumpadModal,
      handleNext,
      handleSubmit,
      handleClick,
   };
}
