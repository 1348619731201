import React from "react";

const CustomWithdrawIcon = ({ className, color }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.41 89.84" className={className}>
         <defs>
            <style>{`.cls-withdraw{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-withdraw"
                  d="M64.1,86H34.7a4.09,4.09,0,1,1,0-8.17H64.1A20.17,20.17,0,0,0,84.24,57.71V28.31A20.16,20.16,0,0,0,64.1,8.18H34.7A20.15,20.15,0,0,0,14.57,28.31v29.4a4.09,4.09,0,0,1-8.18,0V28.31A28.34,28.34,0,0,1,34.7,0H64.1A28.34,28.34,0,0,1,92.41,28.31v29.4A28.34,28.34,0,0,1,64.1,86Z"
               />
               <path
                  className="cls-withdraw"
                  d="M4.09,89.84a4.09,4.09,0,0,1-2.86-7L39.36,45.52a4.09,4.09,0,0,1,5.72,5.85L7,88.67A4.08,4.08,0,0,1,4.09,89.84Z"
               />
               <path
                  className="cls-withdraw"
                  d="M21.46,89.84H4.09A4.09,4.09,0,0,1,0,85.75V68.69a4.09,4.09,0,0,1,8.18,0v13H21.46a4.09,4.09,0,0,1,0,8.18Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomWithdrawIcon;
