import Home from "pages";
import SetUp from "pages/setup";
import CreatePin from "pages/create-pin";
import Dashboard from "features/dashboard/dashboard-nav";
import DashboardHome from "pages/dashboard";
import Requests from "pages/requests";
import Transactions from "pages/transactions";
import Settings from "pages/settings-page";
import SetPayoutModal from "pages/set-payout";
import SetBankDetailsModal from "pages/set-bank-details";
import ChangePin from "pages/change-pin";
import ContactUs from "pages/contact-us";
import SetSpendingLimitModal from "pages/set-spending-limit";
// import ResetPassword from "pages/reset-password";
import BillAccounts from "pages/bill-accounts-page";
import AccountDetails from "pages/bill-details";
import Members from "pages/members-page";
import MemberDetails from "pages/member-id-page";
import AddMembers from "pages/members-add-page";
import Merchants from "pages/merchants-page";
import MerchantDetails from "pages/merchant-id-page";
import AddMerchants from "pages/merchants-add-page";
import Earnings from "pages/earnings";
import { AdminContextProvider } from "features/auth/admin-context";
import SavingsAccounts from "pages/savings-accounts";
import SavingsAccountDetails from "pages/savings-details";
import SavingsAccountCreate from "pages/savings-account-create";
import SavingsAccountEdit from "pages/savings-account-edit";
import SubAdmins from "pages/sub-admins-page";
import SubAdminDetails from "pages/sub-admin-id-page";
import SubAdminCreate from "pages/sub-admins-add-page";
import SubAdminEdit from "pages/sub-admins-edit-page";
import CreateBill from "pages/bill-create";
import Groups from "pages/groups-page";
import GroupDetails from "pages/group-id-page";
import AddGroups from "pages/groups-add-page";
import Withdraw from "pages/withdraw";
import WithdrawDetails from "pages/withdraw-details";
import EditBill from "pages/bill-edit";
import FundAccountCreate from "pages/fund-account-create";
import FundAccountActions from "pages/fund-account-actions";
import MemberTransfer from "pages/member-transfer-page";
import MerchantTransfer from "pages/merchant-transfer-page";
import GroupEdit from "pages/groups-edit-page";
import GroupMembersEdit from "pages/group-members-edit";
import SubAdminTransfer from "pages/sub-admin-transfer";
import MemberEdit from "pages/members-edit-page";

export const protectedRoutes = [
   {
      element: <AdminContextProvider />,
      children: [
         { path: "/", element: <Home /> },
         {
            path: "setup",
            element: <SetUp />,
         },
         {
            path: "create-pin",
            element: <CreatePin />,
         },
         { path: "accounts/funding/create", element: <FundAccountCreate /> },
         { path: "accounts/funding/actions", element: <FundAccountActions /> },
         {
            path: "dashboard",
            element: <Dashboard />,
            children: [
               {
                  index: true,
                  element: <DashboardHome />,
               },
               { path: "transactions", element: <Transactions /> },
               { path: "requests", element: <Requests /> },
               { path: "groups", element: <Groups /> },
               { path: "groups/:id", element: <GroupDetails /> },
               { path: "groups/add", element: <AddGroups /> },
               { path: "groups/:id/edit", element: <GroupEdit /> },
               { path: "groups/:id/members-edit", element: <GroupMembersEdit /> },
               { path: "members", element: <Members /> },
               { path: "members/add", element: <AddMembers /> },
               { path: "members/:id", element: <MemberDetails /> },
               { path: "members/:id/transfer", element: <MemberTransfer /> },
               { path: "members/:id/edit", element: <MemberEdit /> },
               { path: "merchants", element: <Merchants /> },
               { path: "merchants/add", element: <AddMerchants /> },
               { path: "merchants/:id", element: <MerchantDetails /> },
               { path: "merchants/:id/transfer", element: <MerchantTransfer /> },
               { path: "subadmins", element: <SubAdmins /> },
               { path: "subadmins/add", element: <SubAdminCreate /> },
               { path: "subadmins/:id", element: <SubAdminDetails /> },
               { path: "subadmins/:id/edit", element: <SubAdminEdit /> },
               { path: "subadmins/:id/transfer", element: <SubAdminTransfer /> },
               { path: "earnings", element: <Earnings /> },
               { path: "bill-accounts", element: <BillAccounts /> },
               { path: "bill-accounts/create", element: <CreateBill /> },
               { path: "bill-accounts/:id", element: <AccountDetails /> },
               { path: "bill-accounts/:id/edit", element: <EditBill /> },
               {
                  path: "savings-accounts",
                  children: [
                     {
                        index: true,
                        element: <SavingsAccounts />,
                     },
                     {
                        path: "create",
                        element: <SavingsAccountCreate />,
                     },
                     {
                        path: ":id",
                        element: <SavingsAccountDetails />,
                     },
                     {
                        path: ":id/edit",
                        element: <SavingsAccountEdit />,
                     },
                  ],
               },

               { path: "withdraw", element: <Withdraw /> },
               { path: "withdraw/:source/:destination", element: <WithdrawDetails /> },

               {
                  path: "settings",
                  children: [
                     { index: true, element: <Settings /> },
                     { path: "change-pin", element: <ChangePin /> },
                     { path: "bank-details", element: <SetBankDetailsModal /> },
                     {
                        path: "spending-limit",
                        element: <SetSpendingLimitModal />,
                     },
                     { path: "set-payout", element: <SetPayoutModal /> },
                     // { path: "reset-password", element: <ResetPassword /> },
                     { path: "contact-us", element: <ContactUs /> },
                  ],
               },
            ],
         },
      ],
   },
];
