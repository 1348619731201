export default function CustomMembersIcon({ className, color = "#1358CC" }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.83 87.85" className={className}>
         <defs>
            <style>{`.cls-members{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-members"
                  d="M71.28,87.85a4.55,4.55,0,0,1-4.54-4.55c0-15.58-12.93-28.25-28.83-28.25S9.09,67.72,9.09,83.3A4.55,4.55,0,1,1,0,83.3C0,62.71,17,46,37.91,46S75.83,62.71,75.83,83.3A4.55,4.55,0,0,1,71.28,87.85Z"
               />
               <path
                  className="cls-members"
                  d="M37.91,48.36A24.18,24.18,0,1,1,62.09,24.18,24.21,24.21,0,0,1,37.91,48.36Zm0-39.27A15.1,15.1,0,1,0,53,24.18,15.11,15.11,0,0,0,37.91,9.09Z"
               />
            </g>
         </g>
      </svg>
   );
}
