import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

const billSchema = yup.object().shape({
   title: yup
      .string()
      .matches(REGEX.ALPHANUMERIC_NAME_VALIDATION, "please enter a valid bill name")
      .required("bill name cannot be empty"),
   amount: yup
      .number("please enter a valid amount")
      .positive("please enter a valid amount")
      .required("amount cannot be empty")
      .typeError("please enter a valid amount"),
   manager_access: yup
      .array()
      .of(
         yup.object().shape({
            admin_id: yup.string().required(),
            permissions: yup.object().shape({
               withdraw: yup.bool(),
               edit_account: yup.bool(),
            }),
         })
      )
      .compact((v) => !v.admin_id),
   withdrawal_permissions: yup
      .object()
      .shape({
         signOff: yup.bool().required(),
         authorisers: yup.mixed("please enter a valid amount").when("signOff", {
            is: true,
            then: yup.array("please enter a valid amount").of(yup.string()),
         }),
      })
      .required(),
   create_wallet: yup.bool(),
   dynamic_bill: yup.bool(),
   allow_part_payment: yup.bool().required(),
   minimum_amount: yup.mixed("please enter a valid amount").when("allow_part_payment", {
      is: true,
      then: yup
         .number("please enter a valid amount")
         .positive("please enter a valid amount")
         .required("minimum amount cannot be empty")
         .lessThan(yup.ref("amount"), "cannot be more than amount")
         .typeError("please enter a valid amount"),
   }),
   addWithdrawBank: yup.bool(),
   bank_name: yup.string().required("bank name cannot be empty"),
   bank_code: yup.string().required("bank code cannot be empty"),
   account_name: yup.string().required("acount name cannot be empty"),
   account_number: yup.string().required("account number cannot be empty"),
   groups: yup.array().of(yup.string()),
});

const billUtils = {
   billSchema,
};

export default billUtils;
