import { useState } from "react";
import { axiosClient } from "utils/lib/axios";

export const useImageUpload = (onSuccess) => {
   const [data, setData] = useState();
   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);
   const [fileName, setFileName] = useState("");

   // save image urls
   const [thumbnailUrl, setThumbnailUrl] = useState("");
   const [imgUrl, setImgUrl] = useState("");

   const handleImage = async (event) => {
      const file = event.target.files?.[0];
      if (file) {
         setLoading(true);
         const base64 = await convertBase64(file);
         const res = await axiosClient.post("admin/upload-image", {
            image: base64,
            file_name: file.name,
         });

         if (res.data.message === "Image Uploaded") {
            setData(res.data.data);
            onSuccess && onSuccess(res.data.data.url, base64);
            setFileName(file?.name ?? "file");
            setLoading(false);

            console.log("This is the img url: ", res.data.data.url);
            // set thumbnail url
            setThumbnailUrl(res.data.data.thumbnailUrl);
            // set image url
            setImgUrl(res.data.data.url);
         } else {
            setFileName("Error");
            setError("something went wrong");
            setLoading(false);
         }
      }
   };

   return {
      onChange: handleImage,
      loading,
      error,
      data,
      fileName,
      thumbnailUrl,
      imgUrl,
   };
};

const convertBase64 = (file) => {
   return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
         resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
         reject(error);
      };
   });
};
