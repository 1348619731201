import Signup from "pages/signup";
import Signin from "pages/signin";
import Welcome from "pages/welcome";
import ResetPassword from "pages/reset-password";
import EmailVerification from "pages/email-verification";

export const publicRoutes = [
   { path: "signin", element: <Signin /> },
   { path: "signup", element: <Signup /> },
   { path: "welcome", element: <Welcome /> },
   { path: "email-verification/:userId", element: <EmailVerification /> },
   { path: "reset-password", element: <ResetPassword /> },
];
