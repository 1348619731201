import NotificationIcon from "assets/icons/LedgaV2 Icons/notificationIcon";

export default function SidebarRight({ children }) {
   return (
      <aside className="hidden fixed right-0 z-10  justify-between md:block w-full md:top-0 bottom-0 md:h-screen  md:p-6 bg-white transition dark:bg-[white] px-2 md:px-3 dark:border-none md:w-[100px] lg:w-[200px]">
         <div className="w-full flex justify-center cursor-pointer">
            <NotificationIcon />
         </div>
         {children}
      </aside>
   );
}
