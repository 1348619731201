import useSWR from "swr";
import { getAllSubAdminsForBillsUrl } from "../subadmins.service";
import { useState } from "react";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useSubAdminsList() {
   const [subAdminNames, setSubAdminNames] = useState([]);
   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const {
      data: subadminsPageRes,
      error: getAllSubAdminsError,
      mutate: mutateSubAdmins,
   } = useSWR([getAllSubAdminsForBillsUrl, "", "V2"], {
      onSuccess: (data) => {
         const tweakedData = data.subAdmins
            .flatMap((obj) => obj.docs)
            .map((subadmin) => {
               const name = subadmin.name !== undefined ? subadmin.name : "Super Admin";
               return {
                  name: name,
                  value: `${subadmin.id}`,
               };
            });
         setSubAdminNames(tweakedData);
      },
      onError: () => {
         showModal(MODAL_TYPES.ERROR, {
            modalPosition: "CENTER",
            hideOnBlur: false,
            btnOnClick: () => window.location.reload(),
         });
      },
   });

   const getAllSubAdminsLoading = !subadminsPageRes && !getAllSubAdminsError;

   return {
      subadmins: subadminsPageRes?.subAdmins,
      subAdminNames,
      getAllSubAdminsLoading,
      mutateSubAdmins,
   };
}
