import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import Spinner from "features/ui/feedbacks/spinner";
import { signupSchema } from "../auth.validation";
import useSignup from "../api/signup";
import FormError from "features/ui/forms/form-error";

const ErrorComponent = ({ message }) => {
   const errorClass = `whitespace-pre-wrap text-xs pl-1 text-red-500 font-bolder -mt-5 mb-5`;
   return (
      <p className={message && errorClass} role="alert" aria-label={message}>
         {message}
      </p>
   );
};

export default function SignupForm() {
   const { signup, signupLoading, signupError, resetError } = useSignup();

   const hookFormOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         email: "",
         password: "",
         passwordConfirmation: "",
      },
   };

   return (
      <>
         <FormError formError={signupError} />
         <Form
            schema={signupSchema}
            id="signupform"
            options={hookFormOptions}
            className="mb-10"
            onSubmit={signup}
            resetFormError={resetError}
         >
            {({ register, getValues, formState: { errors, dirtyFields } }) => {
               return (
                  <>
                     <FormGroup>
                        <Input
                           label="email"
                           error={errors.email}
                           isEmpty={!dirtyFields.email}
                           registration={register("email")}
                        />
                     </FormGroup>

                     <FormGroup>
                        <Input
                           type="password"
                           label="password"
                           isEmpty={!dirtyFields.password}
                           registration={register("password")}
                        />
                        <div>
                           {errors?.password?.type === "required" ? (
                              <ErrorComponent
                                 message={errors?.password?.message}
                              />
                           ) : (
                              []
                                 .concat(errors?.password?.types?.matches)
                                 .map((errorMessage, i) => (
                                    <ErrorComponent
                                       key={"errorMessage" + i}
                                       message={errorMessage}
                                    />
                                 ))
                           )}
                           {/*concat into an empty array because error.matches can
                              either return a string or an array */}
                        </div>
                     </FormGroup>

                     <FormGroup>
                        <Input
                           type="password"
                           label="password confirmation"
                           isEmpty={!dirtyFields?.passwordConfirmation}
                           registration={register("passwordConfirmation")}
                        />
                        {getValues("password") !==
                           getValues("passwordConfirmation") && (
                           <ErrorComponent
                              message={errors?.passwordConfirmation?.message}
                           />
                        )}
                     </FormGroup>
                  </>
               );
            }}
         </Form>

         <div className="flex items-center justify-between">
            <Button type="submit" disabled={signupLoading} form="signupform">
               <span className="flex items-center justify-center w-16 h-5 md:w-20 md:h-6 border-box">
                  {signupLoading ? (
                     <Spinner color="WHITE" />
                  ) : (
                     <span className="text-sm md:text-base">sign up</span>
                  )}
               </span>
            </Button>

            <Button
               to="../signin"
               variant="TERTIARY"
               color="BLUE_TEXT"
               size="ZERO"
               disabled={signupLoading}
            >
               Already have an <br />
               account, Sign in
            </Button>
         </div>
      </>
   );
}
