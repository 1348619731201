import { RiErrorWarningLine } from "react-icons/ri";
import { classNames } from "utils/lib/getClassName";

const VARIANT_MAPS = {
   MODERATE: "text-orange-600 border-orange-600",
   SEVERE: "text-red-700 border-red-700",
};

const SIZE_MAPS = {
   MEDIUM: "text-2xl sm:text-4xl",
   SMALL: "text-lg sm:text-2xl",
};

export default function Alert({
   message,
   variant = "SEVERE",
   size = "MEDIUM",
   children,
}) {
   if (!message && !children) {
      return <></>;
   }

   const alertClass = classNames(
      "flex w-full items-center border-l-4 pl-4 text-sm sm:text-base mb-4",
      VARIANT_MAPS[variant]
   );

   const iconClass = classNames(SIZE_MAPS[size]);

   return (
      <div role="alert" className={alertClass}>
         <RiErrorWarningLine className={iconClass} />
         <span className="pl-2">{message}</span>
         {children}
      </div>
   );
}
