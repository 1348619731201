import FailModal from "features/ui/feedbacks/failure-modal";
import Spinner from "features/ui/feedbacks/spinner";
import DayRow from "features/transactions/components/billday";

export default function AccountsTable({
   toggleModal,
   billTransactions,
   billTransactionsError,
   billTransactionsLoading,
   mutateBill,
}) {
   if (billTransactionsLoading) {
      return (
         <div className="flex items-center justify-center my-32">
            <Spinner color="GREY" size="MEDIUM" />
         </div>
      );
   }

   if (billTransactionsError) {
      return <FailModal isOpen={billTransactionsError} btnOnClick={mutateBill} />;
   }
   return (
      <div className="mx-auto w-5/6 mb-52 text-base sm:max-w-lg sm:text-lg  md:max-w-3xl md:text-xl">
         {/* <TableHeader
            toggleModal={toggleModal}
            gridTemplateCols="grid-cols-custom-table-1"
            firstCol={"name"}
            otherCols={["date", "all"]}
            lastCol={"amount"}
         /> */}
         {Object.keys(billTransactions).map((day) => {
            return (
               <section className="mb-10 " key={day}>
                  <h2 className="text-[15px] md:text-[20px] mb-[10px] font-semibold text-secondary-darker sm:pl-1 ">
                     {day}
                  </h2>
                  <div className="bg-white px-4 shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 rounded-[10px]">
                     {billTransactions[day].map((daily, index) => (
                        <DayRow key={index} day={daily} />
                     ))}
                  </div>
               </section>
            );
         })}
      </div>
   );
}
