import { axios } from "utils/lib/axios";

export const createTransactionPin = async (queryObj) => {
   return axios.post("admin/transaction-pin", queryObj);
};

export const changeTransactionPin = async (queryObj) => {
   return axios.put("admin/transaction-pin", queryObj);
};

export const requestResetOtp = async (queryObj) => {
   return axios.post("admin/request-pin-reset-otp", queryObj);
};
