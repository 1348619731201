import useSWR from "swr";
import { useEffect, useState } from "react";

import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useUsers(serviceUrl, apiVersion = "V1") {
   const [usersData, setUsersData] = useState([]);
   const [usersTableData, setUsersTableData] = useState([]);
   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const {
      data: allUsersData,
      error: usersDataError,
      mutate: mutateUsers,
   } = useSWR(getVersionedApiService(serviceUrl, apiVersion), {
      onError: () => showModal(MODAL_TYPES.ERROR),
      onSuccess: (data) => {
         if (!data) showModal(MODAL_TYPES.ERROR);
      },
   });

   const usersDataIsLoading = !allUsersData && !usersDataError;

   useEffect(() => {
      if (allUsersData) {
         const allUsersArr = allUsersData.members;
         const finalResult = allUsersArr;
         const transformedResult = finalResult?.map((e) => {
            const { name, uniqueId } = e;
            return [uniqueId, name];
         });
         setUsersData(finalResult);
         setUsersTableData(transformedResult);
      }
      // eslint-disable-next-line
   }, [allUsersData]);

   return {
      usersData,
      usersDataIsLoading,
      usersDataError,
      usersTableData,
      mutateUsers,
      totalCount: allUsersData?.totalCount,
      totalPages: allUsersData?.totalPages,
   };
}

function getVersionedApiService(serviceUrl, apiVersion) {
   return apiVersion === "V2" ? [serviceUrl, "", "V2"] : serviceUrl;
}
