import { useEffect, useState } from "react";
import { read, utils } from "xlsx";

export function useSingleExcelDragUpload() {
   const { register, dragState, data } = useSingleDragUpload();
   const [excelData, setExcelData] = useState();

   useEffect(() => {
      if (!data) {
         setExcelData(null);
         return;
      }

      const workbook = read(data);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const obj = utils.sheet_to_json(sheet);
      const newObj = obj.map((o) => {
         const nestedObj = getNestedValue(o);
         return nestedObj;
      });
      setExcelData(newObj);
   }, [data]);

   return {
      register,
      dragState,
      excelData,
   };
}

export function useSingleDragUpload() {
   const [dragState, setDragState] = useState("leave");
   const [data, setData] = useState(null);

   function dropHandler(ev) {
      setDragState("leave");

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();

      if (ev.dataTransfer.items) {
         if (ev.dataTransfer.items.length !== 1) return;
         const item = [...ev.dataTransfer.items][0];

         if (item.kind !== "file") return;

         const file = item.getAsFile();
         handleStream(file.stream());
      } else {
         if (ev.dataTransfer.files.length !== 1) return;

         const file = [...ev.dataTransfer.files][0];
         handleStream(file.stream());
      }
   }

   async function handleStream(stream) {
      setData(await readStream(stream));
   }

   function dragOverHandler(ev) {
      ev.preventDefault();
   }

   async function readStream(stream) {
      /* collect data */
      const buffers = [];
      const reader = stream.getReader();
      while (true) {
         const res = await reader.read();
         if (res.value) buffers.push(res.value);
         if (res.done) break;
      }

      /* concat */
      const size = buffers.reduce((acc, v) => acc + v.length, 0);
      const out = new Uint8Array(size);

      let off = 0;
      for (const u8 of buffers) {
         out.set(u8, off);
         off += u8.length;
      }

      return out;
   }

   return {
      register: () => {
         return {
            onDrop: dropHandler,
            onDragEnter: () => setDragState("enter"),
            onDragLeave: () => setDragState("leave"),
            onDragOver: dragOverHandler,
         };
      },
      dragState,
      data,
   };
}

export const getNestedValue = (obj) => {
   for (const [key, value] of Object.entries(obj)) {
      const keySplit = key.split(".");

      if (keySplit.length === 2) {
         let mainKey = keySplit[0];
         let nestedKey = keySplit[1];
         const valueIsArray = !isNaN(Number(keySplit[1]));

         if (valueIsArray) {
            obj[mainKey] = [...(obj[mainKey] ? obj[mainKey] : []), value];
         } else {
            obj[mainKey] = {
               ...(obj[mainKey] ? obj[mainKey] : {}),
               [nestedKey]: value,
            };
         }

         delete obj[key];
      }
   }

   return obj;
};
