import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { setPayoutDate } from "./set-payout.services";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useSetPayout() {
   const navigate = useNavigate();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const { queryFn: SetPayoutFn } = useRequest(setPayoutDate);

   const handleSubmit = (queryObj, resetForm) => {
      showModal(MODAL_TYPES.LOADING, {
         message: "please wait while the payout date is being set",
      });
      SetPayoutFn(queryObj, {
         onSuccess: () => {
            resetForm();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "payout date set",
               message:
                  "Your students can now automically withdraw their funds on set date.",
               btnText: "ok",
               btnOnClick: () => navigate(-1),
            });
         },
         onError: (err) => {
            const alreadySet = err?.includes("date is already set");
            showModal(MODAL_TYPES.ERROR, {
               message: alreadySet && "payout date already set",
               btnText: "ok",
               btnOnClick: hideModal,
            });
         },
      });
   };

   return { handleSubmit };
}
