export default function CustomHistoryIcon({ className, color = "#1358CC" }) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.76 92.82" className={className}>
         <defs>
            <style>{`.cls-history{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  className="cls-history"
                  d="M65.78,92.82H14a14,14,0,0,1-14-14V27a14,14,0,0,1,14-14h51.8a14,14,0,0,1,14,14V78.85A14,14,0,0,1,65.78,92.82ZM14,21.06a6,6,0,0,0-6,6V78.85a6,6,0,0,0,6,6h51.8a6,6,0,0,0,6-6V27a6,6,0,0,0-6-6Z"
               />
               <line className="cls-history" x1="17.97" y1="38.51" x2="61.79" y2="38.51" />
               <path
                  className="cls-history"
                  d="M61.79,42.51H18a4,4,0,1,1,0-8H61.79a4,4,0,1,1,0,8Z"
               />
               <line className="cls-history" x1="17.97" y1="54.73" x2="61.79" y2="54.73" />
               <path
                  className="cls-history"
                  d="M61.79,58.73H18a4,4,0,1,1,0-8H61.79a4,4,0,1,1,0,8Z"
               />
               <line className="cls-history" x1="17.97" y1="70.95" x2="61.79" y2="70.95" />
               <path className="cls-history" d="M61.79,75H18a4,4,0,1,1,0-8H61.79a4,4,0,1,1,0,8Z" />
               <line className="cls-history" x1="25.32" y1="16.63" x2="25.32" y2="4" />
               <path
                  className="cls-history"
                  d="M25.32,20.63a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V16.63A4,4,0,0,1,25.32,20.63Z"
               />
               <line className="cls-history" x1="54.19" y1="16.63" x2="54.19" y2="4" />
               <path
                  className="cls-history"
                  d="M54.19,20.63a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V16.63A4,4,0,0,1,54.19,20.63Z"
               />
            </g>
         </g>
      </svg>
   );
}
