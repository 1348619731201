import { useState } from "react";

import RequestsTable from "features/requests/components/table";
import Header from "features/ui/layout/headers/dashboard-header";
import FilterModal from "features/ui/modals/filter-modal";
import useAllRequests from "features/requests/api/all-requests";

export default function Requests() {
   const [queryParams, setQueryParams] = useState("");
   const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
   const { requestsData, requestsLoading } = useAllRequests(queryParams);

   const filterOptions = ["all", "pending", "success", "canceled"];

   const handleFilter = ({ option }) => {
      option === "all"
         ? setQueryParams("")
         : setQueryParams(`?status=${option}`);
   };

   return (
      <section className="wrapper">
         <Header variant="REQUESTS" title="Requests" />
         <RequestsTable
            requestsData={requestsData}
            requestsLoading={requestsLoading}
            showFilterModal={() => setFilterModalIsOpen((prev) => !prev)}
         />
         <FilterModal
            defaultValue="all"
            options={filterOptions}
            handleSubmit={handleFilter}
            isOpen={filterModalIsOpen}
            onClose={() => setFilterModalIsOpen((prev) => !prev)}
         />
      </section>
   );
}
