import useSWR from "swr";
import { getAllSavingssUrl } from "features/savings/savings.service";

export default function useSavings() {
   const {
      data: savings,
      error: getAllSavingsError,
      mutate: mutateSavings,
   } = useSWR([getAllSavingssUrl, "", "V2"]);

   const getAllSavingsLoading = !savings && !getAllSavingsError;
   const cashSavings = savings?.filter((s) => s.savings_type === "cashTarget");
   const dateSavings = savings?.filter((s) => s.savings_type === "dateTarget");

   return {
      savings,
      cashSavings,
      dateSavings,
      getAllSavingsLoading,
      mutateSavings,
   };
}
