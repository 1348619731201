import { BsBank2 } from "react-icons/bs";
import TableRow from "features/ui/tables/table-row";
import TableCol from "features/ui/tables/table-col";
import { getFormattedDate, getFormattedTime } from "utils/lib/number-formatter";
import CustomCreditIcon from "assets/custom-icons/credit-icon";
import CustomDebitIcon from "assets/custom-icons/debit-icon";

export default function Bills({ className, billsData }) {
   //styles
   const wrapperClass = `${className} no-scrollbar px-[10px] flex overflow-x-auto space-x-8 w-full`;

   return (
      <section className={wrapperClass}>
         {!billsData && (
            <div className=" capitalize flex justify-center font-normal w-full">
               <h1>Create a subaccount to get started.</h1>
            </div>
         )}
         {billsData?.slice(0, 3).map((bill) => {
            return (
               <div
                  className="flex-shrink-0  h-fit w-full shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 bg-white rounded-tl-[10px] rounded-tr-[10px]"
                  key={bill._id}
               >
                  <div className="flex h-[85px] px-[20px] flex-row text-black-three items-end pb-[10px] justify-between bg-black-one ">
                     <div>
                        <p className="poppins-regular text-[12px]">{bill.unique_id}</p>
                        <p className="poppins-bold text-[12px]">{bill.title}</p>
                     </div>
                     <div>
                        <p className="poppins-regular text-[12px]">current balance</p>
                        <p className="poppins-bold text-[20px]">
                           <span className="text-[16px]">₦</span>

                           {bill.meta.balance}
                        </p>
                     </div>
                  </div>
                  <div className="px-[20px] py-[20px]">
                     <p className="poppins-medium text-[12px] text-black-one">
                        Recent Transactions
                     </p>
                     {bill?.transactions?.map((transaction) => {
                        return (
                           <BillTransaction
                              key={transaction.id} // Assuming transaction.id is unique, if not use a unique identifier
                              id={bill.unique_id}
                              from={transaction?.receiver?.account_name}
                              to={transaction?.sender?.account_name}
                              amount={transaction.amount}
                              time={`${getFormattedDate(transaction.created_at)} ${getFormattedTime(
                                 transaction.created_at
                              )} `}
                              payment_type={transaction.payment_type}
                           />
                        );
                     })}
                  </div>
               </div>
            );
         })}
      </section>
   );
}

function BillTransaction({ from, to, id, time, amount, payment_type }) {
   return (
      <TableRow gridTemplateCols="grid-cols-4" className="cursor-pointer hover:bg-blue-50">
         <div className="flex gap-[10px] col-span-2 items-center">
            {/* <div className="bg-gray-200 h-[40px] w-[40px] rounded-full"></div> */}
            <BsBank2 />
            <TableCol className="items-start flex flex-col text-black font-medium">
               <p className="text-[16px] w-full text-left text-black-one">
                  {from !== id ? to : from}
               </p>
               <p className="leading-tight poppins-regular text-[12px] text-black-three w-full text-left">
                  {time}
               </p>
            </TableCol>
         </div>
         <TableCol className="justify-center font-medium text-secondary-darker">
            {payment_type === "CREDIT" ? (
               <CustomCreditIcon className={"w-[15px]"} color={"#86efac"} />
            ) : (
               <CustomDebitIcon className={"w-[15px]"} color={"#DC2626"} />
            )}
         </TableCol>
         <TableCol
            className={`justify-end poppins-bold text-[16px] ${
               true ? "text-black-one" : "text-black-one"
            }`}
         >
            <span className="text-xs">₦</span>
            {amount}
         </TableCol>
      </TableRow>
   );
}
