export default function KeysToLowerCase(multiInputArr) {
   const convertedKeysArr = multiInputArr.map((element) => {
      const keys = Object.keys(element);
      const values = Object.values(element);

      return keys.reduce((result, item, index) => {
         return { ...result, [item.toLowerCase()]: values[index] };
      }, {});
   });

   return convertedKeysArr;
}
