import ReceiptField from "./receipt-fieldN";
import { getShortendName } from "./utils";

export default function UserDetailsFields({ name, data, className }) {
   return (
      <div className={className}>
         {name && data && <h2 className="text-sm text-black-three font-semibold">{name}</h2>}
         <ReceiptField label="account id" value={data?.account_number} />
         <ReceiptField label="name" value={getShortendName(data?.account_name)} />
         <ReceiptField label="organization" value={data?.organization} />
      </div>
   );
}
