import AuthLayout from "features/auth/components/layout";
import ResetPasswordComponent from "features/reset-password/reset-password.component";

export default function ResetPassword() {
   return (
      <AuthLayout>
         <ResetPasswordComponent />
      </AuthLayout>
   );
}
