import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import Header from "features/ui/layout/headers/dashboard-header";
import Bills from "features/dashboard/components/bills";
import useDashboardData from "features/dashboard/api/getDashbordData";
import { Link } from "react-router-dom";
import { getFormattedAmount, getFormattedThousand } from "utils/lib/number-formatter";
import { useState } from "react";
import CustomFundIcon from "assets/custom-icons/fund-icon";
import CustomWithdrawIcon from "assets/custom-icons/withdraw-icon";

const options = {
   responsive: true,
   maintainAspectRatio: false,
   plugins: {
      legend: {
         display: true,
         labels: {
            color: "#848484",
            font: {
               family: "Poppins", // Add your desired font here for legend labels
            },
         },
      },
      tooltip: {
         bodyFont: {
            family: "Poppins", // Add your desired font here for tooltip body
         },
         titleFont: {
            family: "Poppins", // Add your desired font here for tooltip title
         },
      },
   },
   // Other chart options...
};

export default function DashboardHome() {
   const { bills, ledgerData } = useDashboardData();

   const {
      MainAccountBalance: mainBal,
      TotalBillBalance: billsBal,
      TotalSavingsBalance: savingsBal,
   } = ledgerData ?? {};

   return (
      <section className="wrapper bg-[#FCFDFF] pb-[100px] md:bg-[#EDF5FC] min-h-screen">
         <Header variant="DASHBOARD">
            <div className="mt-32 bg-white pb-4 w-full px-6 ">
               {ledgerData ? <BalancesTab ledgerData={{ mainBal, billsBal, savingsBal }} /> : null}
            </div>

            {/* Withdraw and Fund Button */}
            <div className="text-primary-darker flex gap-5 px-6">
               <Link
                  className="bg-accent-one rounded-3xl p-2 flex justify-start items-center gap-2 h-[50px] font-semibold px-6"
                  to="/dashboard/withdraw"
               >
                  <CustomWithdrawIcon
                     className="w-[20px] self-center text-accent-one"
                     color="#ffffff"
                  />{" "}
                  <span className="text-white">Transfer</span>
               </Link>
               <Link
                  className="bg-[#C4D5F2] rounded-3xl p-2 flex justify-start items-center gap-2 h-[50px] font-semibold px-6"
                  to="/accounts/funding/create"
               >
                  <CustomFundIcon
                     className="w-[20px] self-center text-accent-one"
                     color="#1358CC"
                  />{" "}
                  <span>Fund</span>
               </Link>
            </div>
         </Header>
         <p className="md:pl-[180px] mb-[24px] px-[20px] mt-[50px] text-xl">Subaccounts</p>

         <div className=" flex flex-col px-[20px]">
            <Bills key={"bills"} billsData={bills} />

            <p className="md:pl-[180px] mb-[24px] mt-[50px] text-xl">Transaction Summary</p>
            {/* <div className="w-full h-[400px] py-[20px] shadow-faintShadow-two shadow-[0_4px_10px] shadow-opacity-80 bg-white flex flex-row justify-center">
               <Doughnut
                  data={{
                     labels: ["funding", "payout"],
                     datasets: [
                        {
                           label: "amount",
                           data: [80, 20],
                           backgroundColor: ["#19B832", "#C52626"],
                           borderRadius: 15,
                        },
                     ],
                  }}
                  options={options}
               />
            </div> */}
         </div>
      </section>
   );
}

const BalancesTab = ({ ledgerData }) => {
   const { mainBal, billsBal, savingsBal } = ledgerData;

   const balances = [
      { label: "Main", value: mainBal },
      { label: "Sub Accounts", value: billsBal },
      { label: "Savings", value: savingsBal },
   ];

   const [activeBal, setActiveBal] = useState({
      label: "Main",
      value: mainBal,
   });

   return (
      <>
         <div className="flex gap-2 mb-10">
            {balances.map((b) => (
               <BalancesTabItem
                  key={b.label}
                  label={b.label}
                  isActive={activeBal.label === b.label}
                  onClick={() => setActiveBal(b)}
               />
            ))}
         </div>
         <p className="text-5xl rounded-2xl text-primary md:bg-white md:p-0 font-semibold md:text-6xl lg:text-7xl">
            <span className="text-xl text-blue-300">₦</span>
            <span>{getFormattedThousand(activeBal.value)}</span>
         </p>
         <div className="flex gap-6 text-primary font-semibold">
            <p>
               Total:{" "}
               <span>
                  {getFormattedAmount(
                     Math.max(mainBal, 0) + Math.max(savingsBal, 0) + Math.max(billsBal, 0)
                  )}
               </span>
            </p>
         </div>
      </>
   );
};

const BalancesTabItem = ({ label, onClick, isActive }) => {
   return (
      <p
         className={`  px-6 py-1 rounded-lg cursor-pointer hover:opacity-100 ${
            isActive ? " text-accent-one bg-accent-four/50" : " text-black-four"
         }`}
         onClick={onClick}
      >
         {label}
      </p>
   );
};
