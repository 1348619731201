import useSWR from "swr";
import { getAllBillsInfoUrl, getAllBillsUrl } from "features/bills/bills.service";
import { fetcher } from "utils/lib/fetcher";

export default function useDashBoardBills() {
   const {
      data: bills,
      error: getAllBillsError,
      mutate: mutateBills,
   } = useSWR(getAllBillsInfoUrl, fetcher);

   const getAllBillsLoading = !bills && !getAllBillsError;

   return { bills, getAllBillsLoading, mutateBills };
}
