import { useState } from "react";
// import Button from "features/ui/forms/button";
import Header from "features/ui/layout/headers/dashboard-header";
import AccountsTable from "features/bills/components/table";
import RangeModal from "features/ui/modals/range-modal";
import { useFormData } from "utils/hooks/useFormData";
import { Link, useParams } from "react-router-dom";
import useSingleBill from "features/bills/api/bill.api";
import { useAdmin } from "features/auth/admin-context";
import { getFormattedAmount } from "utils/lib/number-formatter";
// import getTablePDF from "features/ui/tables/table-pdf";
import DeleteModal from "features/bills/components/delete-bill";
import WithdrawToModal from "features/withdraw/withdraw-to-modal";
import CustomEditIcon from "assets/custom-icons/edit-icon";
import CustomDeleteIcon from "assets/custom-icons/delete-icon";
import CustomWithdrawIcon from "assets/custom-icons/withdraw-icon";

export default function BillAccountDetails() {
   const initialFormData = {
      filterStartDate: "",
      filterEndDate: "",
      downloadStartDate: "",
      downloadEndDate: "",
   };
   //form states
   const [state, dispatch] = useFormData(initialFormData);

   //modal states
   const [filterModal, setFilterModal] = useState(false);
   const [downloadModal, setDownloadModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [withdrawModal, setWithdrawModal] = useState(false);
   const toggleWithdrawModal = () => setWithdrawModal((prev) => !prev);

   //constants
   //TODO: get filter options based institution types
   const filterOptions = ["JSS 1", "JSS 2", "JSS 3", "SSS 1", "SSS 2", "SSS 3"];
   const { id: billId } = useParams();

   //queries
   const {
      billInfo, //
      billTransactions,
      billTransactionsError,
      billTransactionsLoading,
   } = useSingleBill(billId);

   const { adminId } = useAdmin();

   //handle functions
   const handleFilterSelect = (option) => {
      dispatch({
         type: "updateInput",
         payload: { name: "selectedFilter", value: option },
      });
   };

   const handleDownloadFilterSelect = (option) => {
      dispatch({
         type: "updateInput",
         payload: { name: "selectedDownloadFilter", value: option },
      });
   };

   const handleDateChange = (name, value) => {
      dispatch({
         type: "updateInput",
         payload: { name, value },
      });
   };

   const handleSubmit = () => console.log("submitted");

   if (!billInfo) return null;
   return (
      <section className="wrapper md:py-[40px]">
         <div className="flex flex-col items-center md:items-stretch md:flex-row md:mb-[40px] md:w-5/6 md:max-w-3xl mx-auto">
            <Header settingsUrl="../settings" variant="BILL" schoolId={adminId}>
               <div className=" mx-auto flex flex-col justify-end h-full w-full">
                  <div className="flex flex-row gap-[10px]">
                     <div className="py-[5px] px-[10px] rounded-[10px] w-fit bg-accent-two">
                        <p className="text-[12px] ">{billId}</p>
                        <p className="text-[12px] font-semibold">{billInfo?.title}</p>
                     </div>
                     <div className="py-[5px] px-[10px] rounded-[10px] w-fit bg-accent-two">
                        <p className="text-[12px]">VFD BANK</p>
                        <p className="text-[12px] font-semibold">
                           {billInfo?.wallet?.account_number}
                        </p>
                     </div>
                     <div className="py-[5px] px-[10px] rounded-[10px] w-fit bg-accent-two">
                        <p className="text-[12px]">amount</p>
                        <p className="text-[12px] font-semibold">
                           {billInfo && getFormattedAmount(billInfo?.amount)}
                        </p>
                     </div>
                  </div>
                  <div className="my-[40px]">
                     <p className="font-semibold  mb-[8px]  text-xs">balance</p>
                     <p className="text-3xl  font-semibold sm:text-4xl md:text-5xl">
                        <span className="text-lg">₦</span>
                        {billInfo && getFormattedAmount(billInfo?.meta?.balance).slice(1)}
                     </p>
                  </div>

                  <div className="flex flex-row justify-between items-center">
                     <button
                        onClick={toggleWithdrawModal}
                        className="bg-[#C4D5F2] rounded-3xl p-2 flex justify-start items-center gap-2 h-[50px] font-semibold px-6"
                     >
                        <CustomWithdrawIcon className={"w-[24px]"} color={"#1358CC"} />
                        <span className="text-accent-one">Withdraw</span>
                     </button>

                     <div className="flex">
                        <Link
                           to={`/dashboard/bill-accounts/${billInfo.unique_id}/edit`}
                           className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
                        >
                           <CustomEditIcon className={"w-[24px]"} color={"#ffffff"} />
                           <span className="text-white">edit</span>
                        </Link>
                        <button
                           onClick={() => setDeleteModal(true)}
                           className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold "
                        >
                           <CustomDeleteIcon className={"w-[24px]"} color={"#ffffff"} />
                           <span className="text-white">delete</span>
                        </button>
                     </div>
                  </div>
               </div>
            </Header>
         </div>

         <AccountsTable
            toggleModal={() => setFilterModal((prev) => !prev)}
            className="mb-[220px]"
            billTransactions={billTransactions}
            billTransactionsError={billTransactionsError}
            billTransactionsLoading={billTransactionsLoading}
         />

         <WithdrawToModal
            id={billInfo.unique_id}
            title={billInfo.title}
            source="bill"
            isOpen={withdrawModal}
         />

         <DeleteModal bill={billInfo} modalDisplay={deleteModal} setModalDisplay={setDeleteModal} />

         <RangeModal
            isOpen={filterModal}
            onClose={() => setFilterModal(false)}
            dispatch={dispatch}
            options={filterOptions}
            startDate={state.filterStartDate}
            endDate={state.filterEndDate}
            selectedFilter={state.selectedFilter}
            handleSubmit={handleSubmit}
            handleDateChange={handleDateChange}
            handleRangeSelect={handleFilterSelect}
         />

         <RangeModal
            isOpen={downloadModal}
            onClose={() => setDownloadModal(false)}
            dispatch={dispatch}
            startDate={state.downloadStartDate}
            endDate={state.downloadEndDate}
            options={filterOptions}
            selectedFilter={state.selectedDownloadFilter}
            handleSubmit={handleSubmit}
            handleDateChange={handleDateChange}
            handleRangeSelect={handleDownloadFilterSelect}
         />
      </section>
   );
}
