import { useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import useRequest from "utils/hooks/useRequest";
import { resetPasswordEmail } from "./reset-password.services";
import { resetPasswordNewPassword } from "./reset-password.services";
import { resetPasswordOTP } from "./reset-password.services";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { useNavigate } from "react-router-dom";

export default function useResetPassword() {
   const [step, setStep] = useState(1);
   const [error, setError] = useState({});
   const [state, setState] = useState({ email: "", otp: "" });
   const resetError = () => setError("");
   const navigate = useNavigate();

   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const showSuccessModal = () => {
      showModal(MODAL_TYPES.SUCCESS, {
         title: "Password reset successful",
         message: "Your password has been changed successfully",
         btnText: "ok",
         btnOnClick: () => navigate("/signin"),
         modalPosition: "CENTER",
      });
   };

   const handleOTPChange = (value) => {
      resetError();
      setState((prev) => ({ ...prev, otp: value }));
   };

   const handleNext = () => {
      setStep((prev) => prev + 1);
   };

   //get query functions for making post requests
   const { queryFn: verifyEmailFn, loading: emailSubmitLoading } = useRequest(resetPasswordEmail);
   const { queryFn: verifyOTPFn, loading: otpVerificationLoading } = useRequest(resetPasswordOTP);
   const { queryFn: newPasswordFn, loading: passwordResetLoading } =
      useRequest(resetPasswordNewPassword);

   //1. submit email to backend for verification code to be sent
   const handleEmailSubmit = (data) => {
      verifyEmailFn(data, {
         onSuccess: () => {
            setState((prev) => ({ ...prev, email: data.email }));
            showModal(MODAL_TYPES.SUCCESS, {
               icon: <IoCheckmarkCircleOutline className="text-6xl mb-4" />,
               title: "Email Sent",
               message: "Check your mailbox for a reset email",
               size: "SMALL",
               modalPosition: "CENTER",
               btnText: "continue",
               btnOnClick: () => {
                  hideModal();
                  handleNext();
               },
            });
         },
         onError: (message) => {
            if (message.includes("We could not find any account")) {
               setError({ message: "Invalid account details!" });
            } else {
               setError({ message: "Something went wrong. Please try again" });
            }
         },
      });
   };

   //2. submit otp to check match
   const handleVerification = () => {
      const { email, otp } = state;
      const queryObj = { email, otp };
      verifyOTPFn(queryObj, {
         onSuccess: () => handleNext(),
         onError: (message) =>
            message.includes("incorrect")
               ? setError(message)
               : setError("Something went wrong. Please try again"),
      });
   };

   //3. submit new password form
   const handleSubmit = (data) => {
      const { email, otp } = state;
      const queryObj = { email, otp, new_password: data.password };
      newPasswordFn(queryObj, {
         onSuccess: () => showSuccessModal(),
         onError: () => setError({ message: "Something went wrong. Please try again" }),
      });
   };

   return {
      step,
      error,
      resetError,
      emailSubmitLoading,
      otpVerificationLoading,
      passwordResetLoading,
      handleOTPChange,
      handleNext,
      handleSubmit,
      handleEmailSubmit,
      handleVerification,
   };
}
