import { useState } from "react";

import { useAdmin } from "features/auth/admin-context";
import Header from "features/ui/layout/headers/dashboard-header";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { Link } from "react-router-dom";
import { BsSend } from "react-icons/bs";
import GroupCard from "features/groups/components/group-card";
import TabSelect from "features/ui/layout/nav/tabselect";
import CustomDeleteIcon from "assets/custom-icons/delete-icon";
import CustomEditIcon from "assets/custom-icons/edit-icon";

export default function User({
   name,
   userId,
   uniqueId,
   balance,
   bills,
   groupCircles,
   children,
   userType,
   handleDelete,
}) {
   const [tabSelect, setTabSelect] = useState("Sub Accounts");

   const tabSelectOptions = [
      {
         title: "Sub Accounts",
         onClick: () => setTabSelect("Sub Accounts"),
         isActive: tabSelect === "Sub Accounts",
      },
      {
         title: "Groups",
         onClick: () => setTabSelect("Groups"),
         isActive: tabSelect === "Groups",
      },
   ];

   const { adminId } = useAdmin();

   return (
      <section className="wrapper bg-white">
         <Header settingsUrl="../settings" variant="OTHERS" schoolId={adminId}></Header>
         <div className="pt-20 first-letter:w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#EDF5FC] rounded-t-xl flex flex-col items-center">
            <div className="flex flex-col h-full items-center md:items-stretch  md:w-5/6 md:max-w-3xl mx-auto">
               <div className="mx-auto w-5/6 flex flex-col justify-center items-center ">
                  <Profile name={name} uniqueId={uniqueId} />
                  <Balance balance={balance} />
                  <ActionBtns
                     userId={userId}
                     uniqueId={uniqueId}
                     userType={userType}
                     handleDelete={handleDelete}
                  />
               </div>
               <TabSelect options={tabSelectOptions} />
               <TabDetails tabSelect={tabSelect} bills={bills} groupCircles={groupCircles} />
            </div>
            {children}
         </div>
      </section>
   );
}

const Profile = ({ name, uniqueId }) => {
   const pStyle =
      "flex items-center border-none mt-[12px] text-[18px] poppins-bold text-black-one capitalize sm:text-xl md:mb-7 lg:text-[22px] lg:font-normal";
   return (
      <section className="flex flex-col items-center">
         <img
            src={
               "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
            }
            alt="icon"
            className="text-black-one border-black-one border-[4px] rounded-[50%] h-[100px] w-[100px]"
         />

         <p className={pStyle}>{name}</p>
         <p
            className={`bg-black-one rounded-[5px] mt-[10px] px-[5px] py-[2px] w-fit text-white flex flex-row items-center justify-center`}
         >
            {uniqueId}
         </p>
      </section>
   );
};

const Balance = ({ balance }) => {
   return (
      <div className="flex my-[20px] gap-[10px] items-end justify-center w-full">
         <p className={"poppins-bold text-[24px] "}>
            <span className="text-[16px] text-black-three poppins-bold">₦</span>
            {getFormattedAmount(balance ?? 0).slice(1)}
         </p>
      </div>
   );
};

const ActionBtns = ({ userId, uniqueId, userType, handleDelete }) => {
   return (
      <div className="flex flex-row  w-full  justify-between mt-[20px] mb-6 items-center">
         <div className="flex items-center">
            <Link
               to={`../../dashboard/${userType}/${userId}/edit`}
               className="flex flex-col justify-start items-center gap-[8px] font-semibold px-6"
            >
               <CustomEditIcon className={"w-[20px]"} color={"#848484"} />
               <span className="text-black-three">edit</span>
            </Link>

            {handleDelete ? (
               <button
                  onClick={handleDelete}
                  className="flex flex-col justify-start items-center font-semibold "
               >
                  <CustomDeleteIcon className={"w-[20px]"} color={"#848484"} />
                  <span className="text-black-three p-0">delete</span>
               </button>
            ) : null}
         </div>
         <Link
            to={`../../dashboard/${userType}/${userId}-${uniqueId}/transfer`}
            className="rounded-3xl flex flex-col justify-start items-center gap-[8px] h-[50px] font-semibold px-6"
         >
            <BsSend className="w-12 h-12 text-black-three" />
            <span className="text-black-three poppins-semibold">transfer</span>
         </Link>{" "}
      </div>
   );
};

const TabDetails = ({ tabSelect, bills, groupCircles }) => {
   return (
      <div className="h-fit mb-[100px]">
         {/* {tabSelect === "Sub Accounts" ? (
            <div>
               {bills?.paid ? (
                  bills?.paid.map((bill) => {
                     return <BillCard bill={bill} />;
                  })
               ) : (
                  <div className="bg-white p-6 text-xl">No Sub Accounts Yet</div>
               )}
            </div>
         ) : null} */}
         {tabSelect === "Groups" && (
            <div>
               {groupCircles ? (
                  groupCircles.map((group) => {
                     return <GroupCard group={group} />;
                  })
               ) : (
                  <div className="bg-white p-6 text-xl">No Groups Yet</div>
               )}
            </div>
         )}
      </div>
   );
};
